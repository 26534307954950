<template>
  <div class="bg-gradient-to-b from-white to-gray-50 w-full">
    <div class="max-w-7xl mx-auto pt-6 px-4 sm:px-6 lg:px-8">
      <div class="sm:flex sm:flex-col sm:align-center">
        <h1 class="text-2xl sm:text-5xl font-extrabold text-gray-900 text-center">Tu cotización</h1>
        <p class="mt-5 text-xl text-gray-500 sm:text-center">Aquí encontrarás los precios de cada aseguradora en la que confiamos, así como la información más importante para contratar tu seguro, compara precios, encuentra tu cobertura ideal y contrata hoy mismo en un proceso 100% en línea.</p>
        
        <!-- <div class="relative mt-6 bg-gray-100 rounded-lg p-0.5 flex self-center sm:mt-8">
          <button 
            type="button" 
            id="btn_show_car" 
            v-on:click="currentProductName = 'Auto'" 
            v-show="showAuto" 
            class="relative bg-white border-gray-200 rounded-md shadow-sm py-2 w-1/2 text-sm font-medium text-gray-700 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-orange-500 focus:z-10 sm:w-auto sm:px-8"
          >
            Auto
          </button>
        </div> -->
      </div>
      <div>
        <div 
          id="divIdDates" 
          class="grid grid-cols-1 text-2xl sm:grid-cols-3 sm:text-xl ta-center mb05 mt05" 
          style="font-family: Montserrat;margin-bottom: 0.5rem;margin-top: 0.5rem;text-align: center"
        >
          <span 
            class='blue mb05 mt05 pa1' 
            style='color: #243c44;margin-bottom: 0.5rem;margin-top: 0.5rem;padding: 1rem;font-size: 1.5rem;'
          >
            No. de Folio:
          </span>
          <span 
            class='blue mb05 mt05 pa1' 
            style='color: #243c44;margin-bottom: 0.5rem;margin-top: 0.5rem;padding: 1rem;font-size: 1.5rem;'
          >
            Fecha: 
          </span>
          <span 
            class='blue mb05 mt05 pa1' 
            style='color: #243c44;margin-bottom: 0.5rem;margin-top: 0.5rem;padding: 1rem;font-size: 1.5rem;'
          >
            Válido hasta:
          </span>
        </div>
        <carousel :settings="settings" :breakpoints="breakpoints" v-show="currentProductName == 'Auto'" >
          <slide v-for=" provider in activeProviders" :key="provider.nombre" >    
            <div class="mt-5">
              <div class="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200" >
                <div class="p-6">
                  <div class="flex justify-center my-3">
                    <img class="h-40" :src="require(`@/assets/images/aseguradoras/logo_${provider.nombre}.svg`)" alt="Tuple">
                  </div>
                </div>
                <div class="mb-12 flex justify-center loading-div" :id="`divLoading${provider.nombre}`" style="display: block;">
                  <center><img style="height: 3rem;" :src="require(`@/assets/images/ajax-loader.gif`)" alt="Tuple"></center>
                </div>
                <a href="#" 
                  :id="`issuanceQuote${provider.nombre}`" 
                  @click="showQuestionsEmision(provider.nombre)" 
                  class="mt-8 block w-full bg-orange-500 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-orange-700" style="display: none;"
                >
                  Aceptar cotización
                </a>
                <div :id="`totalContent${provider.nombre}`" class="totales" style="display: none;">
                </div>
                <div :id="`totalCoverages${provider.nombre}`" class="totales" style="display: none;">
                </div>
                <div class="pt-6 pb-8 px-6">
                  <button 
                    type="button" 
                    :id="`btnShowCoverages${provider.nombre}`" 
                    style="display: none;" 
                    @click="showCoverages(provider.nombre)" 
                    class="w-full inline-flex items-center justify-center px-6 py-3 border border-orange-500 text-base leading-6 
                    font-medium rounded-md text-orange-500 bg-white hover:bg-orange-500 hover:text-white focus:outline-none focus:border-orange-700 
                    focus:shadow-outline-orange active:bg-orange-700 transition ease-in-out duration-150 mt-12"
                  >
                    COBERTURAS
                  </button>
                </div>
              </div>
            </div>
            <div v-if="index < 3  ? isLoading = false : isLoading = false">   </div>    
          </slide>

          <template #addons="{ slidesCount }" >
            <navigation v-if="slidesCount > 3" />
            <pagination v-if="slidesCount > 3" />
          </template>
        </carousel>
      </div>
      

      <div class="sm:flex sm:flex-col sm:align-center">
        <form class="flex flex-wrap items-center p-5 justify-center mt-5 border border-gray-200 rounded-lg shadow-sm ">
          <div class="w-full m-5">
            <h1 class="text-xl sm:text-3xl text-red-500 text-center ">Personaliza tu seguro</h1>
          </div>
          <div class="sm:w-2/12 w-full ml-2 mr-2">
            <label class="font-semibold">Cobertura</label>
            <div>
              <select name="cobertura" id="select_coverage" required class="select" v-model="coverage">
                <option disabled value="">Seleccionar</option>
                <option selected value="Amplia" name="Amplia">Amplia</option>
                <option value="Limitada" name="Limitada">Limitada</option>
                <option value="RC" name="RC">RC</option>
              </select>
            </div>
          </div>
          <div class="sm:w-2/12 w-full ml-2 mr-2">
            <label class="font-semibold">Forma de pago</label>
            <div>
              <select name="pago" id="select_paymentFrecuency" required class="select" v-model="paymentFrecuency">
                <option disabled value="">Seleccionar</option>
                <option selected value="Anual" name="Anual">Anual</option>
                <option value="Semestral" name="Semestral">Semestral</option>
                <option value="Trimestral" name="Trimestral">Trimestral</option>
              </select>
            </div>
          </div>
          <div class="sm:w-2/12 w-full ml-2 mr-2 rounded-md shadow-sm align-middle mt-6">
            <button 
              type="button" 
              id="btn_requote" 
              @click="requote($event)" 
              style="padding-top: 0.65rem; padding-bottom: 0.65rem;" 
              class="w-full inline-flex items-center justify-center px-6 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-orange-500 hover:bg-orange-500 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition ease-in-out duration-150"
            >
              Recotizar
            </button>
          </div>
          <div class="w-full m-5">
            <h1 class="text-xl sm:text-3xl text-blue-900  text-center ">{{message}}</h1>
          </div>
          <div class="sm:w-2/12 w-full ml-2 mr-2 rounded-md shadow-sm align-middle ">
            <button 
              type="button" 
              id="btn_save_quote" 
              @click="saveQuotation()" 
              class="w-full inline-flex items-center justify-center px-6 py-3 border border-orange-500 text-base leading-6 
                    font-medium rounded-md text-orange-500 bg-white hover:bg-orange-500 hover:text-white focus:outline-none focus:border-orange-700 
                    focus:shadow-outline-orange active:bg-orange-700 transition ease-in-out duration-150"
            >
              Enviar cotización al correo
            </button>
          </div>
          <div class="sm:w-2/12 w-full ml-2 mr-2 rounded-md shadow-sm align-middle ">
            <button 
              type="button" 
              id="btn_share_quote" 
              @click="sendQuoteByMessage()" 
              class="w-full inline-flex items-center justify-center px-6 py-3 border border-orange-500 text-base leading-6 
                    font-medium rounded-md text-orange-500 bg-white hover:bg-orange-500 hover:text-white focus:outline-none focus:border-orange-700 
                    focus:shadow-outline-orange active:bg-orange-700 transition ease-in-out duration-150"
            >
              Enviar cotización al WhatsApp
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="flex justify-between mt-8 ml-8">
      <span class="inline-flex rounded-md shadow-sm ">
        <button 
          type="button" 
          id="btn_back" 
          @click="back()" 
          class="w-full inline-flex items-center justify-center px-6 py-3 border border-orange-500 text-base leading-6 font-medium rounded-md text-orange-500 bg-white hover:bg-orange-500 hover:text-white focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition ease-in-out duration-150"
        >
          Atrás
        </button>
      </span>
    </div>
  </div>
</template>

<script lang="en">
  import '@/assets/css/carousel.css';
  import {
    Carousel,
    Slide,
    Pagination,
    Navigation
  } from 'vue3-carousel';
  import Swal from "sweetalert2";
  import {
    ref,
    inject,
    onMounted,
  } from "vue";
  import {
    useRouter
  } from 'vue-router';

  import BehaviorJson from "@/classes/BehaviorJson.js";
  import Quote from "@/classes/quote.js";
  import axios from "axios";
  import Category from "@/classes/category.js";
  import SendEmail from "@/classes/SendEmail.js";
  
  // import { getUserAuthInfo } from "@/helpers/auth.js";
  export default {
    name: "Quotation",
    components: {
      Carousel,
      Slide,
      Pagination,
      Navigation
    },
    setup() {
      const anterapi = process.env.VUE_APP_ANTERAPI;
      let config = ref({
          headers: {
            'Content-Type': 'application/json'
          }
        });
    
      const router = useRouter();
      const coverage = ref("Amplia");
      const paymentFrecuency = ref("Anual");
      const message = ref("");
      const responseQuoteInfo = ref([]);
      
      // const aspects = inject('aspects');
      const isLoading = inject('isLoading');
      const step = inject("step");
      const categories = inject("categories");
      const contCategory = inject("contCategory");
      const parametrosCotizacion = inject('parametrosCotizacion');
      const parametrosSalesforce = inject('parametrosSalesforce');
      const arrayAnswers = inject('arrayAnswers');
      const activeProviders = inject('activeProviders');
      const currentCategoryName = inject('currentCategoryName');
      const currentProductName = inject('currentProductName');
      currentProductName.value = "Auto";
      
      const selectedProviderQuote = inject('selectedProviderQuote');
      const parametrosEmision = inject('parametrosEmision');
      const car_description = inject('car_description');
      const selectedProducts = inject('selectedProducts');
      const responseQuoteRegister = inject('responseQuoteRegister');
      
      const contSteps = inject('contSteps');
      const quoteId = inject('quoteId');
      const arrayIdQuestions = ref([]);
      const json = ref([]);
      const showAuto = ref(true);
      const carInfo = ref("");
      
      const settings = ref({
        itemsToShow: 1,
        snapAlign: "center",
        arrows: true,
        dots: true,
      });
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      const breakpoints = ref({
        // 700px and up
        700: {
          itemsToShow: 2,
          snapAlign: "align"
        },
        // 1024 and up
        1024: {
          itemsToShow: 3,
          snapAlign: "start"
        }
      });

      onMounted(async () => {
        var paramsString = "q=URLUtils.searchParams&"+window.location.search
        const urlParams = new URLSearchParams(paramsString)
        if(urlParams.get('?id') != null){
          quoteId.value = urlParams.get('?id')
        }
        if(quoteId.value != ""){
          await getQuoteSaved(quoteId.value); 
        }else{
          await fillQuoteJson();
        }
      })

      async function showQuestionsEmision(provider_select) {
        responseQuoteInfo.value.forEach(element => {
          if(provider_select == element.proveedor){
            selectedProviderQuote.value = element;
          }
        });
        categories.value = [];
        isLoading.value = true;

        let requestUrl = anterapi + "/pregunta/obtener_por_productos_y_categorias?procedimiento_almacenado=sp_obtener_preguntas_por_productos_y_categorias&nombre_productos[]=Auto" +
            selectedProducts.value + "&categorias[]=EMISION";
            
        await axios.get(requestUrl, config.value).then((response) => {
          console.log("categoria emision", response.data.respuesta);
          
          json.value = response.data.respuesta; 
        }).catch((error) => {
          console.log("Se presento un error al obtener las preguntas: ", error);
        })
        .finally(() => {
          isLoading.value = false;
        })
        validateQuestions();
        
        currentCategoryName.value = "EMISION";
        step.value++;
        router.push({
          name: "Questions"
        });
        contCategory.value = 0; //categories.value[0].id;
        contSteps.value = 0;
        fillIssuanceJson();
      }

      function validateQuestions() {
        console.log("json: ", json.value);
        for (let i = 0; i < json.value.length; i++) { //categories
          const categories = json.value[i];
          for (let j = 0; j < categories.steps.length; j++) { //steps
            const arrayQuestions = categories.steps[j].questions;
            for (let k = 0; k < arrayQuestions.length; k++) { //questions    
              if (arrayIdQuestions.value.length == 0) {
                arrayIdQuestions.value.push(arrayQuestions[k].id);
              } else {
                const found = arrayIdQuestions.value.find(element => element == arrayQuestions[k].id);
                if (found == undefined) {
                  arrayIdQuestions.value.push(arrayQuestions[k].id);
                } else {
                  json.value[i].steps[j].questions.splice(k, 1);
                  k--;
                }
              }
            }
            if (arrayQuestions.length == 0) {
              json.value[i].steps.splice(j, 1);
              j--;
            }
          } //for
        }
        let arrayCategories = Category.buildFromJson(json.value);
        arrayCategories.forEach(element => {
          categories.value.push(element);
        });

        console.log("categories.value ", categories.value);
      }

      function fillIssuanceJson() {
        parametrosEmision.value.proveedor = selectedProviderQuote.value.proveedor;
        parametrosEmision.value.informacion.auto = parametrosCotizacion.value.auto;
        parametrosEmision.value.informacion.cliente = parametrosCotizacion.value.cliente;
        parametrosEmision.value.informacion.cotizacion = selectedProviderQuote.value.informacion_cotizacion;
        parametrosEmision.value.informacion.cotizacion_id = selectedProviderQuote.value.cotizacion_id;
        parametrosEmision.value.informacion.prospecto_id = parametrosCotizacion.value.prospecto_id;
        parametrosEmision.value.informacion.cotizacion_proveedor_id = selectedProviderQuote.value.cotizacion_proveedor_id;
        parametrosEmision.value.informacion.auto.cobertura = parametrosCotizacion.value.auto.cobertura;
        parametrosEmision.value.informacion.auto.frecuencia_de_pago = parametrosCotizacion.value.auto.frecuencia_de_pago;
        parametrosEmision.value.informacion.cotizacion.importe_total = selectedProviderQuote.value.informacion_cotizacion.importe_total.replace(',','');
        parametrosEmision.value.informacion.cotizacion.primer_importe = selectedProviderQuote.value.informacion_cotizacion.primer_importe.replace(',','');
      }

      async function getQuoteSaved(cotizacion_id) {
        await axios.get(anterapi + '/autos/obtener_proveedores_activos', config.value).then((response) => {
          console.log("respuesta al obtener el listado",response.data.respuesta);
          activeProviders.value = response.data.respuesta;
        }).catch(error => {
          console.log("Se presentó un error; ",error)
        });

        await axios.get(anterapi + '/autos/recuperar_cotizacion?cotizacion_id=' + cotizacion_id, config.value).then((response) => {
          response.data.respuesta.cotizaciones.forEach(quote_response => {
            responseQuoteInfo.value.push(quote_response);  
          });
          arrayAnswers.value = response.data.respuesta.respuestas;
          localStorage.setItem(
              "arrayAnswers",
              JSON.stringify(arrayAnswers.value)
            );
          const quote = new Quote();
          const quote_data = {
            "id": response.data.respuesta.cotizaciones[0].cotizacion_id,
            "fecha_cotizacion": response.data.respuesta.cotizaciones[0].parametros.informacion.fecha_cotizacion,
            "fecha_expiracion": response.data.respuesta.cotizaciones[0].parametros.informacion.fecha_expiracion
          }
          
          parametrosCotizacion.value = response.data.respuesta.cotizaciones[0].parametros.informacion;
          parametrosCotizacion.value.usuario_id = response.data.respuesta.cotizaciones[0].parametros.informacion.usuario_id;
          var opt_cobertura = document.getElementsByName("cobertura")[0];
          var opt_pago = document.getElementsByName("pago")[0];
          if(validar_años_cobertura_rc(parseInt(parametrosCotizacion.value.auto.modelo))){
            opt_cobertura[0].removeAttribute('selected');
            opt_cobertura[1].setAttribute('disabled','true');
            opt_cobertura[1].setAttribute('hidden','true');
            opt_cobertura[2].setAttribute('disabled','true');
            opt_cobertura[2].setAttribute('hidden','true');
            opt_cobertura[3].setAttribute('selected','true');
          }
          switch(parametrosCotizacion.value.auto.cobertura){
            case "Amplia": opt_cobertura[1].setAttribute('selected','true'); break;
            case "Limitada": opt_cobertura[2].setAttribute('selected','true'); break;
            case "RC": opt_cobertura[3].setAttribute('selected','true'); break;
          }

          switch(parametrosCotizacion.value.auto.frecuencia_de_pago){
            case "Anual": opt_pago[1].setAttribute('selected','true'); break;
            case "Semestral": opt_pago[2].setAttribute('selected','true'); break;
            case "Trimestral": opt_pago[3].setAttribute('selected','true'); break;
          }
          paymentFrecuency.value = parametrosCotizacion.value.auto.frecuencia_de_pago;
          coverage.value = parametrosCotizacion.value.auto.cobertura;
          var i = 0;
          responseQuoteInfo.value.forEach(quote_response => {
            if(quote_response.error == false){ 
              
              var quote_prices = setMoneyFormat(quote_response);

              const quote = new Quote();
              quote.organizeQuoteInfo(quote_prices);
              switch(quote_response.proveedor){
                // case "Gnp": document.querySelector(".carousel__track").children[0].style.order = i; break;
                case "Axa": document.querySelector(".carousel__track").children[0].style.order = i; break;
                case "Qualitas": document.querySelector(".carousel__track").children[1].style.order = i; break;
                case "Primeroseguros": document.querySelector(".carousel__track").children[2].style.order = i; break;
                case "Hdi": document.querySelector(".carousel__track").children[3].style.order = i; break;
                case "Chubb": document.querySelector(".carousel__track").children[4].style.order = i; break;
                // case "Atlas": document.querySelector(".carousel__track").children[4].style.order = i; break;
                case "Zurich": document.querySelector(".carousel__track").children[5].style.order = i; break;
                case "Anaseguros": document.querySelector(".carousel__track").children[6].style.order = i; break;
              }
              i++;
            }else{
              const quote = new Quote();
              quote.getErrorRow(quote_response.proveedor);
              switch(quote_response.proveedor){
                // case "Gnp": document.querySelector(".carousel__track").children[0].style.order = 50; break;
                case "Axa": document.querySelector(".carousel__track").children[0].style.order = 50; break;
                case "Qualitas": document.querySelector(".carousel__track").children[1].style.order = 50; break;
                case "Primeroseguros": document.querySelector(".carousel__track").children[2].style.order = 50; break;
                case "Hdi": document.querySelector(".carousel__track").children[3].style.order = 50; break;
                case "Chubb": document.querySelector(".carousel__track").children[4].style.order = 50; break;
                // case "Atlas": document.querySelector(".carousel__track").children[4].style.order = 50; break;
                case "Zurich": document.querySelector(".carousel__track").children[5].style.order = 50; break;
                case "Anaseguros": document.querySelector(".carousel__track").children[6].style.order = 50; break;
              }
            }
          });
          quote.setQuoteDatesAndId(quote_data, activeProviders.value);
          
        });
      }


      function fillQuoteJson() {
        arrayAnswers.value.forEach(answer => {
          if (answer.question.includes("Nombre(s)")) {
            parametrosCotizacion.value.cliente.nombres = answer.answer;
            parametrosSalesforce.value.cliente.nombres = answer.answer;
          }
          if (answer.question.includes("Apellido Paterno")) {
            parametrosCotizacion.value.cliente.apellido_paterno = answer.answer;
            parametrosSalesforce.value.cliente.apellido_paterno = answer.answer;
          }
          if (answer.question.includes("Apellido Materno")) {
            parametrosCotizacion.value.cliente.apellido_materno = answer.answer;
            parametrosSalesforce.value.cliente.apellido_materno = answer.answer;
          }
          if (answer.question.includes("Correo electrónico")) {
            parametrosCotizacion.value.cliente.email = answer.answer;
            parametrosSalesforce.value.cliente.email = answer.answer;
          }
          if (answer.question.includes("Código Postal")) {
            parametrosCotizacion.value.cliente.codigo_postal = answer.answer;
            parametrosSalesforce.value.cliente.codigo_postal = answer.answer;
          }
          if (answer.question.includes("Teléfono")) {
            parametrosCotizacion.value.cliente.telefono = answer.answer;
            parametrosSalesforce.value.cliente.telefono = answer.answer;
          }
          if (answer.question.includes("Cuándo naciste")) {
            parametrosCotizacion.value.cliente.fecha_nacimiento = answer.answer;
            parametrosSalesforce.value.cliente.fecha_nacimiento = answer.answer;
          }
          if (answer.question.includes("tú eres")) {
            parametrosCotizacion.value.cliente.sexo = "Masculino";
            if(answer.answer != "Hombre")
            { 
              parametrosCotizacion.value.cliente.sexo = "Femenino";
            }
            parametrosSalesforce.value.cliente.sexo = parametrosCotizacion.value.cliente.sexo;
          }
          if (answer.question.includes("¿Tu auto es un sedán o pickup?")) {
            parametrosCotizacion.value.auto.tipo = answer.answer_id;
            parametrosSalesforce.value.activo.tipo_auto = answer.answer;
          }

          if (answer.question.includes("¿Qué modelo (año) es tu auto?")) {
            parametrosCotizacion.value.auto.modelo = answer.answer_id;
            parametrosSalesforce.value.activo.modelo = answer.answer;
            if(validar_años_cobertura_rc(parseInt(parametrosCotizacion.value.auto.modelo))){
              var opt_cobertura = document.getElementsByName("cobertura")[0];
              opt_cobertura[0].removeAttribute('selected');
              opt_cobertura[1].setAttribute('disabled','true');
              opt_cobertura[1].setAttribute('hidden','true');
              opt_cobertura[2].setAttribute('disabled','true');
              opt_cobertura[2].setAttribute('hidden','true');
              opt_cobertura[3].setAttribute('selected','true');
              parametrosCotizacion.value.auto.cobertura = "RC";
              coverage.value = "RC";
            }
          }

          if (answer.question.includes("¿Qué marca es tu vehículo?")) {
            parametrosCotizacion.value.auto.marca = answer.answer;
            parametrosCotizacion.value.auto.marca_id = answer.answer_id;
            parametrosSalesforce.value.activo.marca = answer.answer;
          }

          if (answer.question.includes("Selecciona el modelo de tu auto")) {
            parametrosCotizacion.value.auto.submarca = answer.answer;
            parametrosCotizacion.value.auto.submarca_id = answer.answer_id;
            parametrosSalesforce.value.activo.submarca = answer.answer;
          }
          if (answer.question.includes("¿Qué versión es tu auto?")) {
            parametrosCotizacion.value.auto.descripcion = answer.answer;
            parametrosCotizacion.value.auto.descripcion_id = answer.answer_id;
            parametrosSalesforce.value.activo.descripcion_auto = answer.answer;
          }
        });
        console.log("activeProviders", activeProviders.value);
        isLoading.value = true;
        sendRequestToQuoteRegister(parametrosCotizacion.value).then((response) => {
          parametrosCotizacion.value.cotizacion_id = response;
          parametrosSalesforce.value.informacion_cotizacion.cobertura = coverage.value;
          parametrosSalesforce.value.informacion_cotizacion.frecuencia_de_pago = paymentFrecuency.value;
          parametrosSalesforce.value.informacion_cotizacion.cotizacion_id = parametrosCotizacion.value.cotizacion_id;
          parametrosSalesforce.value.informacion_cotizacion.recuperar_cotizacion = "https://anter.com.mx/cotizaciones?id=" + parametrosCotizacion.value.cotizacion_id;
          saveProspectSalesforce()
          quoter();
        });
        console.log("responseQuoteInfo.value ",responseQuoteInfo.value);
      }

      function validar_años_cobertura_rc(modelo_auto){
        var a = new Date()
        if((a.getFullYear()-20) >= modelo_auto){
          return true;
        }
        return false;
      }
      
      async function saveProspectSalesforce(){
        let config = ref({
          headers: {
            'Content-Type': 'application/json'
          }
        });
        
        axios.post(anterapi + "/salesforce/registrar_cliente", parametrosSalesforce.value, config.value).then((response) => {
          console.log("respuesta de /salesforce/registrar_cliente", response.data);
        }).catch(error => {
          console.log("Se presentó un errror: ",error)
        });
      }

      async function quoter() {
        // if (aspects.value[0] == "1") {
          for (const provider of activeProviders.value) {
            const quote = new Quote();
            quote.resetQuoteInfo(provider.nombre);
            const response = await register(provider.nombre);
            await response;
          }
        // }
      }

      function register(provider){
        const behavior = new BehaviorJson(activeProviders.value, parametrosCotizacion.value);
          behavior.buildJsonQuote(provider).then((response) => {
            console.log("Respuesta Cotización: "+ provider);
            var quotes = response.data.respuesta;
            responseQuoteInfo.value.push(quotes);
            // sendRequestToQuoteRegisterResponse(quotes);
            
            var quote_prices = setMoneyFormat(quotes);
            
            const quote = new Quote();
            quote.organizeQuoteInfo(quote_prices);
            sortQuotesCarousel();
          }).catch((error) => {
            console.log("ERROR " + provider + ":" +error.response.data.errores);
            const quote = new Quote();
            quote.getErrorRow(provider);
            // sendRequestToQuoteRegisterResponse(error.response.data.errores);
            switch(provider){
            // case "Gnp": document.querySelector(".carousel__track").children[0].style.order = 50; break;
            case "Axa": document.querySelector(".carousel__track").children[0].style.order = 50; break;
            case "Qualitas": document.querySelector(".carousel__track").children[1].style.order = 50; break;
            case "Primeroseguros": document.querySelector(".carousel__track").children[2].style.order = 50; break;
            case "Hdi": document.querySelector(".carousel__track").children[3].style.order = 50; break;
            case "Chubb": document.querySelector(".carousel__track").children[4].style.order = 50; break;
            // case "Atlas": document.querySelector(".carousel__track").children[4].style.order = 50; break;
            case "Zurich": document.querySelector(".carousel__track").children[5].style.order = 50; break;
            case "Anaseguros": document.querySelector(".carousel__track").children[6].style.order = 50; break;
          } 
          }).finally(() => {})
      }
      
      function sortQuotesCarousel(){
        responseQuoteInfo.value.sort(function (a,b){
          var value_A = parseFloat(a.informacion_cotizacion.importe_total.replace(',',''));
          var value_B = parseFloat(b.informacion_cotizacion.importe_total.replace(',',''));

          return (value_A < value_B) ? -1 : (value_A > value_B) ? 1 : 0;
        });
        for(var i = 0; i < responseQuoteInfo.value.length; i++){
          switch(responseQuoteInfo.value[i].proveedor){
            // case "Gnp": document.querySelector(".carousel__track").children[0].style.order = i; break;
            case "Axa": document.querySelector(".carousel__track").children[0].style.order = i; break;
            case "Qualitas": document.querySelector(".carousel__track").children[1].style.order = i; break;
            case "Primeroseguros": document.querySelector(".carousel__track").children[2].style.order = i; break;
            case "Hdi": document.querySelector(".carousel__track").children[3].style.order = i; break;
            case "Chubb": document.querySelector(".carousel__track").children[4].style.order = i; break;
            // case "Atlas": document.querySelector(".carousel__track").children[4].style.order = i; break;
            case "Zurich": document.querySelector(".carousel__track").children[5].style.order = i; break;
            case "Anaseguros": document.querySelector(".carousel__track").children[6].style.order = i; break;
          }
        }
        
      }


      function setMoneyFormat(response){
        response.informacion_cotizacion.importe_total = parseFloat(response.informacion_cotizacion.importe_total);
        response.informacion_cotizacion.importe_total = response.informacion_cotizacion.importe_total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

        response.informacion_cotizacion.importe_total_sin_descuento = parseFloat(response.informacion_cotizacion.importe_total_sin_descuento);
        response.informacion_cotizacion.importe_total_sin_descuento = response.informacion_cotizacion.importe_total_sin_descuento.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

        response.informacion_cotizacion.primer_importe = parseFloat(response.informacion_cotizacion.primer_importe);
        response.informacion_cotizacion.primer_importe = response.informacion_cotizacion.primer_importe.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

        return response;
      }

      async function sendRequestToQuoteRegister(quote_json) {
        var data = quote_json;

        return await axios.post(anterapi + '/cotizacion_auto/registrar_solicitud', data, config.value).then((response) => {
          console.log("folio cotizacion", response.data.respuesta);
          responseQuoteRegister.value = response.data.respuesta;
          quoteId.value = response.data.respuesta.id;
          
          var currentTime = new Date();
          response.data.respuesta.fecha_cotizacion = new Date();
          response.data.respuesta.fecha_expiracion = currentTime.setDate(currentTime.getDate()+14);
          const quote = new Quote();
          
          quote.setQuoteDatesAndId(response.data.respuesta);
          return response.data.respuesta.id;
        });
      }


      function requote(e) {
        e.preventDefault();

        responseQuoteInfo.value = [];

        parametrosCotizacion.value.auto.cobertura = coverage.value;
        parametrosCotizacion.value.auto.frecuencia_de_pago = paymentFrecuency.value;
        sendRequestToQuoteRegister(parametrosCotizacion.value).then((response) => {
          parametrosCotizacion.value.cotizacion_id = response;
          quoter();
        });
        console.log("responseQuoteInfo.value ",responseQuoteInfo.value);
      }

      function showCoverages(proveedor) {
        responseQuoteInfo.value.forEach(element => {
          if(proveedor == element.proveedor){
            proveedor = element;
          }
        });
    
        var thead = "<table style='width:100%'><thead><tr><th class='bg-orange-500 text-sm font-semibold text-white text-center text-align: -webkit-center; text-align: -moz-center; text-align: -khtml-center; text-align: -ms-center; text-align: center;' style='width: 50%;''>Coberturas</th><th class='bg-orange-500 text-sm font-semibold text-white text-center text-align: -webkit-center; text-align: -moz-center; text-align: -khtml-center; text-align: -ms-center; text-align: center;' style='width: 50%;'' style='width: 50%;'>Suma asegurada</th></tr></thead><tbody>";
        var thead2 = "<table style='width:100%'><thead><tr><th class='bg-orange-500 text-sm font-semibold text-white text-center text-align: -webkit-center; text-align: -moz-center; text-align: -khtml-center; text-align: -ms-center; text-align: center;' style='width: 50%;'>Coberturas Especiales</th><th class='bg-orange-500 text-sm font-semibold text-white text-center text-align: -webkit-center; text-align: -moz-center; text-align: -khtml-center; text-align: -ms-center; text-align: center;' style='width: 50%;'' style='width: 50%;'>Suma asegurada</th></tr></thead><tbody>";
        var coberturasDetallada = thead + addRowsCoverage(proveedor.informacion_cotizacion.coberturas_basicas) + "</tbody></table><br/><br/>" + thead2 +  addRowsCoverage(proveedor.informacion_cotizacion.coberturas_especiales);
        Swal.fire({
          title: 'Listado de coberturas',
          html: coberturasDetallada,
          confirmButtonText: "OK",
        })
      }

      function addRowsCoverage(coverages) {
        var coverageRows = "";
        coverages.forEach(coverage => {
          coverageRows += "<tr class='mb-1' style='border-bottom: 1px solid #e2e8f0;'>"+
                                  "<td>"+coverage.nombre+"</td>"+
                                  "<td class='text-aKingBlue'> "+coverage.suma_asegurada+"</td>"+
                                "</tr>"
        });
        return coverageRows;
      }
      async function sendQuoteByMessage(){
        if (responseQuoteInfo.value.length > 0) {
          let phone = "";
          let auto = ""; let año = ""; let marca = "";
          arrayAnswers.value.forEach(answer => {
            if (answer.question.includes("Teléfono")) {
              phone = answer.answer;
            }
            if (answer.question.includes("¿Qué modelo (año) es tu auto?")){
              año = answer.answer;
            }
            if (answer.question.includes("¿Qué marca es tu vehículo?")) {
              marca = answer.answer;
            }
            if (answer.question.includes("Selecciona el modelo de tu auto")) {
              auto = answer.answer;
            }
          });
          carInfo.value = marca + " " + auto + " " + año;
          console.log("Auto ", carInfo);
          await Swal.fire({
            title: 'Enviar cotización al WhatsApp',
            input: "tel",
            inputAttributes: {
              value: phone
            },
            inputLabel: 'Telefono',
            confirmButtonText: "Enviar",
            cancelButtonText: "Cancelar",
            showCancelButton: true,
            inputValidator: (value) => {
              if (!value) {
                return 'Es necesario ingresar un número telefonico'
              }
              if (value.length != 10) {
                return 'Ingrese un número telefonico con 10 dígitos'
              }
              let mensaje = "https://api.whatsapp.com/send?phone=+521+"+value+"&text=%C2%A1Hola!%20Gracias%20por%20cotizar%20en%20ANTER%20Tu%20Seguro%20Online,%20te%20comparto%20la%20informacion%20de%20la%20cotizacion%20con%20folio:%20"+quoteId.value+"%20%0d%20-Auto:%20"+carInfo.value+"%20-Forma%20de%20pago:%20"+paymentFrecuency.value+"%20-Cobertura:%20"+coverage.value+".%0d%20Solo%20entra%20en%20este%20enlace:%20https://anter.com.mx/cotizaciones?id="+quoteId.value;
              window.open(mensaje);
            }
          })
        } else {
          Swal.fire(
            "Aviso",
            "No se encontrarón cotizaciones para compartir.",
            "info"
          );
        }
      }

      async function saveQuotation() {
        if (responseQuoteInfo.value.length > 0) {
          let email = "";
          arrayAnswers.value.forEach(answer => {
            if (answer.question.toUpperCase().includes("CORREO")) {
              email = answer.answer;
            }
          });
          console.log("email ", email);
          await Swal.fire({
            title: 'Enviar cotización al correo',
            input: "email",
            inputAttributes: {
              value: email
            },
            inputLabel: 'Correo',
            confirmButtonText: "Enviar",
            cancelButtonText: "Cancelar",
            showCancelButton: true,
            inputValidator: (value) => {
              if (!value) {
                return 'Es necesario ingresar un correo'
              }
              if (value.length < 5) {
                return 'Ingrese un correo válido'
              }
              sendEmail(value);
            }
          })
        } else {
          Swal.fire(
            "Aviso",
            "No se encontrarón cotizaciones que guardar.",
            "info"
          );
        }
      }
      async function sendEmail(email) {
        isLoading.valie = true;
        if (email == "" || email == null) {
          Swal.fire(
            "Error",
            "Es necesario ingresar un correo",
            "error"
          );
          return;
        }
        const sendEmail = new SendEmail();
        sendEmail.sendQuotationmail(email, parametrosCotizacion.value.cotizacion_id).then(() => {
          Swal.fire(
            "Correo enviado.",
            "El envió del correo se realizó correctamente.",
            "success"
          );
        }).catch((error) => {
          console.log("Se presentó un error al enviar el correo: ", error);
          Swal.fire(
            "Aviso",
            "Se presentó un error al enviar su cotización al correo.",
            "error"
          );
        }).finally(() => {
          isLoading.value = false;
        })
      }

      function back() {
        quoteId.value = "";
        router.push({
          name: "QuoteResume"
        });
      }

      return {
        car_description,
        selectedProducts,
        responseQuoteInfo,
        step,
        showQuestionsEmision,
        isLoading,
        parametrosEmision,
        requote,
        coverage,
        paymentFrecuency,
        saveQuotation,
        sendQuoteByMessage,
        message,
        back,
        currentProductName,
        showAuto,
        activeProviders,
        settings,
        breakpoints,
        quoter,
        showCoverages
      }
    }
  }
</script>

<style scoped>
  .carousel__item {
    min-height: 200px;
    width: 100%;
    background-color: var(--carousel-color-primary);
    color:  var(--carousel-color-white);
    font-size: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .carousel_slide {
    @apply p-10
  }


  .carousel__prev,
  .carousel__next {
    box-sizing: content-box;
    border: 5px solid #FF5733;
    background-color: #ffffff;
  }
</style>
