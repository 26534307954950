<template>
  <div class="min-h-screen bg-white flex">
    <change-password-container/>
  </div>
</template>
<script>
import ChangePasswordContainer from '@/components/AccountRecover/ChangePasswordContainer';

export default {
  components: {
    ChangePasswordContainer
  }
};
</script>