<template>

    <change-password-form/>
    <change-password-image/>

</template>
<script>
import ChangePasswordForm from '@/components/AccountRecover/ChangePasswordForm';
import ChangePasswordImage from '@/components/AccountRecover/ChangePasswordImage';

export default {
  components:{
    ChangePasswordForm,
    ChangePasswordImage
  }
}
</script>