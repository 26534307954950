<template>
  <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
    <div class="bg-white p-5 shadow-lg">
      <div class="flex">
        <h2 class="text-lg leading-6 font-medium text-cool-gray-900 w-full sm:w-1/4 mt-2">
          Personal
        </h2>
      </div>
      <div class="block sm:flex mt-5 sm:space-x-4 space-y-6 sm:space-y-0">
        <div class="w-full">
          <input
            v-model="searchValue"
            type="text"
            class="form-input py-3 px-4 block w-full transition ease-in-out duration-150"
            placeholder="Buscar..."
            id="input_searsh"
            @keyup="searchUser()"
          />
        </div>
        <div class="w-full">
          <select
            name="select-filter-user"
            id="select-filter-user"
            class="form-select py-3 px-4 block w-full transition ease-in-out duration-150"
            v-model="filterSelect"
          >
            <option value="0">Tipo de filtro</option>
            <option value="1">Nombre</option>
            <option value="2">Correo</option>
            <!-- <option value="3">Fecha ingreso</option> -->
          </select>
        </div>
        <div class="w-full">
          <button
            class="bg-white px-5 py-2 rounded-xl hover:bg-orange-700 w-full sm:w-2/3 transition duration-150 flex border-2 hover:border-orange-700 hover:text-white sm:float-right active:bg-orange-700 shadow-md focus:outline-none focus:shadow-outline-orange focus:border-orange-700"
            v-on:click="OpenModal()"
            id="btn-regiter-user"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
              />
            </svg>
            <label class="ml-2">Usuario</label>
          </button>
        </div>
      </div>
    </div>

    <div class="w-full py-10 px-2 sm:px-0">
      <!-- contenido de la tarjeta -->
      <div v-for="datauser in listaDePersonalSeleccionado" :key="datauser">
        <CardsUsers 
          :userObj="datauser"
          ref="CardsUsers"  
          v-on:reload_info_user="getPersonalByFilter(20, 1)"  />
      </div>
      <!-- contenido de la tarjeta -->
    </div>

    <div class="bg-white p-5 shadow-lg">
      <div class="flex">
        <div class="m-auto flex">
          <div
            v-if="pageActu != 1"
            class="h-10 w-10 bg-orange-700 text-white hover:bg-orange-500 rounded-l-lg items-center justify-center flex pagination-items"
            @click="selectPagination(1)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M11 19l-7-7 7-7m8 14l-7-7 7-7"
              />
            </svg>
          </div>
          
            <div class="h-10 w-10 bg-orange-700 text-white hover:bg-orange-500 items-center justify-center flex pagination-items"
            style="cursor: pointer"
            v-for="page in pagesOfRow"
            :key="page"
            @click="selectPagination(page)"
            >
              {{ page }}
            </div>
          
          <div
            v-if="pageActu < (total_rows/20)"
            class="h-10 w-10 bg-orange-700 text-white hover:bg-orange-500 items-center justify-center flex rounded-r-lg pagination-items"
            @click="selectPagination(parseInt(total_rows/20)+1)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M13 5l7 7-7 7M5 5l7 7-7 7"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>

    <!-- modal de agregar usuario -->
    <modal-add-user ref="ModalAddUser" v-on:reload_data_user="getPersonalByFilter(20, 1)" />
    <!-- modal de agregar usuario -->
  </div>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import { onMounted, ref } from "vue";
import CardsUsers from "@/components/dashboard/personal/CardsUsers.vue";
import ModalAddUser from "@/components/dashboard/personal/ModalAddUser.vue";
import { getUserAuth } from "@/helpers/auth.js";
export default {
  components: {
    CardsUsers,
    ModalAddUser,
  },
  setup() {
    const anterapi = process.env.VUE_APP_ANTERAPI;
    let listaTotal = ref([]);
    let listaDePersonal = ref ([]);
    let listaDePersonalSeleccionado = ref([]);
    let filterSelect = ref(0);
    let searchValue = ref("");
    let pagesOfRow = ref([1]);
    let pageActu = ref(0);
    let filtro = ref("");
    let total_rows = ref("");
    let responsivePagination = ref({
      items: 10,
    });
    onMounted(async () => {
      $(window).resize(function () {
        // responsivePagination.value.items = window.screen.width <= 639 ? 1 : 3;
        // responsivePagination.value.items = window.screen.width <= 600 ? 1 : 10;
        // responsivePagination.value.items =
        //   window.screen.width >= 400 && window.screen.width <= 500 ? 5 : "";
        if (window.screen.width >= 100 && window.screen.width <= 352)
          responsivePagination.value.items = 1;
        if (window.screen.width >= 352 && window.screen.width <= 428)
          responsivePagination.value.items = 2;
        if (window.screen.width >= 428 && window.screen.width <= 584)
          responsivePagination.value.items = 5;
        if (window.screen.width >= 600) responsivePagination.value.items = 10;
      });
      filtro.value = 20;
      await getPersonalByFilter(filtro.value, 1);
      // await getTotalPersonal();

      document.getElementById("input_searsh").value = "";
      
    });
    
    function searchUser() {
      setTimeout(function () {
        listaDePersonal.value = [];
        listaTotal.value.forEach((listado) => {
          if (
            listado.persona.nombres.toUpperCase().indexOf(searchValue.value.toUpperCase()) >
              -1 &&
            (filterSelect.value == "1" || filterSelect.value == "0")
          ) {
            listaDePersonal.value.push(listado);
          }
          if (
            listado.email.toUpperCase().indexOf(searchValue.value.toUpperCase()) > -1 &&
            filterSelect.value == "2"
          ) {
            listaDePersonal.value.push(listado);
          }
        });

      }, 2000);
    }

    async function getPersonalByFilter(filter, selected_page) {
      axios.get(
        anterapi + "/empleados/ordenar_personal_por_filtro?token_auth=" +
        getUserAuth() +
          "&filtro=" +
          filter
      )
      .then((res) => {
        if (res.status == 200) {
          getTotalPersonal();
          total_rows.value = res.data.respuesta.filas;
          listaDePersonal.value = res.data.respuesta.lista_ordenada;
          listaDePersonalSeleccionado.value = res.data.respuesta.lista_ordenada[selected_page-1];
          pageActu.value = selected_page;
          pageofRows();
          selectPagination(selected_page);
          
        }
      });
    }

    function getTotalPersonal() {
      axios.get(
        anterapi + "/empleados/consultar_personal?token_auth=" +
        getUserAuth()
      )
      .then((res) => {
        if (res.status == 200) {
          listaTotal.value = res.data.respuesta.informacion;
        }
      });
    }

    function selectPagination(page){
      pageActu.value = page;
      listaDePersonalSeleccionado.value = listaDePersonal.value[page-1];
    }

    function OpenModal() {
      $("#show-modal-active-user").addClass("show-modal-active");
    }

    function pageofRows() {
      pagesOfRow.value = [];
      for (let index = 0; index <= (total_rows.value/filtro.value); index++) {
        pagesOfRow.value.push(index+1);
      }
    }

    return {
      getPersonalByFilter,
      getTotalPersonal,
      selectPagination,
      OpenModal,
      listaDePersonal,
      listaDePersonalSeleccionado,
      searchUser,
      filterSelect,
      listaTotal,
      searchValue,
      pagesOfRow,
      pageActu,
      filtro,
      total_rows,
      responsivePagination,
    };
  },
};
</script>

<style>
.toggle-checkbox:checked {
  @apply: right-0 border-green-400;
  right: 0;
  border-color: #68d391;
}
.toggle-checkbox:checked + .toggle-label {
  @apply: bg-green-400;
  background-color: #68d391;
}
button:focus {
  outline: none;
}
@media (max-width: 300px) {
  .pagination-items {
    height: 20px;
    width: 30px;
  }
}
/* :focus {
  outline: none;
} */
</style>