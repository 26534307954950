<template>
  <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
    <div class="bg-white p-5 shadow-lg">
      <div class="flex">
        <h2 class="text-lg leading-6 font-medium text-cool-gray-900 w-full sm:w-1/4 mt-2">
          Preguntas
        </h2>
      </div>
      <div class="block sm:flex mt-5 sm:space-x-4 space-y-6 sm:space-y-0">
        <div class="w-full">
          <!-- <input
            v-model="searchValue"
            type="text"
            class="form-input py-3 px-4 block w-full transition ease-in-out duration-150"
            placeholder="Buscar..."
            id="input_searsh"
            @keyup="searchQuestions()"
          /> -->
        </div>
        <div class="w-full">
          <!-- <select
            name="select-filter-user"
            id="select-filter-user"
            class="form-select py-3 px-4 block w-full transition ease-in-out duration-150"
            v-model="filterSelect"
          >
            <option selected disabled value="0">Tipo de filtro</option>
            <option value="1">Categoria</option>
            <option value="2">Subcategoria</option>
            <option value="3">Pregunta</option>
            <option value="4">Status</option>
          </select> -->
        </div>
        <div class="w-full">
          <!-- <button
            class="bg-white px-5 py-2 rounded-xl hover:bg-orange-700 w-full sm:w-2/3 transition duration-150 flex border-2 hover:border-orange-700 hover:text-white sm:float-right active:bg-orange-700 shadow-md focus:outline-none focus:shadow-outline-orange focus:border-orange-700"
            v-on:click="OpenModalAddQuestion()"
            id="btn-register-question"
          >
            <label class="ml-2">Agregar Pregunta</label>
          </button> -->
        </div>
      </div>
    </div>

    <div class="w-full py-10 px-2 sm:px-0">
      <!-- contenido de la tarjeta -->
      <div v-for="question in questionsList" :key="question">
        <QuestionCard 
          :questionInfo="question"
          ref="QuestionCard"  
          v-on:reload_questions_info="getQuestions()"  />
      </div>
      <!-- contenido de la tarjeta -->
    </div>

    <!-- <div class="bg-white p-5 shadow-lg">
      <div class="flex">
        <div class="m-auto flex">
          <div
            v-if="pageActu != 1"
            class="h-10 w-10 bg-orange-700 text-white hover:bg-orange-500 rounded-l-lg items-center justify-center flex pagination-items"
            @click="selectPagination(1)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M11 19l-7-7 7-7m8 14l-7-7 7-7"
              />
            </svg>
          </div>
          
            <div class="h-10 w-10 bg-orange-700 text-white hover:bg-orange-500 items-center justify-center flex pagination-items"
            style="cursor: pointer"
            v-for="page in pagesOfRow"
            :key="page"
            @click="selectPagination(page)"
            >
              {{ page }}
            </div>
          
          <div
            v-if="pageActu < (total_rows/20)"
            class="h-10 w-10 bg-orange-700 text-white hover:bg-orange-500 items-center justify-center flex rounded-r-lg pagination-items"
            @click="selectPagination(parseInt(total_rows/20)+1)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M13 5l7 7-7 7M5 5l7 7-7 7"
              />
            </svg>
          </div>
        </div>
      </div>
    </div> -->

    <!-- modal de agregar pregunta -->
    <!-- <modal-add-question ref="ModalAddQuestion" v-on:reload_data_questions="getQuestions()" /> -->
    
  </div>
</template>

<script>
import $ from "jquery";

import { onMounted, ref, inject } from "vue";
import QuestionCard from "@/components/dashboard/questions/QuestionCard.vue";
import QuestionConfig from "@/classes/QuestionConfig.js";
// import ModalAddQuestion from "@/components/dashboard/questions/ModalAddQuestion.vue";

export default {
  components: {
    QuestionCard,
    // ModalAddQuestion
  },
  setup() {
    const isLoading = inject('isLoading');
    
    let filterSelect = ref(0);
    let searchValue = ref("");
    let questionsList = ref([]);
    let foundQuestions = ref ([]);
    let responsivePagination = ref({
      items: 10,
    });
    onMounted(async () => {
      $(window).resize(function () {
        if (window.screen.width >= 100 && window.screen.width <= 352)
          responsivePagination.value.items = 1;
        if (window.screen.width >= 352 && window.screen.width <= 428)
          responsivePagination.value.items = 2;
        if (window.screen.width >= 428 && window.screen.width <= 584)
          responsivePagination.value.items = 5;
        if (window.screen.width >= 600) responsivePagination.value.items = 10;
      });
      await getQuestions();  

      // document.getElementById("input_searsh").value = "";
      
    });
    
    async function getQuestions() {
      isLoading.value = true;
      const questionsConfig = new QuestionConfig();
      let result = await questionsConfig.get();

      if(result.status == 200){
        questionsList.value = result.data.respuesta.informacion;
        
        isLoading.value = false;
      }else{
        isLoading.value = false;
        console.log(result.data.respuesta.message);
      }
    }

    function searchQuestions() {
      setTimeout(function () {
        foundQuestions.value = [];
        questionsList.value.forEach((listado) => {
          if (
            listado.categoria.toUpperCase().indexOf(searchValue.value.toUpperCase()) >
              -1 &&
            (filterSelect.value == "1" || filterSelect.value == "0")
          ) {
            foundQuestions.value.push(listado);
          }
          if (
            listado.subcategoria.toUpperCase().indexOf(searchValue.value.toUpperCase()) > -1 &&
            filterSelect.value == "2"
          ) {
            foundQuestions.value.push(listado);
          }
          if (
            listado.pregunta.toUpperCase().indexOf(searchValue.value.toUpperCase()) > -1 &&
            filterSelect.value == "3"
          ) {
            foundQuestions.value.push(listado);
          }
        });

      }, 2000);
    }

    function OpenModalAddQuestion() {
      $("#show-modal-active-question").addClass("show-modal-active");
    }

    // function OpenModalEditQuestion() {
    //   $("#show-modal-edit-active-question").addClass("show-modal-active");
    // }
    
    return {
      OpenModalAddQuestion,
      // OpenModalEditQuestion,
      searchQuestions,
      filterSelect,
      searchValue,
      questionsList,
      responsivePagination,
      foundQuestions
    };
  },
};
</script>

<style>
.toggle-checkbox:checked {
  @apply: right-0 border-green-400;
  right: 0;
  border-color: #68d391;
}
.toggle-checkbox:checked + .toggle-label {
  @apply: bg-green-400;
  background-color: #68d391;
}
button:focus {
  outline: none;
}
@media (max-width: 300px) {
  .pagination-items {
    height: 20px;
    width: 30px;
  }
}
/* :focus {
  outline: none;
} */
</style>