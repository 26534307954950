<template>
  <div class="min-h-screen bg-white flex">
    <account-recover-container/>
  </div>
</template>
<script>
import AccountRecoverContainer from '@/components/AccountRecover/AccountRecoverContainer';

export default {
  components: {
    AccountRecoverContainer
  }
};
</script>