<template>
  <div id="nosotros" class="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
    <div class="relative" data-aos="fade-right" data-aos-duration="1000">
      <h4 class="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
        ¿Quiénes somos?
      </h4>
      <p class="mt-3 text-lg leading-7 text-gray-500">
        Somos una empresa mexicana que busca facilitar el proceso de cotización,
        comparación y compra de un seguro de auto, gastos médicos y vida.
      </p>
      <p class="mt-3 text-lg leading-7 text-gray-500">
        En Anter, trabajamos con diferentes aseguradoras para brindarle a
        nuestros clientes la posibilidad de elegir el seguro que más se adecúe a
        sus necesidades.
      </p>
      <p class="mt-3 text-lg leading-7 text-gray-500">
        Con el objetivo de proteger todos los aspectos de su vida, hemos añadido
        seguro de gastos médicos y seguro de vida.
      </p>
    </div>
    <div class="mt-10 -mx-4 relative lg:mt-0">
      <svg class="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width="784" height="404" fill="none" viewBox="0 0 784 404">
        <defs>
          <pattern id="ca9667ae-9f92-4be7-abcb-9e3d727f2941" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor"/>
          </pattern>
        </defs>
        <rect width="784" height="404" fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"/>
      </svg>
      <img class="relative mx-auto" width="490" data-aos="fade-left" data-aos-duration="1000" data-aos-delay="100" data-aos-anchor-placement="top-center" src="@/assets/images/ilustraciones/ilustraciones_dudas.svg"/>
    </div>
  </div>
</template>
