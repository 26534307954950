<template>
  <div class="shadow pb-6">
    <div class="max-w-screen-xl mx-auto px-4 sm:px-6">
      <nav class="relative flex items-center justify-between sm:h-10">
        <div class="flex items-center flex-1">
          <div class="flex items-center justify-between w-full md:w-auto">
            <a href="/" aria-label="Home">
              <img class="h-6 w-auto" src="@/assets/images/logo.png" alt="Logo" />
            </a>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>