<style scoped>
body {
  align-items: center;
  background: #e3e3e3;
  display: flex;
  height: 100vh;
  justify-content: center;
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}
.slider {
  background: white;
  margin: auto;
  overflow: hidden;
  position: relative;
}
.slider::before,
.slider::after {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  content: "";
  position: absolute;
  width: 200px;
  z-index: 2;
}
.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.slider::before {
  left: 0;
  top: 0;
}
.slider .slide-track {
  animation: scroll 40s linear infinite;
  display: flex;
  width: calc(250px * 14);
}
.slider .slide {
  margin-top: -30px;
  height: 200px;
}
</style>
<template>
  <div class="bg-white border-t border-gray-200">
    <div class="slider">
      <p class="text-center uppercase font-bold text-gray-700">Te protegemos junto a </p>
      <div class="slide-track">
        <div class="slide col-span-1 flex justify-center">
          <img class="h-auto" src="@/assets/images/aseguradoras/logo_Gnp.svg" alt="gnp" />
        </div>
        <div class="slide col-span-2 flex justify-center md:col-span-3">
          <img class="h-auto" src="@/assets/images/aseguradoras/logo_Qualitas.svg" alt="Qualitas"/>
        </div>
        <div class="slide col-span-1 flex justify-center">
          <img class="h-auto" src="@/assets/images/aseguradoras/logo_Axa.svg" alt="Axa" />
        </div>
        <div class="slide col-span-1 flex justify-center md:col-span-3">
          <img class="h-auto" src="@/assets/images/aseguradoras/logo_Hdi.svg" alt="HDI" />
        </div>
        <div class="slide col-span-1 flex justify-center">
          <img class="h-auto" src="@/assets/images/aseguradoras/logo_Primeroseguros.svg" alt="Primero seguros" />
        </div>
        <div class="slide col-span-1 flex justify-center">
          <img class="h-auto" src="@/assets/images/aseguradoras/logo_Chubb.svg" alt="Chubb" />
        </div>
        <div class="slide col-span-1 flex justify-center">
          <img class="h-auto" src="@/assets/images/aseguradoras/logo_Zurich.svg" alt="Zurich" />
        </div>
        <div class="slide col-span-1 flex justify-center">
          <img class="h-auto" src="@/assets/images/aseguradoras/logo_Anaseguros.svg" alt="Ana seguros" />
        </div>
        <div class="slide col-span-1 flex justify-center">
          <img class="h-auto" src="@/assets/images/aseguradoras/logo_Atlas.svg" alt="Atlas" />
        </div>
        <div class="slide col-span-1 flex justify-center">
          <img class="h-auto" src="@/assets/images/aseguradoras/logo_Gnp.svg" alt="gnp" />
        </div>
        <div class="slide col-span-2 flex justify-center md:col-span-3">
          <img class="h-auto" src="@/assets/images/aseguradoras/logo_Qualitas.svg" alt="Qualitas"/>
        </div>
        <div class="slide col-span-1 flex justify-center">
          <img class="h-auto" src="@/assets/images/aseguradoras/logo_Axa.svg" alt="Axa" />
        </div>
        <div class="slide col-span-1 flex justify-center md:col-span-3">
          <img class="h-auto" src="@/assets/images/aseguradoras/logo_Hdi.svg" alt="HDI" />
        </div>
        <div class="slide col-span-1 flex justify-center">
          <img class="h-auto" src="@/assets/images/aseguradoras/logo_Primeroseguros.svg" alt="Primero seguros" />
        </div>
        <div class="slide col-span-1 flex justify-center">
          <img class="h-auto" src="@/assets/images/aseguradoras/logo_Chubb.svg" alt="Chubb" />
        </div>
        <div class="slide col-span-1 flex justify-center">
          <img class="h-auto" src="@/assets/images/aseguradoras/logo_Zurich.svg" alt="Zurich" />
        </div>
        <div class="slide col-span-1 flex justify-center">
          <img class="h-auto" src="@/assets/images/aseguradoras/logo_Anaseguros.svg" alt="Ana seguros" />
        </div>
        <div class="slide col-span-1 flex justify-center">
          <img class="h-auto" src="@/assets/images/aseguradoras/logo_Atlas.svg" alt="Atlas" />
        </div>
      </div>
    </div>
  </div>
</template>