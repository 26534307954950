<template>
<div>
  <div class="vld-parent">
    <loading :active="isLoading" :can-cancel="false" :color="'#bfd243'" :height="120" :width="400" :is-full-page="true">
    </loading>
  </div>
  <div v-if="typeTag == 'text' || typeTag == 'date' || typeTag == 'number'">
    <input :type="typeTag" :class="customClass" required :id="questionId" :name="answer" @blur="emitUpdateInput" @keyup="capitalize" />
    <div class="p-2">
      <div class="-mt-6  mb-5">
        <div class="text-sm  font-normal  max-w-full flex-initial text-red-700 ">
          <p :id="'alert'+questionId"></p>
        </div>
      </div>
    </div>
  </div>
  <div v-if="arrayOptions.length == 0">
    <div v-if="typeTag == 'radio'">
      <div>
        <input :type="typeTag" :class="customClass" value="Si" :name="questionId" @blur="emitUpdateInput" id="Si" :child="childs" />{{"Si"}}
        <input :type="typeTag" :class="customClass" value="No" :name="questionId" @blur="emitUpdateInput" id="No" :child="childs" />{{"No"}}
        <div class="p-2">
          <div class="-mt-6  mb-5">
            <div class="text-sm  font-normal  max-w-full flex-initial text-red-700 ">
              <p :id="'alert'+questionId"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="typeTag == 'date range'" class="flex flex-group">
      <div>
        De:
        <input type="date" :class="customClass" :name="questionId" @change="emitUpdateInput" title="startDate" />
        A:
        <input type="date" :class="customClass" :name="questionId" @change="emitUpdateInput" title="endDate" />
      </div>
      <div class="p-2">
        <div class="-mt-6  mb-5">
          <div class="text-sm  font-normal  max-w-full flex-initial text-red-700 ">
            <p :id="'alert'+questionId"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="arrayOptions.length > 0">
    <div v-if="typeTag == 'radio'">
      <div v-for="param in arrayParams" :key="param.value">
        <input :type="typeTag" :class="customClass" :value="param.value" :name="questionId" @blur="emitUpdateInput" :id="param.id" />{{param.value}}
      </div>
      <div class="p-2">
        <div class="-mt-6  mb-5">
          <div class="text-sm  font-normal  max-w-full flex-initial text-red-700 ">
            <p :id="'alert'+questionId"></p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="typeTag == 'checkbox'">
      <div v-for="param in arrayParams" :key="param.id">
        <input :type="typeTag" :class="customClass" :value="param.id" required :id="param.id" :name="param.value" />{{param.value}}
      </div>
      <div class="p-2">
        <div class="-mt-6  mb-5">
          <div class="text-sm  font-normal  max-w-full flex-initial text-red-700 ">
            <p :id="'alert'+questionId"></p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="typeTag == 'select' && arrayAddress.length == 0">
      <select :class="customClass" @blur="emitUpdateInput" :id="questionId">
        <option disabled value="" selected>Seleccionar</option>
        <option v-for="param in arrayParams" :key="param.id" :value="param.value" :name="param.id">{{param.value}}</option>
      </select>
      <div class="p-2">
        <div class="-mt-6  mb-5">
          <div class="text-sm  font-normal  max-w-full flex-initial text-red-700 ">
            <p :id="'alert'+questionId"></p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="typeTag == 'select' && arrayAddress.length != 0">
      <select :class="customClass" @blur="emitUpdateInput" :id="questionId">
        <option disabled value="" selected>Seleccionar</option>
        <option v-for="param in arrayAddress" :key="param.id" :value="param.value" :name="param.id" :state="param.state" :id_state="param.id_state" :id_town="param.id_town" :town="param.town" :id_city="param.id_city" :city="param.city">{{param.value}}</option>
      </select>
      <div class="p-2">
        <div class="-mt-6  mb-5">
          <div class="text-sm  font-normal  max-w-full flex-initial text-red-700 ">
            <p :id="'alert'+questionId"></p>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
</template>

<script>
import Validation from '@/classes/Validation.js';
import {
  inject,
  ref,
  onMounted
} from "vue";
import axios from "axios";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  name: "Options",
  components: {
    Loading

  },
  props: {
    arrayOptions: {
      type: Array
    },
    questionType: {
      type: String
    },
    questionId: {
      type: Number
    },
    questionDescription: {
      type: String
    },
    productName: {
      type: String
    },
    categoryName: {
      type: String
    },
    subcategoryName: {
      type: String
    },
    validations: {
      type: Array
    },
    childQuestions: {
      type: Array
    }

  },
  setup(props) {
    const questionId = ref("");
    const answer = ref("");
    const answers = ref([]);
    const typeTag = ref("");
    const customClass = ref("input");
    const arrayParams = ref([]);
    const isLoading = ref(false);
    const validated = ref(true);
    const child = ref([]);

    const questionIdGlobal = inject('questionId');
    const arrayIdQuestions = inject('arrayIdQuestions');
    arrayIdQuestions.value = [];
    const contCategory = inject('contCategory');
    const step = inject('step');
    const contSteps = inject('contSteps');
    const arrayAnswersDependents = inject('arrayAnswersDependents');
    const arrayAddress = ref([]);
    const issuanceParams = inject('issuanceParams');
    const currentSubcategoryName = inject('currentSubcategoryName');
    const message = inject('message');
    const childs = ref([]);

    const emitUpdateInput = async (event) => {
      //apply validation
      message.value = "";
      let element = document.getElementById(event.target.id);
      let index = null;
      let description = event.target.value;
      let answer_id = event.target.name;
      let question_id = event.target.id;

      if (typeTag.value == "select") {
        index = event.target.options.selectedIndex;
      }

      if (typeTag.value == "radio") {
        element = document.getElementsByName(event.target.name);
      }

      const validation = new Validation(event.target.value, props.validations, index, element);
      document.getElementById("alert" + props.questionId).innerHTML = await validation.applyValidation();

      if (document.getElementById("alert" + props.questionId).innerHTML == "") {
        validated.value = true;
      } else {
        validated.value = false;

      }
      /*if (props.questionType == "BOOLEAN") {
        if (child.value != undefined && child.value != null) {
          child.value.forEach(child => {
            if (event.target.value == "No") {
              let element = document.getElementById(child);
              element.setAttribute("disabled", ""); //turns required on
              element.disabled = true;
              //document.getElementById(labelName).innerText = "";
            } else {
              if (event.target.value == "Si") {
                let element = document.getElementById(child);
                element.removeAttribute("disabled");
              }
            }
          });
        }
      }*/
      if (props.questionType == "BOOLEAN") {
        let labelName = "";
        childs.value.forEach(child => {
          labelName = "";
          let input = document.getElementById(child);
          if (input == null) {
            let isEmptyRangeDates = false,
              validateRangeDates = [];
            let elements = document.getElementsByName(child);
            elements.forEach(element => {
              if (element.className == "date-range") {
                if (event.target.value == "No") {
                  element.setAttribute("disabled", "");
                  element.disabled = true;
                  element.value = "";
                  labelName = "alert"+element.name;
                  console.log("alabe",labelName);
                  document.getElementById(labelName).innerText = "";
                } else {
                  element.removeAttribute("disabled");
                  if (element.value) {
                    validateRangeDates.push(element.value);
                  } else {
                    isEmptyRangeDates = true;
                  }
                }
              }
            });

            if (event.target.value == "Si") {
              if (isEmptyRangeDates) {
                if (document.getElementById("alert" + props.questionId)) {
                  document.getElementById("alert" + props.questionId).value == "Seleccione las dos fechas";
                }
              } else {
                if (document.getElementById("alert" + props.questionId)) {
                  console.log(document.getElementById("alert" + props.questionId));
                  if (validateRangeDates[0] > validateRangeDates[1]) {
                    labelName = "alert" + props.questionId;
                    document.getElementById(labelName).innerText = "La fecha inicial no puede ser mayor a la fecha final";
                  }
                }
              }
            }
          } else {
            if (event.target.value == "No") {
              input.setAttribute("disabled", "");
              input.disabled = true;
            } else {
              input.removeAttribute("disabled");
            }
          }
        });
      }
      if (typeTag.value == 'date range') {
        let dates = JSON.parse(localStorage.getItem(event.target.name));

        if (!dates) {
          dates = {
            "startDate": "",
            "endDate": ""
          };
        }

        if (event.target.title == "startDate") {
          dates.startDate = event.target.value;
        } else {
          dates.endDate = event.target.value;
        }
        localStorage.setItem(event.target.name, JSON.stringify(dates));

        dates = JSON.parse(localStorage.getItem(event.target.name));
        console.log("starDate ",dates.startDate);
        console.log("endDate ", dates.endDate);
        if (dates.startDate != "" && dates.endDate != "") {
          let date = new Date();
          if (dates.startDate > dates.endDate) {
            let labelName = "alert" + props.questionId;
            document.getElementById(labelName).innerText = "La fecha inicial no puede ser mayor a la fecha final";
          }
          if (dates.startDate > date.toISOString() || dates.endDate > date.toISOString()) {
            let labelName = "alert" + props.questionId;
            document.getElementById(labelName).innerText = "La fecha ".concat(event.target.title == "startDate" ? "inicial" : "final","", " seleccionada no puede ser mayor a la fecha actual");
          }
        }   
      }

      //save answers
      if (event.target.value != "") {
        let state = "";
        let id_state = "";
        let id_city = "";
        let city = "";
        let id_town = "";
        let town = "";

        if (typeTag.value == "select") {
          description = event.target.options[index].innerText;
          answer_id = event.target.options[event.target.options.selectedIndex].attributes.name.nodeValue;
        }
        if (typeTag.value == "radio") {
          answer_id = event.target.id;
          question_id = event.target.name;
        }

        if (typeTag.value == "select" && arrayAddress.value.length > 0) {
          description = event.target.options[event.target.options.selectedIndex].innerText
          state = event.target.options[event.target.options.selectedIndex].attributes.state ? event.target.options[event.target.options.selectedIndex].attributes.state.nodeValue : "";
          id_state = event.target.options[event.target.options.selectedIndex].attributes.id_state ? event.target.options[event.target.options.selectedIndex].attributes.id_state.nodeValue : "";
          id_town = event.target.options[event.target.options.selectedIndex].attributes.id_town ? event.target.options[event.target.options.selectedIndex].attributes.id_town.nodeValue : "";
          town = event.target.options[event.target.options.selectedIndex].attributes.town ? event.target.options[event.target.options.selectedIndex].attributes.town.nodeValue : "";
          city = event.target.options[event.target.options.selectedIndex].attributes.city.nodeValue ? event.target.options[event.target.options.selectedIndex].attributes.city.nodeValue : "";
          id_city = event.target.options[event.target.options.selectedIndex].attributes.id_city ? event.target.options[event.target.options.selectedIndex].attributes.id_city.nodeValue : "";
        }
        if (arrayAnswersDependents.value.length > 0) {
          arrayAnswersDependents.value.forEach((element, index) => {
            if (element.question_id == question_id) {
              arrayAnswersDependents.value.splice(index, 1);
            }
          });
        }

        answer.value = description;

        if (arrayAddress.value.length > 0 && props.questionDescription.includes("Colonia")) {
          arrayAnswersDependents.value.push({
            product: props.productName,
            category: props.categoryName,
            subcategory: props.subcategoryName,
            question_id: event.target.id,
            question: props.questionDescription,
            answer_id: answer_id,
            answer: description,
            state: state,
            id_state: id_state,
            id_town: id_town,
            id_city: id_city,
            city: city,
            town: town,
            validated: validated.value,
            questionType: typeTag.value
          });
        } else {
          arrayAnswersDependents.value.push({
            product: props.productName,
            category: props.categoryName,
            subcategory: props.subcategoryName,
            question_id: question_id,
            question: props.questionDescription,
            answer_id: answer_id,
            answer: description,
            validated: validated.value,
            questionType: typeTag.value
          });
        }
      }
      localStorage.setItem("arrayAnswersDependents", JSON.stringify(arrayAnswersDependents.value));
    }

    onMounted(async () => {
      currentSubcategoryName.value = props.subcategoryName;
      arrayIdQuestions.value.push({
        "id": props.questionId,
        "validations": props.validations.length > 0 ? props.validations : []
      });
      localStorage.setItem("arrayIDQuestions", JSON.stringify(arrayIdQuestions.value));
      questionId.value = props.questionId;
      questionIdGlobal.value = questionId.value;
      validateTypeQuestion();
      await validateOption();
      addAnswer();
    })

    function validateTypeQuestion() {
      switch (props.questionType) {
        case "LIBRE":
          typeTag.value = "text";
          break;
        case "OPCION UNICA":
          if (props.arrayOptions.length == 2) {
            typeTag.value = "radio";
            customClass.value = "radio";
          } else {
            typeTag.value = "select";
            customClass.value = "select"
          }
          break;
        case "OPCION MULTIPLE":
          typeTag.value = "checkbox";
          customClass.value = "checkbox;"
          break;
        case "RANGO DE FECHAS":
          typeTag.value = "date range";
          customClass.value = "date-range "
          break;
        case "FECHA":
          typeTag.value = "date";
          break;
        case "NUMERICA":
          typeTag.value = "number";
          break;
        case "BOOLEAN":
          typeTag.value = "radio";
          customClass.value = "radio";
          childs.value = props.childQuestions;
          break;
        default:
          typeTag.value = "text";
          break;
      }
    }

    function validateOption() {
      props.arrayOptions.forEach((option, index) => {
        if (props.arrayOptions[index].endpoint != null &&
          props.arrayOptions[index].endpoint != "") {
          let requestUrl = props.arrayOptions[index].endpoint;
          let template = props.arrayOptions[index].json_params_template;
          var data = "";
          let config = {
            headers: {
              'Content-Type': 'application/json'
            }
          }
          isLoading.value = true;
          if (props.arrayOptions[index].option.includes("Colonia")) {
            // let basic_auth = {
            //   auth: {
            //     username: "multicr",
            //     password: "*cabsa2018*"
            //   }
            // }
            template = JSON.parse(template);
            arrayAnswersDependents.value.forEach(answer => {
              if (answer.question.includes("Código Postal")) {
                template.provider = issuanceParams.value.provider.replace("_", "")
                template.body_json.address.postal_code = answer.answer;
              }
            });

            //data = template == "" ? template : template;
            axios.post(requestUrl, template, config).then((response) => {
              response.data.response.forEach(element => {
                arrayAddress.value.push({
                  id: element.id,
                  value: element.value,
                  state: element.state,
                  id_state: element.state_id,
                  id_town: element.town_id,
                  town: element.town,
                  id_city: element.city_id,
                  city: element.city
                });
              });
            }).catch((error) => {
              console.log("Se realizó un error al consultar las colonias: ", error.message);
            }).finally(() => {
              isLoading.value = false;
            })
          } else {
            template = buildParamsTemplate(props.arrayOptions[index].json_params_template);
            data = template == "" ? template : template;
            axios.get(requestUrl, {
              params: {
                json: data
              },
            }, config).then((response) => {
              response.data.response.forEach(element => {
                arrayParams.value.push(element);
              });
            }).catch((error) => {
              console.log("error: ", error.message);
            }).finally(() => {
              isLoading.value = false;
            })
          }

        } else {
          arrayParams.value.push({
            id: option.id,
            value: option.option
          });
        }
      });
    }

    function buildParamsTemplate(template) {
      template = JSON.stringify(template);
      if (template != null && template != "") {
        arrayAnswersDependents.value.forEach((answer) => {
          if (template.includes("question_" + answer.question_id)) {
            template = template.replace("question_" + answer.question_id, answer.answer_id);
          }
        });
      }
      return btoa(template);
    }

    function addAnswer() {
      if (arrayAnswersDependents.value.length > 0) {
        for (let item of arrayIdQuestions.value) {
          const found = arrayAnswersDependents.value.find(element => element.question_id == item.id);
          if (found) {
            switch (found.questionType) {
              case 'radio':
                if (document.getElementsByName(found.question_id)) {
                  var name = document.getElementsByName(found.question_id);
                  name.forEach(element => {
                    if (element.id == found.answer_id) {
                      element.checked = true;
                    }
                  });
                }
                break;
              case 'select':
                if (document.getElementById(found.question_id)) {
                  document.getElementById(found.question_id).value = found.answer_id;
                }
                break;
              default:
                if (document.getElementById(found.question_id)) {
                  document.getElementById(found.question_id).value = found.answer;
                }
                break;
            }
          }
        }
      }
    }

    function capitalize(event) {
      let description = event.target.value;
      description = description.charAt(0).toUpperCase() + description.slice(1);
      document.getElementById(event.target.id).value = description;
    }

    return {
      typeTag,
      step,
      contSteps,
      contCategory,
      answer,
      arrayParams,
      validateOption,
      customClass,
      answers,
      isLoading,
      emitUpdateInput,
      arrayAddress,
      child,
      capitalize,
      childs
    }
  }
}
</script>

<style>
.input {
  @apply mt-5 mb-5 shadow appearance-none border rounded py-3 px-4 block w-full transition ease-in-out duration-150;
}

.radio {
  @apply m-3 h-4 w-4 text-red-500 transition duration-150 ease-in-out;
}

.select {
  @apply mt-5 mb-5 shadow border rounded py-3 px-4 block w-full transition duration-150 ease-in-out;
}

.date-range {
  @apply mt-5 mb-5 shadow border rounded py-3 px-4 transition duration-150 ease-in-out w-5/12;
}
</style>
