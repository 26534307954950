<template>
  <div class="flex justify-end items-center space-x-6">
    <div class="hidden md:flex md:space-x-10">
      <a href="/#mision-vision" class="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out" >Misión-Visión</a>
      <a href="/#nosotros" class="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out" >Nosotros</a>
      <a href="/contactanos" class="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out" >Contacto</a>
      <a target="_blank" href="https://anterseguros.com/blog/preguntas-frecuentes/" class="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out" >FAQ</a>
    </div>
    <div class="hidden md:flex md:items-center md:justify-end md:inset-y-0">
      <span v-if="_loginDataUser.status==false"  class="inline-flex rounded-md shadow">
        <a href="/login" class="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-orange-500 bg-white hover:text-orange-400 focus:outline-none focus:border-orange-300 focus:shadow-outline-orange active:bg-gray-50 active:text-orange-700 transition duration-150 ease-in-out">Iniciar Sesión</a>
      </span>
      <span v-if="_loginDataUser.status==true && _loginDataUser.rol != 'Cliente'" class="inline-flex rounded-md shadow">
        <a href="panel-administrativo" class="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-orange-500 bg-white hover:text-orange-400 focus:outline-none focus:border-orange-300 focus:shadow-outline-orange active:bg-gray-50 active:text-orange-700 transition duration-150 ease-in-out">{{_loginDataUser.nombre}}</a>
        <a @click="logOut()" class="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-orange-500 bg-white hover:text-orange-400 focus:outline-none focus:border-orange-300 focus:shadow-outline-orange active:bg-gray-50 active:text-orange-700 transition duration-150 ease-in-out">Cerrar Sesión</a>
      </span>
      <span v-if="_loginDataUser.status==true && _loginDataUser.rol == 'Cliente'" class="inline-flex rounded-md shadow">
        <a href="/cliente/inicio" class="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-orange-500 bg-white hover:text-orange-400 focus:outline-none focus:border-orange-300 focus:shadow-outline-orange active:bg-gray-50 active:text-orange-700 transition duration-150 ease-in-out">{{_loginDataUser.nombre}}</a>
        <a @click="logOut()" class="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-orange-500 bg-white hover:text-orange-400 focus:outline-none focus:border-orange-300 focus:shadow-outline-orange active:bg-gray-50 active:text-orange-700 transition duration-150 ease-in-out">Cerrar Sesión</a>
      </span>
      <span class="inline-flex rounded-md shadow">
        
      </span>
    </div>
  </div>
</template>

<script>
import {getUserAuthInfo,deleteUserAuth} from "@/helpers/auth.js";
import { onMounted, ref } from '@vue/runtime-core'
export default {
  setup() {
    let _loginDataUser=ref({
      status:false,
      nombre:"",
      rol:""
    }
    );
    onMounted(()=>{
      let res=getUserAuthInfo()
      if(res){
        _loginDataUser.value.nombre=`${res.usuario_actual}`;
        _loginDataUser.value.status=true;
        _loginDataUser.value.rol=`${res.rol}`;
      }
      else{
        _loginDataUser.value.nombre="";
        _loginDataUser.value.status=false;
        _loginDataUser.value.rol="";
      }
    })
    function logOut(){
      _loginDataUser.value.nombre="";
      _loginDataUser.value.status=false;
      _loginDataUser.value.rol="";
      deleteUserAuth();
    }
    return{
      _loginDataUser,
      logOut
    }
  },
}
</script>