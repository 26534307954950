<template>
  <main class="lg:relative pt-12">
    <health-jumbotron />
    <providers/>
    <medical-expenses/>
  </main>
</template>
<script>
import Providers from '@/components/health/Providers.vue';
import HealthJumbotron from "@/components/health/HealthJumbotron.vue";
import MedicalExpenses from "@/components/health/MedicalExpenses.vue";

export default {
  components: {
    HealthJumbotron,
    MedicalExpenses,
    Providers
  },
  setup() {},
};
</script>
