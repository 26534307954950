<template>
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <meta name="description" content="">
    <meta name="author" content="">
    <link rel="icon" href="../../../../favicon.ico">
    <!-- Bootstrap core CSS -->
    <link href="../../css/editor.css" rel="stylesheet">
    <!-- Custom styles for this template -->
    <link href="narrow-jumbotron.css" rel="stylesheet">
  </head>
  <body>
    <!-- /container -->
    <div class="container">
      <div style="text-align: center;">
        <loading :active="isLoading" 
          :can-cancel="false" 
          :color="'#bfd243'" 
          :height="100" 
          :width="350" 
          :loader="loader"/>
        <table class="table dataTable no-footer divide-y rwd-table" style="width:90%">
          
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Fecha movimiento</th>
              <th>Modificado por</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(aseguradora, index) in aseguradoras" :key="index">
              <td>
                <img class="h-16" :src="require(`@/assets/images/aseguradoras/logo_${aseguradora.nombre}.svg`)">
              </td>
              <td> 
                <div v-if="aseguradora.status">{{ dateFormatForUserView(aseguradora.fecha_activo)}}</div>
                <div v-else>{{ dateFormatForUserView(aseguradora.fecha_inactivo)}}</div>
              </td>
              <!-- <td>
                <div v-if="aseguradora.fecha_activo">
                  {{ dateFormatForUserView(aseguradora.fecha_activo)}}
                </div>
              </td>
              <td>
                <div v-if="aseguradora.fecha_inactivo">
                  {{ dateFormatForUserView(aseguradora.fecha_inactivo)}}
                </div>
              </td> -->
              <td>
                {{aseguradora.nombres}} {{ aseguradora.apellido_paterno }}
              </td>
              <td>
                <button v-if="aseguradora.status" 
                  type="button" 
                  id="btn_agreed" 
                  v-on:click="changeStatus(index, aseguradora.status)"
                  class="w-full flex justify-center py-2 px-4 border border-transparent 
                  text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-400 
                  focus:outline-none focus:border-red-700 focus:shadow-outline-red a
                  ctive:bg-red-700 transition duration-150 ease-in-out">
                  Desactivar
                </button>
                <button v-else
                  type="button" 
                  id="btn_agreed" 
                  v-on:click="changeStatus(index, aseguradora.status)"
                  class="w-full flex justify-center py-2 px-4 border border-transparent 
                  text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-400 
                  focus:outline-none focus:border-green-700 focus:shadow-outline-green a
                  ctive:bg-green-700 transition duration-150 ease-in-out">
                  Activar
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </body>
</template>
<script>
  import axios from "axios"
  import {
    ref,
    onMounted,
  } from "vue";
  import Swal from 'sweetalert2';
  import Loading from 'vue-loading-overlay';
  import { getUserAuthInfo } from "@/helpers/auth.js";
  import { dateFormatForUserView } from '@/helpers/date.js';
  export default {
    components: {
      Loading
    },
    setup(){ 
    const loader = ref('spinner');
    const isLoading = ref(false);
    const anterapi = process.env.VUE_APP_ANTERAPI;
    let aseguradoras = ref([]);
    let config = ref({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getUserAuthInfo().token}`
      }
    });
    
    onMounted(async () => {
      isLoading.value = true;
      await getAllInsuranceCompanies();   
      isLoading.value = false;
    })
    
    async function getAllInsuranceCompanies() {
      await axios.get(anterapi + '/administrador/obtener_informacion_proveedores', config.value).then((response) => {
        aseguradoras.value = response.data.respuesta;
      }).catch(error => {
        console.log("Se presentó un error; ",error)
      });
    }
    
    async function changeStatus(index, status){
      let accion;
      status? accion = "Desactivar" : accion = "Activar"
      await Swal.fire({
        title: accion + " proveedor",
        text: "¿Desea "+accion.toLowerCase()+" el estatus de "+aseguradoras.value[index].nombre+"?",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cerrar",
      }).then(async (event) => {
        if (event.isConfirmed) {
          isLoading.value = true;
          let data = {
            id: aseguradoras.value[index].id
          };
          await axios.post(anterapi + '/administrador/actualizar_estado_proveedor', data, config.value).then((response) => {
            aseguradoras.value[index].status = response.data.respuesta.status;
            aseguradoras.value[index].fecha_activo = response.data.respuesta.fecha_activo;
            aseguradoras.value[index].fecha_inactivo =  response.data.respuesta.fecha_inactivo;
            aseguradoras.value[index].modificado_por = response.data.respuesta.modificado_por;
          }).catch(error => {
            console.log("Se presentó un error; ",error)
          });
        } 
        isLoading.value = false;
      });
    }
    return{
      aseguradoras,
      dateFormatForUserView,
      changeStatus,
      isLoading,
      loader
    };
},
};
</script>