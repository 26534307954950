import axios from "axios";
import { getUserAuthInfo } from "@/helpers/auth.js";
import {
  ref,
} from 'vue';
var anterapi = process.env.VUE_APP_ANTERAPI;
var config = ref({});
export default class Customer {

  constructor() {
    const data = getUserAuthInfo();
    config.value = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${data.token}`
      }
    };
  }

  async register(email, password, names, lastName, maternalLastName, gender, phone, rfc, birthDate, street, neighborhood, postalCode, outdoorNumber, personType, businessName){
      let data = {
        email: email,
        password: password,
        personales: {
          nombres: names,
          apellido_paterno: lastName,
          apellido_materno: maternalLastName,
          sexo: gender,
          telefono: phone,
          rfc: rfc,
          fecha_nacimiento: birthDate,
          calle: street,
          colonia: neighborhood,
          codigo_postal: postalCode,
          numero_exterior: outdoorNumber,
          tipo: personType,
          razon_social: businessName
        }
      };
      const response = await axios.post(anterapi + "/clientes/registrar", data, { headers: {'Content-Type': 'application/json'}});
      console.log(response.data.respuesta);
  }
  getProfile(){
    return axios.get(anterapi + "/clientes/consultar", config.value);
  }

  getPolicies(){
    return axios.get(anterapi + "/clientes/consultar_polizas_vigentes", config.value);
  }
  getQuotations(){
    return axios.get(anterapi + "/clientes/consultar_cotizaciones", config.value);
  }

  getPaymentsList(){
    return axios.get(anterapi + "/clientes/consultar_polizas_vigentes_pagos", config.value);
  }

  static setHeaderPolicyTable(){
    return [
      "No. Póliza",
      "Aseguradora",
      "Descripcion Auto",
      "Inicio Vigencia",
      "Fin Vigencia",
      "Póliza PDF"
    ]
  }

  static setOptionsPolicyTable(){
    return [
      "num_poliza",
      "aseguradora",
      "descripcion_vehiculo",
      "inicio_vigencia",
      "fin_vigencia",
      "PDF"
    ]
  }

  static setHeaderQuotationTable(){
    return [
      "Folio",
      "Cobertura",
      "Forma de pago",
      "Vehiculo",
      "Inicio vigencia",
      "Fin Vigencia"
    ]
  }

  getPayments(){
    return axios.get(anterapi + "/clientes/consultar_polizas_vigentes_pagos", config.value);
  }

  static setHeaderPaymentTable(){
    return [
      "Status",
      "No. Póliza",
      "Aseguradora",
      "Descripcion Auto",
      "Primer Importe",
      "Importe Subsecuente",
      "Importe Total",
      "Fecha Limite Pago",
      "Inicio Vigencia",
      "Fin Vigencia"
    ]
  }

  static setOptionsQuotationTable(){
    return [
      "folio",
      "cobertura",
      "forma_pago",
      "descripcion_vehiculo",
      "inicio_vigencia",
      "fin_vigencia"
    ]
  }

  static setOptionsPaymentTable(){
    return [
      "num_poliza",
      "aseguradora",
      "descripcion_vehiculo",
      "inicio_vigencia",
      "fin_vigencia",
      "PDF"
    ]
  }

  static setRecord(record){
    return this.addRecordRow(record);
  }

  static addRecordRow(record){
    var receiptRows = "<PRE> <br/>"+
    "    No. Póliza: "+record.num_poliza+"<br/>"+
    "    Status: "+record.status+"<br/>"+
    "    Aseguradora: "+record.aseguradora+"<br/>"+
    "    Primer importe: "+record.primer_importe+"<br/>"+
    "    Importe subsecuente: "+record.importe_subsecuente+"<br/>"+
    "    Importe total: "+record.importe_total+"<br/>"+
    "    Fecha Inicio Vigencia: "+record.inicio_vigencia+"<br/>"+
    "    Fecha Fin Vigencia: "+record.fin_vigencia+"</PRE><br/>";
    
    for(var i = 0;i<record.recibos.length; i++){
      receiptRows += "<PRE>Recibo_"+(i+1)+": <br/>"+
                    "    Fecha Inicio Pago: "+record.recibos[i].fecha_inicio+"<br/>"+
                    "    Fecha Límite Pago: "+record.recibos[i].fecha_limite+"<br/>"+
                    "    Status: "+record.recibos[i].status+"</PRE><br/>";
    }
    return receiptRows;
  }

  static setInfo(receipts, index){
    var recordInfo = document.getElementById("divReceipt_"+index);

    recordInfo.innerHTML = this.addRecordRow(receipts);
  }

  getPolicyPdf(id, policyNumber){
    return axios.get(anterapi + "/poliza_pdf/obtener_por_folio_y_numero_poliza?folio="+id+"&numero_poliza="+policyNumber, config.value);
  }
}