<template>
  <div class="min-h-screen bg-white flex">
    <login-container/>
  </div>
</template>
<script>
import LoginContainer from '@/components/login/LoginContainer';

export default {
  components: {
    LoginContainer
  }
};
</script>