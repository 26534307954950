<template>
  <div class="relative mt-12 sm:mt-16 lg:mt-24" data-aos="fade-left" data-aos-duration="1000" data-aos-anchor-placement="top-center" data-aos-anchor="#nosotros">
    <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
      <div class="lg:col-start-2">
        <div class="text-base max-w-prose mx-auto">
          <div class="prose text-gray-500 text-lg">
            <h2>Responsabilidad civil</h2>
            <p>Este seguro cubrirá los daños que podrías ocasionar a terceros en caso de
                protagonizar un accidente.
            </p>
            <h2> ¿Cómo puedo contratar mi póliza?</h2>
            <p>Con ANTER asegurar tu auto es fácil, rápido y seguro, no te pedimos más de lo
                que
                necesitas pasa contratar tu póliza.
            </p>
            <ol>
              <li>Ingresa los datos generales del vehículo</li>
              <li>Ingresa los datos generales del contratante</li>
              <li>Compara precios con las diferentes aseguradoras</li>
              <li>Elige la que cumpla con tus necesidades</li>
              <li>Paga tu póliza en línea</li>
            </ol>
            <h2> Beneficios</h2>
            <ul>
              <li>Mejores tarifas del mercado</li>
              <li>Protección inmediata</li>
              <li>Comparación de 7 aseguradoras en el mismo lugar</li>
              <li>Cotización instantánea</li>
              <li>Contrata tu seguro en menos de 5 minutos</li>
              <li>Múltiples métodos de pago</li>
            </ul>
          </div>
          <div class="flex justify-center w-full mt-12">
            <span class="w-full inline-flex rounded-md shadow-sm">
              <a  href="cotizacion" class="w-full flex items-center justify-center px-8 py-3 border
              border-transparent text-base leading-6 font-medium rounded-md text-white bg-orange-500
              hover:bg-orange-400 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange 
              transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10 ">
                Cotizar
              </a>
            </span>
          </div>
        </div>
      </div>

      <div class="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
        <img class="relative mx-auto" width="490" data-aos="fade-right" data-aos-duration="1500"
          data-aos-delay="100" data-aos-anchor-placement="top-center"
          data-aos-anchor="#nosotros"
          src="@/assets/images/ilustraciones/ilustraciones_poliza.svg"
          alt="">
      </div>
    </div>
  </div>
</template>