import moment from 'moment';

const parseDate = (date) => {
    if(typeof date == 'undefined' || date == ''){
        return moment(new Date()).format('MMMM D, YYYY');
    }
    return moment(date).format('MMMM D, YYYY');
}

export default {
    parseDate: parseDate
}