<template>
  <div style="min-height: 640px">
    <div class="h-screen flex overflow-hidden bg-white"
      x-data="{ sidebarOpen: false }"
      keydown.window.escape="sidebarOpen = false">
      <side-bar-menu />
      <div x-init="$el.focus()"
        class="flex-1 overflow-auto focus:outline-none"
        tabindex="0"
      >
        <side-bar-menu-mobile />
        <main class="flex-1 relative pb-8 z-0 overflow-y-auto bg-gray-50">
          <header-component />
          <div class="mt-8 max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="bg-white overflow-hidden shadow rounded-lg flex flex-col justify-betweeen col-span-3 p-4">
              <div class="vld-parent w-full">
                <loading :active="isLoading" :can-cancel="false" :color="'#bfd243'" :height="120" :width="400" :is-full-page="true">
                </loading>
              </div>
              <ReportTable ref="tableRef" :arrayFields="dataFields" :rows="rows" :columns="columns" :reportType="reportType"/>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderComponent from "@/components/customer/HeaderComponent.vue";
import SideBarMenu from '@/components/customer/SideBarMenu';
import SideBarMenuMobile from '@/components/customer/SideBarMenuMobile';
import { onMounted, ref, provide } from "@vue/runtime-core";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import ReportTable from "@/components/dashboard/reports/ReportTable.vue";
import Customer from '@/classes/customer.js';
import Swal from 'sweetalert2';
export default {
  components: {
    SideBarMenu,
    SideBarMenuMobile,
    HeaderComponent,
    Loading,
    ReportTable
  },
  setup() {
    const tableRef = ref();
    const policyId = ref();
    const policyNumber = ref();
    let dataFields = ref();
    let columns = ref();
    let rows = ref([]);
    let reportType = ref("Policy");
    const isLoading = ref(false);
    provide('isLoading', isLoading);
    onMounted(async () => {
      getReport();

      var paramsString = "q=URLUtils.searchParams&"+window.location.search
      const urlParams = new URLSearchParams(paramsString)
      if(urlParams.get('?policyId') != null){
        policyId.value = urlParams.get('?policyId');
        policyNumber.value = urlParams.get('policyNumber');
        downloadPdf(policyId.value,policyNumber.value);
      }
    });

    const getReport = async () => {
      isLoading.value = true;
      var customer = new Customer();
      columns.value = Customer.setHeaderPolicyTable();
      dataFields.value = Customer.setOptionsPolicyTable();
      let response = await customer.getPolicies();
      
      rows.value = response.data.respuesta.informacion;
      let arrayRows= [];
      rows.value.forEach(row =>{
        row.PDF = "<a href='/cliente/mis-polizas?policyId="+row.folio+"&policyNumber="+row.num_poliza+"'>DESCARGAR</button>";
        arrayRows.push(row);
      });
      rows.value = arrayRows;
      tableRef.value.createTablePolicy();
      isLoading.value = false;
    };

    function downloadPdf(id, policyNumber){
      var customer = new Customer();
      customer.getPolicyPdf(id, policyNumber).then((response) => {
        if(response.data.respuesta.error == false){
          var urls = [];
          urls.push(response.data.respuesta.url_poliza_pdf);
          if(response.data.respuesta.url_poliza_usa_pdf != ""){
            urls.push(response.data.respuesta.url_poliza_usa_pdf);
            Swal.fire({
              title: 'Selecciona el documento',
              showDenyButton: true,
              showCancelButton: true,
              confirmButtonText: 'Poliza PDF',
              denyButtonText: `Poliza USA`,
              icon: 'success',
            }).then((result) => {
              if (result.isConfirmed) {
                window.open(urls[0]);
              } else if (result.isDenied) {
                window.open(urls[1]);
              } else {
                window.open('','_parent','');
                window.close();
              }
            })
          }else{
            Swal.fire({
              icon: 'success',
              title: 'Selecciona el documento',
              showDenyButton: true,
              showCancelButton: true,
              confirmButtonText: 'Poliza PDF',
            }).then((result) => {
              if (result.isConfirmed) {
                window.open(urls[0]);
              }
            })
          }
        }else{
          Swal.fire(
            "Aviso",
            "No se pudo obtener el pdf de la póliza, apoyate con un asesor.",
            "info"
          );
        }
      });
    }

    return {
      isLoading,
      tableRef,
      reportType,
      dataFields,
      columns,
      rows
    }
  },
};
</script>