
import axios from "axios"
export default class Validation {
  value = null;
  validations = [];
  arrayMessages = [];
  index = null;
  element = null;

  constructor(value, validations, index, element, arrayAnswers) {
    this.value = value;
    this.validations = validations;
    this.index = index;
    this.element = element;
    this.arrayAnswers = arrayAnswers;
  }

  async applyValidation(){   
    let config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    let data = {};
    for (let i = 0; i < this.validations.length; i++) {
      var regular = new RegExp(this.validations[i].values);
      switch (this.validations[i].name) {
        case "No vacío":
          if( this.value == "" || this.value == null || this.value.length == 0 ) {
            this.arrayMessages.push("El campo debe tener un valor");
          }else{
            const ind = this.arrayMessages.findIndex((element) => element == "El campo debe tener un valor");
            if(ind){
              this.arrayMessages.slice(ind,1);
            }
          }        
          break;
        case "Campo Select":
          if( this.index == null || this.index == 0 ) {
            this.arrayMessages.push(`Selecciona un valor de la lista`);
          }else{
            const ind = this.arrayMessages.findIndex((element) => element.include("Selecciona un valor de la lista"));
            if(ind){
              this.arrayMessages.slice(ind,1);
            }
          }
          break;
        case "Campo Checkbox":
          if( !this.element.checked ) {
            this.arrayMessages.push(`Selecciona un valor`);
          }else{
            const index = this.arrayMessages.findIndex((element) => element.include("Selecciona un valor"));
            this.arrayMessages.slice(index,1);
          }
          break;
        case "Campo Radiobutton":
            var seleccionado = false;
            for(let i = 0; i< this.element.length; i++) {
              if(this.element[i].checked) {
                seleccionado = true;
                break;
              }
            }
            if(!seleccionado) {
              this.arrayMessages.push(`Selecciona una opción`);
            }else{
              const index = this.arrayMessages.findIndex((element) => element.include("Selecciona una opción"));
              this.arrayMessages.slice(index,1);
            }
            break;
        case "Longitud de rango":
          if(this.value.length < JSON.parse(this.validations[i].values).min){
            this.arrayMessages.push(`El campo debe tener mínimo ${JSON.parse(this.validations[i].values).min} caracteres`);
          }else{
            const index = this.arrayMessages.findIndex((element) => element.include("El campo debe tener mínimo"));
            this.arrayMessages.slice(index,1);
            if(this.value.length > JSON.parse(this.validations[i].values).max){
              this.arrayMessages.push(`El campo debe tener máximo ${JSON.parse(this.validations[i].values).max} caracteres`);
            }else{
              const index = this.arrayMessages.findIndex((element) => element.include("El campo debe tener máximo"));
              this.arrayMessages.slice(index,1);
            }
          }
          break;
        case "Solo letras":
          if(!regular.test(this.value)){ 
            this.arrayMessages.push("El campo debe tener sólo letras");
          }else{
            const index = this.arrayMessages.findIndex((element) => element.include("El campo debe tener sólo letras"));
            this.arrayMessages.slice(index,1);
          }
          break;
        case "Longitud":
          if(this.value.length != this.validations[i].values){
            this.arrayMessages.push(`El campo debe tener ${this.validations[i].values} caracteres`);
          }else{
            const index = this.arrayMessages.findIndex((element) => element.include(`El campo debe tener ${this.validations[i].values} caracteres`));
            this.arrayMessages.slice(index,1);
          }
          break;
        case "Solo número":
          //var reg = new RegExp(this.validations[i].values);
          if( !(regular.test(this.value)) ) {
            this.arrayMessages.push("El campo debe tener sólo números");
          }else{
            const index = this.arrayMessages.findIndex((element) => element.include("El campo debe tener sólo números"));
            this.arrayMessages.slice(index,1);
          }
          break;
        case "Email válido":
          //var regul = new RegExp(this.validations[i].values);
          if( !(regular.test(this.value)) ) {
            this.arrayMessages.push("Correo no válido");
          }else{
            const index = this.arrayMessages.findIndex((element) => element.include("Correo no válido"));
            this.arrayMessages.slice(index,1);
          }
          break;
        case "RFC válido":
          data = {};
          data = Validation.createRfcValidatorHash(this.value, this.arrayAnswers.value);
          
          await axios.post(this.validations[i].values, data, config).then((response) => {
            console.log("RFC response", response);
            const ind = this.arrayMessages.findIndex((element) => element.include("RFC no válido"));
            if(ind){
              this.arrayMessages.slice(ind,1);
            }
          }).catch((error) => {
            this.arrayMessages.push(error.response.data.errores);
            //console.log("Se presento un error al validar el RFC: ", error);
          });
          break;
        case "CURP válido":
          if( !(regular.test(this.value)) ) {
            this.arrayMessages.push("CURP no válido");
          }else{
            const index = this.arrayMessages.findIndex((element) => element.include("CURP no válido"));
            this.arrayMessages.slice(index,1);
          }
          break;
        case "Código postal válido":
            data = {};
            data =
            {
              proveedor: "Gnp",
              codigo_postal: this.value
            };
            await axios.post(this.validations[i].values, data, config).then((response) => {
              console.log("Código postal response", response);
              const ind = this.arrayMessages.findIndex((element) => element.include("Código postal no válido"));
              if(ind){
                this.arrayMessages.slice(ind,1);
              }
            }).catch((error) => {
              this.arrayMessages.push("Código postal no válido");
              console.log("Se presento un error al validar el Código postal: ", error);
            });
            break;
        case "Alfanumérico":
          if( !(regular.test(this.value)) ) {
            this.arrayMessages.push("El campo no debe tener caracteres especiales, sólo letras y números");
          }else{
            const index = this.arrayMessages.findIndex((element) => element.include("El campo no debe tener caracteres especiales, sólo letras y números"));
            this.arrayMessages.slice(index,1);
          }
        break;       
        case "Mayoría de edad":
          var birth_year = new Date(this.value+" 00:00:00").getFullYear();
          var validBirthYear = new Date().getFullYear()-18;
          if (validBirthYear < birth_year){
            this.arrayMessages.push("Debes tener 18 al menos para poder cotizar un auto, fecha de nacimiento proporcionada: ("+this.value+")");
          }
          if(validBirthYear == birth_year){
            var birth_month = new Date(this.value+" 00:00:00").getMonth()+1;
            var validBirthMonth= new Date().getMonth()+1;
            if(validBirthMonth < birth_month){
              this.arrayMessages.push("Debes tener 18 al menos para poder cotizar un auto, fecha de nacimiento proporcionada: ("+this.value+")");
            }
            if(validBirthMonth == birth_month){
              var birth_day = new Date(this.value+" 00:00:00").getDate();
              var validBirthDay = new Date();
              if(validBirthDay.getDate() < birth_day){
                this.arrayMessages.push("Debes tener 18 al menos para poder cotizar un auto, fecha de nacimiento proporcionada: ("+this.value+")");
              }else{
                const index = this.arrayMessages.findIndex((element) => element.include("Debes tener 18 al menos para poder cotizar un auto, fecha de nacimiento proporcionada: ("+this.value+")"));
                this.arrayMessages.slice(index,1);
              }
            }
          }
          break;
        case "Formato Rfc":
          if( !(regular.test(this.value)) ) {
            this.arrayMessages.push("El formato es 4 letras iniciales del nombre(AAAA), 6 digitos fecha nacimiento(YYMMDD) y 3 caracteres letra o número de la homoclave. (Consultar RFC: https://www.mi-rfc.com.mx/consulta-rfc-homoclave)");
          }else{
            const index = this.arrayMessages.findIndex((element) => element.include("El formato de rfc es 4 letras iniciales del nombre(AAAA), 6 digitos fecha nacimiento(YYMMDD) y 3 caracteres letra o número de la homoclave. (Consultar RFC: https://www.mi-rfc.com.mx/consulta-rfc-homoclave)"));
            this.arrayMessages.slice(index,1);
          }
        break;
      }
      if(this.arrayMessages.length > 0){
        break;
      }
    }
    return this.arrayMessages;
  }

  static validateAllQuestionsNotEmpty(arrayAnswers,arrayIdQuestions) {
    if (arrayAnswers.length > 0) {
      for (let item of arrayIdQuestions) {
        const answer = arrayAnswers.find(element => element.question_id == item.id);
        let showValidation = false;
        if (!answer) {
          if (item.validations.length > 0) {
            for (let validation of item.validations) {
              if (validation.name.includes("No vacío")) {
                showValidation = true;
                break;
              }
            } 
            if (showValidation) {
              return true;
            }
          }
        } else {
          if (!answer.validated) {
            return true;
          }
        }
      }
      return false;
    } else {
      return true;
    }
  }

  static createRfcValidatorHash(rfc, arrayAnswers){
    var name = "", paternal_name = "", maternal_name = "", birthdate = "";
    
    arrayAnswers.forEach(answer => {
      if (answer.question.includes("Nombre(s)")) {
        name = answer.answer;
      }
      if (answer.question.includes("Apellido Paterno")) {
        paternal_name = answer.answer;
      }
      if (answer.question.includes("Apellido Materno")) {
        maternal_name = answer.answer;
      }
      if (answer.question.includes("Cuándo naciste")) {
        birthdate = answer.answer;
      } 
    });

    return {
      rfc: rfc,
      fecha_nacimiento: birthdate,
      nombres: name,
      apellido_paterno: paternal_name,
      apellido_materno: maternal_name
    }
  }
}