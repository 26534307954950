<template>
  <div class="max-w-6xl px-4 sm:px-6 lg:px-1">
    <div class="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
      <div class="bg-white overflow-hidden shadow rounded-lg flex flex-col justify-betweeen col-span-3 p-4">
        <div :class="label.classes">
          <small>{{ label.text }}</small>
        </div>
        <!-- Data Table -->
        <table id="datatable" class="table w-full dataTable no-footer min-w-full divide-y divide-cool-gray-200 pt-8 rwd-table" style="width:90%">
          <thead>
            <tr>
              <th class="bg-cool-gray-50 text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider" v-for="column in columns[0]" :key="column.title"> {{ column.title }}</th>
            </tr>
          </thead>
          <tbody>
          </tbody>
        </table>
        <!-- End Data Table -->
      </div>
    </div>
  </div>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "@/assets/css/custom.css";

import $ from "jquery";
import { onMounted, ref } from "vue";

export default {
  props: { title: { type: String, default: function () { return '' } }, columns: { type: Array, default: function () { return [] } }, data: { type: Array, default: function () { return [] } }, search: { type: Boolean, default: function(){ return true}}, dataLength: {type: Boolean, default: function(){ return true } } , label: {type: Object, default: function(){ return {text:'', classes:''} }} },

  setup(props) {

    const dataTable = ref(null);
    
    const lang = {
          decimal: "",
          emptyTable: "No hay información",
          info: "Mostrando _START_ a _END_ de _TOTAL_ resultados",
          infoEmpty: "Mostrando 0 to 0 of 0 resultados",
          infoFiltered: "(Filtrado de _MAX_ total resultados)",
          infoPostFix: "",
          thousands: ",",
          lengthMenu: "Mostrar _MENU_ resultados",
          loadingRecords: "Cargando...",
          processing: "Procesando...",
          search: "Buscar:",
          zeroRecords: "Sin resultados encontrados",
          paginate: {
            first: "Primero",
            last: "Ultimo",
            next: "Siguiente",
            previous: "Anterior",
          },
    };

    async function createTable(){
      await props.data;
      showTable();
    }

    const showTable = () => {
      $.fn.dataTable.ext.errMode = 'none';

      var quantityColumns = 0;
      const columnAlignment = [];

      if(props.data.length>0){
        quantityColumns = props.data[0].length;
        for (let index = 0; index < quantityColumns; index++) {
          columnAlignment[index] = {
            "targets": index, 
            "className": (index>0)?"text-right":"text-left"
          }; 
        }
      }
      
      if ( !$.fn.DataTable.isDataTable('#datatable') ) {
        dataTable.value = $("#datatable").DataTable({
          data: props.data,
          scrollX: true,
          bFilter: props.search,
          bLengthChange : props.dataLength,
          language: lang,
          columnDefs: columnAlignment
        });
      }else{
        dataTable.value.destroy();
        dataTable.value = $("#datatable").DataTable({
          data: props.data,
          scrollX: true,
          bFilter: props.search,
          bLengthChange : props.dataLength,
          language: lang,
          columnDefs: columnAlignment
        });
      }
    }
 
    

    onMounted(() => {

    });
    
    return {
      createTable
    };
  },
}
</script>