<template>
  <svg class="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12" width="404" height="784" fill="none" viewBox="0 0 404 784" >
    <defs>
      <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse" >
        <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
      </pattern>
    </defs>
    <rect width="404" height="784" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
  </svg>
</template>
