<template>
  <div id="app" class="relative overflow-hidden" >
    <router-view />
  </div>
</template>

<script>
import AOS from 'aos'
import 'aos/dist/aos.css'
import { onMounted } from '@vue/runtime-core';
export default {
  name: "App",
  components: {
  },
  setup() {
    onMounted(() => {
      AOS.init()
    });
  }
};
</script>
