<template>
<div class="bg-white shadow overflow-hidden sm:rounded-lg max-w-xl mt-12">
  <div class="px-4 py-5 sm:px-6">
    <h3 class="text-lg leading-6 font-medium text-gray-900">
      Resumen
    </h3>
    <p class="mt-1 max-w-2xl text-sm text-gray-500">
      Detalles de tu emisión
    </p>
  </div>
  <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
    <dl class="sm:divide-y sm:divide-gray-200">
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500">
          Aseguradora Seleccionada </dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {{parametrosEmision.proveedor}}
        </dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500">
          Nombre </dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {{parametrosEmision.informacion.cliente.nombres + " " + parametrosEmision.informacion.cliente.apellido_paterno + " " + parametrosEmision.informacion.cliente.apellido_materno}}
        </dd>
      </div>
      <!-- <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500">
          Servicios emitidos
        </dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {{selected_products}}
        </dd>
      </div> -->
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500">
          RFC
        </dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {{parametrosEmision.informacion.cliente.rfc}}
        </dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500">
          Fecha de nacimiento
        </dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {{parametrosEmision.informacion.cliente.fecha_nacimiento}}
        </dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500">
          Sexo </dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {{parametrosEmision.informacion.cliente.sexo}}
        </dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500">
          Correo
        </dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {{parametrosEmision.informacion.cliente.email}}
        </dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500">
          Teléfono
        </dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {{parametrosEmision.informacion.cliente.telefono}}
        </dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500">
          Código Postal </dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {{parametrosEmision.informacion.cliente.codigo_postal}}
        </dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500">
          Colonia </dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {{parametrosEmision.informacion.cliente.colonia}}
        </dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500">
          Auto </dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {{descripcion_auto}}
        </dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500">
          Serie de vehiculo </dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {{parametrosEmision.informacion.auto.numero_serie}}
        </dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500">
          Placas </dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {{parametrosEmision.informacion.auto.placas}}
        </dd>
      </div>
    </dl>
  </div>
  <div class="flex justify-between  mt-16">
    <span class="inline-flex rounded-md shadow-sm">
      <button type="button" id="btn_back" @click="back()" class="w-full inline-flex items-center justify-center px-6 py-3 border border-orange-500 text-base leading-6 font-medium rounded-md text-orange-500 bg-white hover:bg-orange-500 hover:text-white focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition ease-in-out duration-150">
        Atrás
      </button>
    </span>
    <span class="inline-flex rounded-md shadow-sm">
      <button type="button" id="btn_issue" @click="getIssuanceResponse();" class="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-orange-500 hover:bg-orange-500 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition ease-in-out duration-150">
        Emitir
      </button>
    </span>
  </div>
</div>
</template>

<script>
import {
  inject,
  ref,
  onMounted
} from "vue";
import {
  useRouter
} from 'vue-router';
import Swal from "sweetalert2";
import axios from "axios";
import { getUserAuthInfo } from "@/helpers/auth.js";
import Customer from '@/classes/customer.js';
export default {
  name: "Resume",
  components: {},
  setup() {
    const anterapi = process.env.VUE_APP_ANTERAPI;
    let config = ref({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getUserAuthInfo().token}`
      }
    });  
    const router = useRouter();
    const isLoading = inject('isLoading');
    const arrayAnswers = inject('arrayAnswers');
    const issuanceResponseParams = inject('issuanceResponseParams')
    const parametrosEmision = inject('parametrosEmision');
    const selectedProviderQuote = inject('selectedProviderQuote');
    const descripcion_auto = ref();
    const parametrosPago = inject('parametrosPago');
    const parametrosSalesforce = inject('parametrosSalesforce');

    onMounted(async () => {
      arrayAnswers.value.forEach(answer => {
        if (answer.question.includes("Serie")) {
          parametrosEmision.value.informacion.auto.numero_serie = answer.answer;
          parametrosSalesforce.value.activo.numero_serie = answer.answer;
        }
        if (answer.question.includes("RFC")) {
          parametrosEmision.value.informacion.cliente.rfc = answer.answer;
          parametrosSalesforce.value.cliente.rfc = answer.answer;
        }
        if (answer.question.includes("Placas")) {
          parametrosEmision.value.informacion.auto.placas = answer.answer;
          parametrosSalesforce.value.activo.placas = answer.answer;
        }
        if (answer.question.includes("Colonia")) {
          parametrosEmision.value.informacion.cliente.colonia = answer.answer;
          parametrosSalesforce.value.cliente.colonia = answer.answer;
        }
        if (answer.question.includes("Calle")) {
          parametrosEmision.value.informacion.cliente.calle = answer.answer;
          parametrosSalesforce.value.cliente.calle = answer.answer;
        }
        if (answer.question.includes("Número Exterior")) {
          parametrosEmision.value.informacion.cliente.numero_exterior = answer.answer;
          parametrosSalesforce.value.cliente.numero_exterior = answer.answer;
        }
        if (answer.question.includes("Número Interior")) {
          parametrosEmision.value.informacion.cliente.numero_interior = answer.answer;
          parametrosSalesforce.value.cliente.numero_interior = answer.answer;
        }
      });
      
      descripcion_auto.value = parametrosEmision.value.informacion.auto.modelo + " " + parametrosEmision.value.informacion.auto.descripcion;
      parametrosEmision.value.proveedor = selectedProviderQuote.value.proveedor;
      parametrosSalesforce.value.informacion_poliza.aseguradora = selectedProviderQuote.value.proveedor;
      await saveQuestionAnswers(arrayAnswers);
    })
    async function getIssuanceResponse() {
      isLoading.value = true;
      
      var data = parametrosEmision.value;
      
      if (parametrosEmision.value.proveedor == "Hdi" || parametrosEmision.value.proveedor == "Axa" || parametrosEmision.value.proveedor == "Zurich" || parametrosEmision.value.proveedor == "Anaseguros" || parametrosEmision.value.proveedor == "Atlas") {
        await sendRequestToIssuanceRegister(parametrosEmision.value)
            
        parametrosPago.value.proveedor = parametrosEmision.value.proveedor;
        parametrosPago.value.informacion.auto = parametrosEmision.value.informacion.auto;
        parametrosPago.value.informacion.cliente = parametrosEmision.value.informacion.cliente;
        parametrosPago.value.informacion.cotizacion = parametrosEmision.value.informacion.cotizacion;
        parametrosPago.value.informacion.cotizacion.control_id = parametrosEmision.value.informacion.cotizacion.control_id;
        parametrosPago.value.informacion.cotizacion_id = parametrosEmision.value.informacion.cotizacion_id;
        parametrosPago.value.informacion.cotizacion_proveedor_id = parametrosEmision.value.informacion.cotizacion_proveedor_id;
        parametrosPago.value.informacion.emision_id = parametrosEmision.value.informacion.emision_id;
        parametrosPago.value.informacion.prospecto_id = parametrosEmision.value.informacion.prospecto_id;
        parametrosPago.value.informacion.usuario_id = parametrosEmision.value.informacion.usuario_id;
        parametrosPago.value.informacion.respuesta_id = parametrosEmision.value.informacion.respuesta_id;
        
        parametrosPago.value.informacion.emision.numero_poliza = "";
        parametrosPago.value.informacion.emision.numero_endoso = "";
        parametrosPago.value.informacion.emision.facturas = "";
        parametrosPago.value.informacion.emision.primer_importe = parametrosEmision.value.informacion.cotizacion.primer_importe;
        parametrosPago.value.informacion.emision.importe_subsecuente = parametrosEmision.value.informacion.cotizacion.importe_subsecuente;
        parametrosPago.value.informacion.emision.importe_total = parametrosEmision.value.informacion.cotizacion.importe_total;
        parametrosPago.value.informacion.emision.prima_neta = parametrosEmision.value.informacion.cotizacion.prima_neta;
        parametrosPago.value.informacion.emision.impuesto = parametrosEmision.value.informacion.cotizacion.impuesto;
        
        // parametrosSalesforce.value.informacion_poliza.numero_poliza = "";
        // parametrosSalesforce.value.informacion_poliza.importe_total = parametrosEmision.value.informacion.cotizacion.importe_total;
        // parametrosSalesforce.value.informacion_poliza.prima_neta = parametrosEmision.value.informacion.cotizacion.prima_neta;
        // parametrosSalesforce.value.informacion_poliza.iva = parametrosEmision.value.informacion.cotizacion.impuesto;
        // parametrosSalesforce.value.informacion_poliza.derecho = "550";
        // parametrosSalesforce.value.informacion_poliza.recargos = "300";
        // parametrosSalesforce.value.informacion_poliza.fecha_emision = getCurrentDate();
        // parametrosSalesforce.value.informacion_poliza.fecha_inicio = getCurrentDate();
        // parametrosSalesforce.value.informacion_poliza.fecha_termino = getFinishDate();
        // saveProspectSalesforce();

        router.push({
          name: "WayOfPayment"
        });
        isLoading.value = false;
      } else {
        return await axios.post(anterapi + '/autos/solicitar_emision', data, config).then((response) => {
            issuanceResponseParams.value = response.data.respuesta;
            
            parametrosPago.value.proveedor = parametrosEmision.value.proveedor;
            parametrosPago.value.informacion.auto = parametrosEmision.value.informacion.auto;
            parametrosPago.value.informacion.cliente = parametrosEmision.value.informacion.cliente;
            parametrosPago.value.informacion.cotizacion = parametrosEmision.value.informacion.cotizacion;
            parametrosPago.value.informacion.cotizacion_id = parametrosEmision.value.informacion.cotizacion_id;
            parametrosPago.value.informacion.cotizacion_proveedor_id = parametrosEmision.value.informacion.cotizacion_proveedor_id;
            parametrosPago.value.informacion.emision_id = issuanceResponseParams.value.emision_id;
            parametrosPago.value.informacion.prospecto_id = parametrosEmision.value.informacion.prospecto_id;
            parametrosPago.value.informacion.usuario_id = parametrosEmision.value.informacion.usuario_id;
            parametrosPago.value.informacion.respuesta_id = parametrosEmision.value.informacion.respuesta_id;
            
            parametrosPago.value.informacion.emision.numero_poliza = issuanceResponseParams.value.informacion_emision.numero_poliza;
            parametrosPago.value.informacion.emision.numero_endoso = issuanceResponseParams.value.informacion_emision.numero_endoso;
            parametrosPago.value.informacion.emision.facturas = issuanceResponseParams.value.informacion_emision.recibos;
            parametrosPago.value.informacion.emision.primer_importe = issuanceResponseParams.value.informacion_emision.primer_importe;
            parametrosPago.value.informacion.emision.importe_subsecuente = issuanceResponseParams.value.informacion_emision.importe_subsecuente;
            parametrosPago.value.informacion.emision.importe_total = issuanceResponseParams.value.informacion_emision.importe_total;
            parametrosPago.value.informacion.emision.prima_neta = issuanceResponseParams.value.informacion_emision.prima_neta;
            parametrosPago.value.informacion.emision.impuesto = issuanceResponseParams.value.informacion_emision.impuesto;

            parametrosSalesforce.value.informacion_poliza.numero_poliza = issuanceResponseParams.value.informacion_emision.numero_poliza;
            parametrosSalesforce.value.informacion_poliza.importe_total = issuanceResponseParams.value.informacion_emision.importe_total;
            parametrosSalesforce.value.informacion_poliza.prima_neta = issuanceResponseParams.value.informacion_emision.prima_neta;
            parametrosSalesforce.value.informacion_poliza.iva = issuanceResponseParams.value.informacion_emision.impuesto;
            parametrosSalesforce.value.informacion_poliza.derecho = issuanceResponseParams.value.informacion_emision.derecho;
            parametrosSalesforce.value.informacion_poliza.recargos = issuanceResponseParams.value.informacion_emision.recargos;
            parametrosSalesforce.value.informacion_poliza.fecha_emision = getCurrentDate();
            parametrosSalesforce.value.informacion_poliza.fecha_inicio = getCurrentDate();
            parametrosSalesforce.value.informacion_poliza.fecha_termino = getFinishDate();

            //saveProspectSalesforce();
            const customer = new Customer();
            
            customer.register(
              parametrosEmision.value.informacion.cliente.email,
              parametrosPago.value.informacion.emision.numero_poliza, 
              parametrosEmision.value.informacion.cliente.nombres, 
              parametrosEmision.value.informacion.cliente.apellido_paterno, 
              parametrosEmision.value.informacion.cliente.apellido_materno, 
              parametrosEmision.value.informacion.cliente.sexo, 
              parametrosEmision.value.informacion.cliente.telefono, 
              parametrosEmision.value.informacion.cliente.rfc, 
              parametrosEmision.value.informacion.cliente.fecha_nacimiento, 
              parametrosEmision.value.informacion.cliente.calle, 
              parametrosEmision.value.informacion.cliente.colonia, 
              parametrosEmision.value.informacion.cliente.codigo_postal, 
              parametrosEmision.value.informacion.cliente.numero_exterior, 
              "Fisica", 
              ""
            );

            router.push({
              name: "WayOfPayment"
            });
            // if (parametrosEmision.value.proveedor == "GNP" || parametrosEmision.value.proveedor == "Chubb") {
            //   getPaymentUrl();
            // } else {
            //   router.push({
            //     name: "WayOfPayment"
            //   });
            // }
          }).catch((error) => {
            console.log("Se presento un error: ", error);

            issuanceResponseParams.value = error.response.data.respuesta;
            
            Swal.fire({
              title: "Aviso",
              text: "Error: " + error.response.data.errores,
              icon: "error",
            });
          })
          .finally(() => {
            isLoading.value = false;
          });
      }
    }

    function getCurrentDate(){
      var current = new Date();
      return new Date(current).toISOString()
    }

    function getFinishDate(){
      var current = new Date();
      var current_one_year = new Date(current.setFullYear(current.getFullYear() + 1));
      return new Date(current_one_year).toISOString()
    }

    // async function saveProspectSalesforce(){
    //   let config = ref({
    //     headers: {
    //       'Content-Type': 'application/json'
    //     }
    //   }); 

    //   axios.post(anterapi + "/salesforce/registrar_cliente", parametrosSalesforce.value, config.value).then((response) => {
    //     console.log("respuesta de /salesforce/registrar_cliente", response.data);
    //   }).catch(error => {
    //     console.log("Se presentó un errror: ",error)
    //   });
    // }
    async function sendRequestToIssuanceRegister() {

      return await axios.post(anterapi + '/emision/registrar_solicitud', parametrosEmision.value, config.value).then((response) => {
        parametrosEmision.value.informacion.emision_id = response.data.respuesta.emision.id;
        console.log(response.data.respuesta);
      }).catch((error) => {
        console.log("Se presento un error: ", error);
          
        Swal.fire({
          title: "Aviso",
          text: "Se presentó un error al emitir. Favor de comunicarse con un asesor. " + error.message,
          icon: "error",
        });
      })
      .finally(() => {
      });
    }
    // async function getPaymentUrl() {
    //   isLoading.value = true;
    //   let requestUrl = anterapi + "/autos/generar_enlace_de_pago";
      
    //   let parameter = "";
    //   if (issuanceResponseParams.value.proveedor == "Gnp") {
    //     parameter = {
    //       "provider": issuanceResponseParams.value.proveedor,
    //       "quote_number": issuanceResponseParams.value.quote_number,
    //       "first_payment": issuanceResponseParams.value.total_amount
    //     };
    //   }
    //   if (issuanceResponseParams.value.proveedor == "Chubb") {
    //     parameter = {
    //       "provider": issuanceResponseParams.value.proveedor,
    //       "policy_number": issuanceResponseParams.value.policy_number,
    //       "inciso_number": "1",
    //       "policy_coin": "1",
    //       "payment_frequency": parametrosEmision.value.body_json.quote.payment_frequency,
    //       "client_names": parametrosEmision.value.body_json.client.names,
    //       "client_paternal_last_name": parametrosEmision.value.body_json.client.paternal_last_name,
    //       "client_maternal_last_name": parametrosEmision.value.body_json.client.maternal_last_name,
    //       "email": parametrosEmision.value.body_json.client.email
    //     }
    //   }
    //   await axios.get(requestUrl, {
    //       params: parameter
    //     }, config.value).then((response) => {

    //       window.location.href = response.data.response.url;
    //       issuanceResponseParams.value = response.data.response.url;
    //     }).catch((error) => {
    //       console.log("Se presento un error: ", error);
    //     })
    //     .finally(() => {
    //       isLoading.value = false;
    //     });
    // }

    async function saveQuestionAnswers(array){
      var data = {
        "respuesta": {
          "lista_detallada": array.value
        }
      }

      axios.post(anterapi + "/respuestas/registrar", data, config.value).then((response) => {
        parametrosEmision.value.informacion.respuesta_id = response.data.respuesta.id;
        console.log("respuesta de /respuestas/registrar", response.data);
      }).catch(error => {
        console.log("Se presentó un errror: ",error)
      });
    }

    function back() {
      router.push({
        name: "Questions"
      });
    }
    return {
      getIssuanceResponse,
      issuanceResponseParams,
      parametrosEmision,
      descripcion_auto,
      isLoading,
      arrayAnswers,
      back
    }
  }
}
</script>