<template>

    <create-account-form/>
    <create-account-image/>

</template>
<script>
import CreateAccountForm from '@/components/createAccount/CreateAccountForm';
import CreateAccountImage from '@/components/createAccount/CreateAccountImage';

export default {
  components:{
    CreateAccountForm,
    CreateAccountImage
  }
}
</script>