import Step from "@/classes/step.js"
let categories = [];
export default class Category {
  id = null;
  name = "";
  steps = [];

  constructor(id, name, steps) {
    this.id = id;
    this.name = name;
    this.steps = steps;
  }
  static buildFromJson(jsonArray) {
    categories = [];
    for (let i = 0; i < jsonArray.length; i++) {
      let steps = Step.buildFromJson(jsonArray[i].steps);
      //steps = steps.sort(function(a, b){return a - b});
      steps.sort(function (a, b) {
        if (a.step > b.step) {
          return 1;
        }
        if (a.step < b.step) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
      categories.push(new Category(jsonArray[i].category_id, jsonArray[i].category_name, steps));
    }
    return categories;
  }
  getSteps() {
    return this.steps;
  }

  static countCategory() {
    return categories.length;
  }
  static nameCategory() {
    return categories;
  }
  static getStepById(){
    //return this.Steps.find(step => step == id);
  }

}
