import { useRouter } from 'vue-router';
const router = useRouter();
function setUserAuth(auth) {
    sessionStorage.setItem("login", auth);
}

function isAuth(){
    if(!sessionStorage.getItem('login')){
        return false;
    }else if(typeof JSON.parse(sessionStorage.getItem('login')).token == 'undefined' || JSON.parse(sessionStorage.getItem('login')).token == ""){
        return false;
    }
    return true
}

function getUserAuth() {
    if(!isAuth()){
        return false
    }
    return JSON.parse(sessionStorage.getItem('login')).token;
}

function getUserAuthInfo() {
    if(!isAuth()){
        return false
    }
    return JSON.parse(sessionStorage.getItem('login'));
}

function deleteUserAuth() {
    sessionStorage.removeItem('login');
    router.push({
        path: "/"
    });
}

function getUser(){
    if(isAuth()){
       return JSON.parse(sessionStorage.getItem("login")).persona
    }
}

export { isAuth, getUserAuth, setUserAuth, deleteUserAuth, getUserAuthInfo,getUser };