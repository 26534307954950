<template>
    <div>   
      <div class="h-screen flex overflow-hidden bg-white" x-data="{ sidebarOpen: false }" keydown.window.escape="sidebarOpen = false">
        <side-bar-menu/>
        <div x-init="$el.focus()" class="flex-1 overflow-auto focus:outline-none" tabindex="0">
          <side-bar-menu-mobile/>
          <main class="flex-1 relative pb-8 z-0 overflow-y-auto bg-gray-50">
            <header-component/>
            <main-component />
          </main>
          <div class="centered">
            <div class="insurance-contact-card">
                <div class="data">
                    <img class="h-4 w-auto mx-auto" 
                    src="@/assets/images/logo_anter.png"
                    />
                    <div class="title"> 
                        <h1><span class="title">Tel&eacute;fonos</span> para</h1>
                         <h1>reportar siniestros:</h1>                      
                    </div>
                    <div class="content">
                        <div>
                            <table>
                                <tbody >
                                    <tr>
                                        <th><span class="responsive-font-size">Qualitas:</span></th>
                                        <td class="responsive-font-size">
                                            <a href=“tel:800-800-2880”>800 800 2880</a>
                                            <br>
                                            <a href=“tel:800-288-6700”>800 288 6700</a>                    
                                        </td>
                                    </tr>
                                    <tr>
                                        <th><span class="responsive-font-size">Chubb:</span></th>
                                        <td class="responsive-font-size">811 253 3030<br>551 253 3030 CDMX</td>
                                    </tr>
                                    <tr>
                                        <th><span class="responsive-font-size">Primero Seguros:</span></th>
                                        <td class="responsive-font-size">800 017 6288</td>
                                    </tr>
                                    <tr>
                                        <th><span class="responsive-font-size">HDI:</span></th>
                                        <td class="responsive-font-size">800 019 6000</td>
                                    </tr>
                                    <tr>
                                        <th><span class="responsive-font-size">GNP:</span></th>
                                        <td class="responsive-font-size">555 227 9000</td>
                                    </tr>
                                    <tr>
                                        <th><span class="responsive-font-size">Zurich:</span></th>
                                        <td class="responsive-font-size">800 288 6911</td>
                                    </tr>
                                    <tr>
                                        <th><span class="responsive-font-size">AXA:</span></th>
                                        <td class="responsive-font-size">800 911 1292</td>
                                    </tr>
                                    <tr>
                                        <th><span class="responsive-font-size">Ana Seguros:</span></th>
                                        <td class="responsive-font-size">800 835 3262</td>
                                    </tr>
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>  
            </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import MainComponent from "@/components/customer/MainComponent.vue";
  import HeaderComponent from "@/components/customer/HeaderComponent.vue";
  import SideBarMenu from '@/components/customer/SideBarMenu';
  import SideBarMenuMobile from '@/components/customer/SideBarMenuMobile';
  export default {
    components: {
      HeaderComponent,
      MainComponent,
      SideBarMenu,
      SideBarMenuMobile
      
    },
    setup() {
    },
  };
  </script>
  <style scoped>
  .centered{
    display: flex;
    justify-content: center;
    position: relative;
  }
  .title{
    font-size: clamp(27px, 4vw, 32px);
    font-weight: bold;
    text-align: center;
  }
  .insurance-contact-card{
    width: 800px;
    min-width: 300px;
    max-width: 1000px;
    max-height: auto;
    background: rgb(0,58,116);
    background: linear-gradient(45deg, rgba(0,58,116,1) 20%, rgba(1,81,156,1) 100%);
    padding-top: 2.7rem;
    color:white;
    text-align: center;
    display: flex;
    justify-content: center;
    
  }
  .content{
    width: 800px;
    justify-content: center;
    display: flex;
    padding-top: 1rem;
    padding-bottom: 3rem;
    padding-right: 3.5rem;
    padding-left: 5%;
  }
  td{
    text-align: left;
    padding: .3rem;
  }
  th{
    padding-right: 5%;
    
  }
  .responsive-font-size{
    font-size: clamp(20px, 4vw, 25px);
  }
  img {
  width: 450px;
  height: auto;
}
  </style>