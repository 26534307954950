<template>

  <div style="min-height: 640px">
    <div class="h-screen flex overflow-hidden bg-white"
      x-data="{ sidebarOpen: false }"
      keydown.window.escape="sidebarOpen = false">
      <side-bar-menu />
      <div x-init="$el.focus()"
        class="flex-1 overflow-auto focus:outline-none"
        tabindex="0"
      >
        <side-bar-menu-mobile />
        <main class="flex-1 relative pb-8 z-0 overflow-y-auto bg-gray-50">
          <header-component />
          <header><h1 class="text-2xl font-bold" style="color: #e55203">P</h1><h1 class="text-2xl font-bold" style="color: #253947">olizas-Pagos</h1></header>
          <div class="receipts">
            <Receipt 
              v-for="(receipt, i) in receipts" 
              :policy="receipt" 
              :index="i" 
              :key="i"
              :open="receipt.open"
              @toggleOpen="toggleOpen"
            />
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderComponent from "@/components/customer/HeaderComponent.vue";
import SideBarMenu from '@/components/customer/SideBarMenu';
import SideBarMenuMobile from '@/components/customer/SideBarMenuMobile';
import Receipt from "@/components/customer/Receipt.vue";
import Customer from '@/classes/customer.js';
import { onMounted, ref, provide } from "@vue/runtime-core";
export default {
  name: 'Payment',
  components: {
    Receipt,
    HeaderComponent,
    SideBarMenu,
    SideBarMenuMobile
  },
  setup() {
    let records = ref([]);
    let receipts = ref([]);
    const isLoading = ref(false);
    provide('isLoading', isLoading);
    onMounted(async () => {
      getInfo();
    });

    const getInfo = async () => {
      isLoading.value = true;
      var customer = new Customer();
      let response = await customer.getPaymentsList();
      
      records.value = response.data.respuesta.informacion;
      let arrayRows= [];
      records.value.forEach(record => {
        arrayRows.push({
          policy_number: record.num_poliza,
          name: record.nomenclatura,
          receipt: record,
          open: false
        });
      })
      
      receipts.value = arrayRows;
      isLoading.value = false;
    };
    return {
      receipts
    }
  },
  methods: {
    toggleOpen: function (index) {
      this.receipts = this.receipts.map((receipt, i) => {
        if (index === i) {
          receipt.open = !receipt.open;
        } else {
          receipt.open = false;
        }
        return receipt;
      });
    }
  }
}
</script>

<style>
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
body {
	font-family: Montserrat;
}
header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}
header h1 {
  color: #ffffff;
  font-size: 28px;
  font-weight: 300;
  text-transform: uppercase;
}
header h1 span {
  color: #253947;
  font-weight: 900;
}
.policy {
  display: block;
  width: 100%;
  max-width: 768px;
  margin: 15px auto;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  background-color: #FFF;
}
.policy .name {
  position: relative;
  color: #3c3c3c;
  font-size: 20px;
  transition: all 0.4s linear;
}
.policy .name::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%) rotate(0deg);
  width: 30px;
  height: 30px;
  
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  
  transition: all 0.2s linear;
}
.policy.open .name {
  margin-bottom: 15px;
}
.policy.open .name::after {
  transform: translateY(-50%) rotate(90deg);
}
.policy .receipt {
  color: #3c3c3c;
  font-size: 18px;
  opacity: 0;
  max-height: 0px;
  overflow-y: hidden;
  transition: all 0.4s ease-out;
}
.policy.open .receipt {
  opacity: 1;
  max-height: 1000px;
}
</style>