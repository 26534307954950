<template>
  <div
    class="bg-white shadow-lg border p-4 rounded-lg py-3 px-1 max-w-3xl m-auto mb-5 truncate"
  >
    <div class="grid grid-cols-1 sm:grid-cols-5 pl-5 sm:pl-0">
      <div class="sm:col-span-4" @click="OpenModalMovil()">
        <p class="w-full truncate">Categoria</p>

        <div class="mt-2 truncate">
          <strong>{{ questionFormEdit.category }} </strong>
        </div>

        <div class="mt-2 truncate">
          <p class="truncate">Subcategoria</p>
          <strong>{{ questionFormEdit.subcategory }}</strong>
        </div>

        <div class="mt-2 truncate">
          <p class="truncate">Pregunta</p>
          <strong>{{ questionFormEdit.question }}</strong>
        </div>

        <div class="mt-2 truncate">
          <p class="truncate">Tipo</p>
          <strong>{{ questionFormEdit.type }}</strong>
        </div>

        <div class="mt-2 truncate">
          <p class="truncate">Opciones</p>
          <strong>{{ questionFormEdit.options }}</strong>
        </div>

        <div class="mt-2 truncate">
          <p class="truncate">Validaciones</p>
          <strong>{{ questionFormEdit.validations }}</strong>
        </div>
      </div>

      <div>
        <div class="mr-10 sm:text-right">
          <!-- <div>
            <div class="hidden sm:block">
              <button
                v-on:click="OpenModal()"
                class="bg-orange-700 px-3 py-2 rounded-lg hover:bg-orange-500 focus:outline-none text-white mb-6"
                id="btn-edit"
              >
                Editar
              </button>
            </div>
          </div> -->

          <div>
            <div class="flex flex-col-reverse sm:block sm:col">
              <div
                class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
              >
                <input
                  for="btn-is-active"
                  v-if="questionFormEdit.status == true"
                  v-on:click="changeStateUser"
                  type="checkbox"
                  name="toggle"
                  id="btn-is-active"
                  checked
                  class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                />
                <input
                  for="btn-is-active"
                  v-if="questionFormEdit.status == false"
                  v-on:click="changeStateUser"
                  type="checkbox"
                  name="toggle"
                  id="btn-is-active"
                  class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                />
                <label
                  class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                ></label>
                <label
                  v-if="questionFormEdit.status == false || questionFormEdit.status == ''"
                  class="text-xs text-gray-700"
                  >Inactivo</label
                >
                <label v-if="questionFormEdit.status == true" class="text-xs text-gray-700"
                  >Activo</label
                >
              </div>
              <div class="mt-2 sm:mt-6 w-full h-full">
                <p class="w-full">Vista</p>
                <strong>{{ questionFormEdit.screen }}</strong>
              </div>
              <div class="mt-2 sm:mt-6 w-full h-full">
                <p class="w-full">Orden</p>
                <strong>{{ questionFormEdit.order }}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { onMounted, ref, inject } from "vue";
import "@/assets/css/custom.css";

export default {
  props: {
    questionInfo: Array,
  },
  setup(props) {
    const isLoading = inject('isLoading');
    let screen = ref(false);
    let ShowModalEditQuestion = ref(false);
    let questionFormEdit = ref({
      category: props.questionInfo.categoria,
      subcategory: props.questionInfo.subcategoria,
      question: props.questionInfo.pregunta,
      type: props.questionInfo.tipo,
      options: props.questionInfo.opciones,
      validations: props.questionInfo.validaciones,
      order: props.questionInfo.orden,
      screen: props.questionInfo.vista,
      status: props.questionInfo.status
    });

    onMounted(async () => {
      // assigDataUserForModel();
      screen.value = window.screen.width <= 639 ? true : false;
      $(window).resize(function () {
        screen.value = window.screen.width <= 639 ? true : false;
      });
    });

    function OpenModalMovil() {
      if (screen.value) {
        OpenModal();
      }
    }
    function OpenModal() {
      
      ShowModalEditQuestion.value = true;
    }
    function CloseModal() {
      ShowModalEditQuestion.value = false;
    }
    return {
      
      isLoading,
      questionFormEdit,
      OpenModal,
      CloseModal,
      OpenModalMovil,
      ShowModalEditQuestion
    };
  },
};
</script>
<style>
.toggle-checkbox:checked {
  @apply: right-0 border-green-400;
  right: 0;
  border-color: #68d391;
}
.toggle-checkbox:checked + .toggle-label {
  @apply: bg-green-400;
  background-color: #68d391;
}
/* :focus {
  outline: none;
} */
.modal-active {
  align-items: center;
  position: relative;
  z-index: 1000;
}
.modal {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  align-items: center;
  pointer-events: none;
  z-index: 1000;
}
.show-modal-active {
  display: flex;
  opacity: 1;
  align-items: center;
  pointer-events: auto;
  z-index: 1000;
}
@media (max-width: 900px) {
  .modal-active {
    margin-top: 75vh;
    margin-bottom: 100px;
  }
  .modal {
    overflow-y: scroll;
  }
}
</style>