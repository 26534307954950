// import Swal from "sweetalert2";
import axios from "axios";
var anterapi = process.env.VUE_APP_ANTERAPI;
const role = (sessionStorage.getItem('login'))? JSON.parse(sessionStorage.getItem('login')).rol: '';
export default class Policy {
  
  constructor(folio, policyNumber) {
    this.folio = folio;
    this.policyNumber = policyNumber;
  }

  get(){
    let url = "";
    let params = "";
    if(role == 'Administrador'|| role == 'Super_Administrador'){
      params = {
        folio: this.folio,
        numero_poliza: this.policyNumber,
      };
      url = anterapi + "/poliza_pdf/obtener_por_folio_y_numero_poliza";
    }
    let options = this.setOptions(params);
    return axios.get(url, options);
  }

  setOptions(params){
    return {
      headers: {
        "Content-Type": "Application/Json",
        Authorization: sessionStorage.getItem("login")
          ? JSON.parse(sessionStorage.getItem("login")).token
          : "",
      },
      params: params,
    };
  }

  openLink(url){
    window.open(url);
  }
}
