import axios from "axios";
import { getUserAuthInfo } from "@/helpers/auth.js";
var anterapi = process.env.VUE_APP_ANTERAPI;
export default class QuestionConfig {

  constructor() {
  }

  get(){
    let options = this.setOptions(null);
    return axios.get(anterapi + "/admin/consultar_formulario_preguntas", options);
  }

  getValidations(){
    let options = this.setOptions(null);
    return axios.get(anterapi + "/admin/consultar_validaciones", options);
  }

  setOptions(params){
    return {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem("login")
          ? `Bearer ${getUserAuthInfo().token}`
          : "",
      },
      params: params,
    };
  }

  setTableHeaders(){
    return [
      "Categoria", "SubCategoria", 
      "Pregunta", "Tipo", "Estatus", 
      "Vista", "Orden", 
      "Opciones", "Validaciones"
    ]
  }

  setTableOptions(){
    return [
      "categoria", "subcategoria",
      "pregunta", "tipo", "status",
      "orden", "vista",
      "opciones", "validaciones"
    ]
  }

}