export default class Option {
  id = null;
  option = "";
  status = "";
  provider = "";
  catalog = "";
  endpoint = "";
  json_params_template = "";

  constructor(id, option, status, provider, catalog, endpoint, json_params_template) {
    this.id = id;
    this.option = option;
    this.status = status;
    this.provider = provider;
    this.catalog = catalog;
    this.endpoint = endpoint;
    this.json_params_template = json_params_template;
  }

  static buildFromJson(jsonArray) {
    let options = [];
    if (jsonArray.length > 0) {
      for (let i = 0; i < jsonArray.length; i++) {
        options.push(new Option(jsonArray[i].id, jsonArray[i].option, jsonArray[i].status,
          jsonArray[i].provider, jsonArray[i].catalog, jsonArray[i].endpoint, jsonArray[i].json_params_template));
      }
    }
    return options;
  }

  buildParamsTemplate() {
    console.log("params");
  }


}