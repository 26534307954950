<template>
    <div style="min-height: 640px">
      <div class="h-screen flex overflow-hidden bg-white"
        x-data="{ sidebarOpen: false }"
        keydown.window.escape="sidebarOpen = false">
        <dashboard-side-bar-menu />
        <div x-init="$el.focus()"
          class="flex-1 overflow-auto focus:outline-none"
          tabindex="0"
        >
          <dashboard-side-bar-menu-mobile />
          <main class="flex-1 relative pb-8 z-0 overflow-y-auto">
            <dashboard-header />
            <div class="mt-8 max-w-6xl mx-auto px-4 sm:px-6 lg:px-8" style="min-width: 100%;">
              <div class="bg-white overflow-hidden shadow rounded-lg flex flex-col justify-betweeen col-span-3 p-4">
                <br>
                <InsuranceCompaniesTable />
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  </template>
  <script>
  import DashboardSideBarMenu from "@/components/dashboard/DashboardSideBarMenu";
  import DashboardSideBarMenuMobile from "@/components/dashboard/DashboardSideBarMenuMobile";
  import DashboardHeader from "@/components/dashboard/DashboardHeader";
  import InsuranceCompaniesTable from "@/components/dashboard/insurance/InsuranceCompaniesTable";
  import { onMounted } from "@vue/runtime-core";
  
  export default {
    components: {
      DashboardSideBarMenu,
      DashboardSideBarMenuMobile,
      DashboardHeader,
      InsuranceCompaniesTable
    },
    setup() {
      onMounted(() => {});
    },
  };
  </script>