// import Swal from "sweetalert2";
import axios from "axios";
var anterapi = process.env.VUE_APP_ANTERAPI;

export default class Summary {
  
  constructor() {
  }
  static async getByRangeOfDates(initialDate, endDate, token, userId, role){
    let response="";
    if(role == "Administrador" || role == "Super_Administrador"){
      response = axios.get(`${anterapi}/resumen/obtener_total_cotizaciones_y_ventas_por_rango_de_fecha?token_auth=${token}&fecha_inicial=${initialDate}&fecha_final=${endDate}`).then((result) => {
        return result.data
      });
    }else{
      response = axios.get(`${anterapi}/resumen/obtener_total_cotizaciones_y_ventas_de_usuario_por_rango_de_fecha?token_auth=${token}&usuario_id=${userId}&fecha_inicial=${initialDate}&fecha_final=${endDate}`).then((result) => {
        return result.data
      });
    }
    
    return await response;
  }

  static async getQuoteByRangeOfDateSummary(dateStart, dateEnd, token, userId){
    const response =  axios.get(`${anterapi}/resumen/obtener_total_cotizaciones_y_ventas_de_usuario_por_rango_de_fecha?usuario_id=${userId}&token_auth=${token}&fecha_inicial=${dateStart}&fecha_final=${dateEnd}`).then((result) => {
      return result.data
    });
  
    return await response;
  }
}