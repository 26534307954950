export default class DateAndFormatter {
    constructor() { }

    getDayOrMonthOrYear(start, finish, date) {
        let data = "";
        for (let index = start; index <= finish; index++) {
            data = data + date[index];
        }
        return data;
    }

    formattedDay(value, date) {
        if (value < 10) {
            value = "0" + value;
        }
        return this.assigFormattedDate(0, 1, value, date);
    }

    formattedMonth(value, date) {
        if (value < 10) {
            value = "0" + value;
        }
        return this.assigFormattedDate(3, 4, value, date);
    }

    formattedYear(value, date) {
        return this.assigFormattedDate(6, 9, value, date);
    }

    assigFormattedDate(start, finish, value, date) {
        let i = 0;
        for (let index = start; index <= finish; index++) {
            date = this.replaceAtIndex(
                date,
                index,
                value[i]
            );
            i++;
        }
        return date;
    }

   replaceAtIndex(_string, _index, _newValue) {
        if (_index >_string.length - 1) {
            return _string;
        } else {
            return (
                _string.substring(0, _index) +
                _newValue +
                _string.substring(_index + 1)
            );
        }
    }

    dateFormat(texto) {
        return texto.replace(/^(\d{2})-(\d{2})-(\d{4})$/g, "$3-$2-$1");
    }

    isValidDate(str) {
        var parts = str.split('-');
        if (parts.length < 3)
            return false;
        else {
            var day = parseInt(parts[0]);
            var month = parseInt(parts[1]);
            var year = parseInt(parts[2]);
            if (isNaN(day) || isNaN(month) || isNaN(year)) {
                return false;
            }
            if (day < 1 || year < 1)
                return false;
            if(month>12||month<1)
                return false;
            if ((month == 1 || month == 3 || month == 5 || month == 7 || month == 8 || month == 10 || month == 12) && day <= 31)
                return false;
            if ((month == 4 || month == 6 || month == 9 || month == 11 ) && day <= 30)
                return false;
            if (month == 2) {
                if (((year % 4) == 0 && (year % 100) != 0) || ((year % 400) == 0 && (year % 100) == 0)) {
                    if (day<= 29)
                        return false;
                } else {
                    if (day <= 28)
                        return false;
                }      
            }
            return true;
        }
    }

    getdate() {
        let newmonth;
        let day = new Date().getDate();
        let month = new Date().getMonth() + 1;
        let year = new Date().getFullYear();
        if (month < 10) {
          newmonth = '0' + month.toString();
          month = newmonth;
        }
        return `${year}-${month}-${day}`;
      }

}