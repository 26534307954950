import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "@/assets/css/tailwind.css";
import axios from "axios";
import VueAxios from "vue-axios";
import jQuery from "jquery";
import VueCompositionApi from "@vue/composition-api";
import VueTheMask from 'vue-the-mask';
import Swal from 'sweetalert2';
import moment from 'moment'
import VueResource from 'vue-resource';

import Maska from 'maska';
import DisableAutocomplete from 'vue-disable-autocomplete';

import filters from "@/helpers/filters.js"
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faUserSecret)
const app = createApp(
  App
).component('font-awesome-icon', FontAwesomeIcon);
app.use(router, axios, VueAxios, jQuery, VueCompositionApi, VueTheMask, Swal, moment, VueResource, Maska, DisableAutocomplete)
app.mount("#app");
app.config.globalProperties.$axios=axios;
moment.locale("es");
app.config.globalProperties.$moment=moment;

app.config.globalProperties.$filters = {
  parseDate(date) { 
    return filters.parseDate(date)
  }
}