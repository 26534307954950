<template>
<main class="lg:relative">
    <div class="relative py-16 bg-white overflow-hidden">
      <pattern-bg/>
      <general/>
    </div>
</main>
</template>
<script>
import General from '@/components/terms/General.vue';
import PatternBg from '@/components/common/PatternBg.vue';

export default {
  components:{
    General,
    PatternBg
  },
  setup() {
    
  },
}
</script>
