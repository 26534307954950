<template>
  <div class="z-50 absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden" v-show="openNavBar">
    <div class="rounded-lg shadow-md">
      <div class="rounded-lg bg-white shadow-xs overflow-hidden" role="menu" aria-orientation="vertical" aria-labelledby="main-menu">
        <div class="px-5 pt-4 flex items-center justify-between">
          <div>
            <img class="h-4 w-auto" src="@/assets/images/logo.png"/>
          </div>
          <div class="-mr-2">
            <button type="button" @click="closeMenu" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out" aria-label="Close menu">
              <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
              </svg>
            </button>
          </div>
        </div>
        <div class="px-2 pt-2 pb-3">
          <a href="/#nuestros-productos" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem">Servicios</a>
          <a href="./#nosotros" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem" >Nosotros</a>
          <a href="/contactanos" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem">Contacto</a>
        </div>
        <!-- <div>
          <a href="/login" class="block w-full px-5 py-3 text-center font-medium text-orange-500 bg-gray-50 hover:bg-gray-100 hover:text-orange-700 focus:outline-none focus:bg-gray-100 focus:text-orange-700 transition duration-150 ease-in-out" role="menuitem">
            Asesores
          </a>
        </div> -->
        <div v-if="_loginDataUser.status==false">
         <a href="/login" class="block w-full px-5 py-3 text-center font-medium text-orange-500 bg-gray-50 hover:bg-gray-100 hover:text-orange-700 focus:outline-none focus:bg-gray-100 focus:text-orange-700 transition duration-150 ease-in-out" role="menuitem">Asesores</a>
        </div>
        <div v-if="_loginDataUser.status==true">
          <a @click="logOut()" class="block w-full px-5 py-3 text-center font-medium text-orange-500 bg-gray-50 hover:bg-gray-100 hover:text-orange-700 focus:outline-none focus:bg-gray-100 focus:text-orange-700 transition duration-150 ease-in-out" role="menuitem">{{_loginDataUser.data}}</a>
        </div>
        <div>
          <a href="http://anter.com.mx:8080/usuarios/sign_in/" class="block w-full px-5 py-3 text-center font-medium text-orange-500 bg-gray-50 hover:bg-gray-100 hover:text-orange-700 focus:outline-none focus:bg-gray-100 focus:text-orange-700 transition duration-150 ease-in-out" role="menuitem">
            Administración
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {getUser,deleteUserAuth} from "@/helpers/auth.js";
import { inject, onMounted, ref } from '@vue/runtime-core';
export default {
  
  setup() {
    const openNavBar = inject('openNavBar');
    function closeMenu(){
      openNavBar.value = false;
    }
    let _loginDataUser=ref({
      status:false,
      data:""
    }
    );
    onMounted(()=>{
      let res=getUser()
      if(!(typeof res=="undefined")){
        _loginDataUser.value.data=`${res.name} ${res.paternal_name}`;
        _loginDataUser.value.status=true;
      }
      else{
        _loginDataUser.value.data="";
        _loginDataUser.value.status=false;
      }
    })
    function logOut(){
      _loginDataUser.value.data="";
      _loginDataUser.value.status=false;
      deleteUserAuth();
    }
    return {
      closeMenu,
      openNavBar,
      _loginDataUser,
      logOut
    }
  },
};
</script>