<template>
  <div>   
    <pattern-bg/>
    <div x-data="{ open: false }" class="relative pt-6 pb-12 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">
      <header-component/>
      <!-- Modal de usuario -->
      <!--div class="modal show-modal-active" v-if="ShowModalAdverts">
        <div class="z-10 w-11/12 sm:w-4/6 m-auto bg-white border-2 p-5 modal-active">
          <div id="modal-header" class="flex">
            <div class="w-6/12">
              
            </div>
            <div class="w-6/12 text-right">
              <label
                class="text-gray-400 text-xl hover:text-gray-700 transition duration-150"
                v-on:click="CloseModal()"
                >X</label
              >
            </div>
          </div>
          <div class="border-t-2 w-full mb-1 mt-1"></div>
          <div id="modal-body mb-2">
            <div class="block sm:flex">
              <div class="px-3 w-full">
                <adverts/>
              </div>
            </div>
          </div>
          <div class="border-t-2 w-full mb-1 mt-1"></div>
          <div id="modal-footer">
            <div class="text-center">
              <button
                id="btn-closed"
                class="bg-white hover:transition duration-150 hover:bg-orange-500 hover:border-orange-500 text-orange-700 hover:text-white px-3 py-2 border border-orange-700 rounded-xl ml-3 focus:outline-none focus:shadow-outline-orange focus:border-orange-700"
                v-on:click="CloseModal()"
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div-->
      <!-- Modal de usuario -->
      <main-component />
      <footer-component/>
    </div>
  </div>
  <!-- Your Chat Plugin code -->
  <div class="fb-customerchat"
      attribution=install_email
      attribution_version="biz_inbox"
      page_id="103137977784270"
      theme_color="#ff7a00"
        logged_in_greeting="&#xa1;Hola!, &#xbf;en qu&#xe9; podemos ayudarte?"
        logged_out_greeting="&#xa1;Hola!, &#xbf;en qu&#xe9; podemos ayudarte?">
    </div>

    <div style="margin-top: 80px;"  >
      <a href="https://bit.ly/WhatsAppAnter" class="float" target="_blank">
        <i class="fa fa-whatsapp my-float"></i>
      </a>
    </div>
</template>
<script>
// import {ref} from "vue";
// import Adverts from "@/components/common/AdvertisingSlider.vue";
import MainComponent from "@/components/main-page/MainComponent.vue";
import PatternBg from "@/components/common/PatternBg.vue";
import FooterComponent from '@/components/common/FooterComponent.vue';
import HeaderComponent from "@/components/common/HeaderComponent.vue";
export default {
  components: {
    HeaderComponent,
    MainComponent,
    PatternBg,
    FooterComponent
    // Adverts
  },
  setup() {
    // let ShowModalAdverts = ref(true);
    // function CloseModal() {
    //   ShowModalAdverts.value = false;
    // }
    // return{
    //   CloseModal,
    //   ShowModalAdverts
    // }

  },
};
</script>
