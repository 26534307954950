import { createWebHistory, createRouter } from "vue-router";

import { isAuth } from '@/helpers/auth.js';

import MainPage from '@/views/pages/MainPage.vue';
import Quoter from '@/views/pages/quotations/Quoter.vue';
import Faq from '@/views/pages/Faq.vue';
import Contact from '@/views/pages/Contact.vue';
import Privacy from '@/views/pages/Privacy.vue';
import Terms from '@/views/pages/Terms.vue';

import LandingRadames from '@/views/pages/radames/LandingRadames.vue';

import Cars from '@/views/pages/products/Cars.vue';
import CarsBusiness from '@/views/pages/products/CarsBusiness.vue';
import Health from '@/views/pages/products/Health.vue';

import Login from '@/views/pages/account/Login.vue';
import CreateAccount from '@/views/pages/account/CreateAccount.vue';
import AccountRecover from '@/views/pages/account/AccountRecover.vue';
import ChangePassword from '@/views/pages/account/ChangePassword.vue';
import AccountConfirmation from '@/views/pages/account/AccountConfirmation.vue';
import ConfirmAccount from '@/views/pages/account/ConfirmAccount.vue';

import Summary from '@/views/pages/dashboard/Summary.vue';
import Reports from '@/views/pages/dashboard/Reports.vue';
import Staff from '@/views/pages/dashboard/Staff.vue';
import Profile from '@/views/pages/dashboard/Profile.vue';
import Activity from '@/views/pages/dashboard/Activity.vue';
import PolicyPdf from '@/views/pages/dashboard/PolicyPdf.vue';

import CustomerMain from '@/views/pages/customer/MainPage.vue';
import CustomerProfile from '@/views/pages/customer/Profile.vue';
import CustomerQuotations from '@/views/pages/customer/Quotation.vue';
import CustomerPolicies from '@/views/pages/customer/Policy.vue';
import CustomerPayments from '@/views/pages/customer/Payment.vue';
import InsuranceContact from '@/views/pages/customer/InsuranceContact.vue';
import InsuranceCompanies from '@/views/pages/dashboard/InsuranceCompanies.vue';
import ErrorHistory from '@/views/pages/dashboard/ErrorHistory.vue';
import Sales from '@/views/pages/dashboard/SalesReport.vue';
import ShowQuestions from '@/views/pages/dashboard/QuestionsConfig.vue';

const routes = [
  {
    path: "/",
    name: "Main Page",
    meta: { requiresAuth: false },
    component: MainPage
  },
  {
    path: "/cotizacion",
    name: "Quoter",
    meta: { requiresAuth: false },
    component: Quoter,
    children: [
      {
        path: '/productos',
        name: "Products",
        meta: { requiresAuth: false },
        component: () => import(/* webpackChunkName: "Steps" */'@/components/quoter/Products.vue')
      },
      {
        path: '/preguntas',
        name: "Questions",
        meta: { requiresAuth: false },
        component: () => import(/* webpackChunkName: "Steps" */'@/components/quoter/Steps.vue')
      },
      {
        path: '/resumen-cotizacion',
        name: "QuoteResume",
        meta: { requiresAuth: false },
        component: () => import(/* webpackChunkName: "QuoteResume" */'@/components/quoter/QuoteResume.vue')
      },
      {
        path: '/resumen-emision',
        name: "IssuanceResume",
        meta: { requiresAuth: false },
        component: () => import(/* webpackChunkName: "IssuanceResume" */'@/components/issuance/IssuanceResume.vue')
      },
      {
        path: '/pago-en-linea',
        name: "WayOfPayment",
        meta: { requiresAuth: false },
        component: () => import(/* webpackChunkName: "WayOfPaymente" */'@/components/quoter/WayOfPayment.vue')
      },
      {
        path: '/gracias-por-tu-compra',
        name: "PaymentCompleted",
        meta: { requiresAuth: false },
        component: () => import(/* webpackChunkName: "PaymentCompleted" */'@/components/quoter/PaymentCompleted.vue')
      },
      {
        path: '/cotizaciones',
        name: "Quotation",
        meta: { requiresAuth: false },
        component: () => import(/* webpackChunkName: "Quotationt" */'@/components/quoter/Quotation.vue')
      },
      {
        path: '/ayuda',
        name: "Help",
        meta: { requiresAuth: false },
        component: () => import(/* webpackChunkName: "Help" */'@/components/quoter/Help.vue')
      },
    ]
  },
  {
    path: "/preguntas-frecuentes",
    name: "Frequently Asked Questions",
    meta: { requiresAuth: false },
    component: Faq
  },
  {
    path: "/autos",
    name: "Car services",
    meta: { requiresAuth: false },
    component: Cars
  },
  {
    path: "/autos-empresa",
    name: "Car Business services",
    meta: { requiresAuth: false },
    component: CarsBusiness
  },
  {
    path: "/contactanos",
    name: "Contact us",
    meta: { requiresAuth: false },
    component: Contact
  },
  {
    path: "/salud",
    name: "Health",
    meta: { requiresAuth: false },
    component: Health
  },
  {
    path: "/login",
    name: "Login",
    meta: { requiresAuth: false },
    component: Login
  },
  {
    path: "/createAccount",
    name: "CreateAccount",
    meta: { requiresAuth: false },
    component: CreateAccount
  },
  {
    path: "/accountRecover",
    name: "AccountRecover",
    meta: { requiresAuth: false },
    component: AccountRecover
  },
  {
    path: "/changePassword/:token/:email",
    name: "ChangePassword",
    meta: { requiresAuth: false },
    component: ChangePassword
  },
  {
    path: "/confirmation/:confirmation_token/:email",
    name: "AccountConfirmation",
    meta: { requiresAuth: false },
    component: AccountConfirmation
  },
  {
    path: "/confirmAccount",
    name: "ConfirmAccount",
    meta: { requiresAuth: false },
    component: ConfirmAccount
  },
  {
    path: "/aviso-de-privacidad",
    name: "Privacy",
    meta: { requiresAuth: false },
    component: Privacy
  },
  {
    path: "/terminos-y-condiciones",
    name: "Terms",
    meta: { requiresAuth: false },
    component: Terms
  },
  {
    path: "/panel-administrativo",
    name: "Dashboard",
    meta: { requiresAuth: true },
    component: Summary
  },

  {
    path: "/panel/reportes",
    name: "DashboardReports",
    component: Reports
  },

  {
    path: "/panel/personal",
    name: "DashboardStaff",
    meta: { requiresAuth: true },
    component: Staff
  },
  {
    path: "/panel/aseguradoras",
    name: "DashboardInsuranceCompanies",
    meta: { requiresAuth: true },
    component: InsuranceCompanies
  },
  {
    path: "/panel/historial-errores",
    name: "DashboardErrorHistory",
    meta: { requiresAuth: true },
    component: ErrorHistory
  },
  {
    path: "/panel/resumen-ventas",
    name: "DashboardSales",
    meta: { requiresAuth: true },
    component: Sales
  },
  {
    path: "/panel/mi-perfil",
    name: "DashboardProfile",
    meta: { requiresAuth: true },
    component: Profile
  },
  {
    path: "/panel/mis-registros",
    name: "Activity",
    meta: { requiresAuth: true },
    component: Activity
  },
  {
    path: "/panel/descargar-poliza",
    name: "PolicyPdf",
    meta: { requiresAuth: true },
    component: PolicyPdf
  },
  {
    path: "/panel/configuracion-preguntas",
    name: "ShowQuestions",
    meta: { requiresAuth: true },
    component: ShowQuestions
  },
  {
    path: "/cliente/inicio",
    name: "Customer Main",
    meta: { requiresAuth: true },
    component: CustomerMain
  },
  {
    path: "/cliente/mi-perfil",
    name: "Customer Profile",
    meta: { requiresAuth: true },
    component: CustomerProfile
  },
  {
    path: "/cliente/mis-cotizaciones",
    name: "Quotations",
    meta: { requiresAuth: true },
    component: CustomerQuotations
  },
  {
    path: "/cliente/mis-polizas",
    name: "Policies",
    meta: { requiresAuth: true },
    component: CustomerPolicies
  },

  {
    path: "/cliente/mis-pagos",
    name: "Payments",
    meta: { requiresAuth: true },
    component: CustomerPayments
  },
  {
    path: "/cliente/contacto-aseguradoras",
    name: "InsuranceContact",
    meta: { requiresAuth: true },
    component: InsuranceContact
  },
  {
    path: "/radames",
    name: "LandingRadames",
    meta: { requiresAuth: false },
    component: LandingRadames
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const { requiresAuth } = to.meta;

  const auth = isAuth();
  if (requiresAuth) {
    if (!auth) {
      return next({ path: '/' });
    }
  }

  next();
});

export default router;