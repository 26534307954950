const hasSpecialcharacters = text => {
    const regexSpecialcharacters = /[$.|?*+()]/g;
    return !regexSpecialcharacters.test(text);
}

const hasCharactersNumbers = text => {
    const regexCharactersNumbers = /[0-9]/g;
    return !regexCharactersNumbers.test(text);
}

const hasLetters = text => {
    const regexCharactersNumbers = /[a-zA-z]/g;
    return !regexCharactersNumbers.test(text);
}

export { hasSpecialcharacters, hasCharactersNumbers, hasLetters };