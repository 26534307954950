<template>
<div>
  <h1 class="font-semibold text-xl mb-5 text-center">Registro dependiente </h1>
  <form lang="en" v-if="stepName == 'Datos personales'">
    <h2 class="font-semibold text-base  mb-5 ">{{stepName}}</h2>
    <div v-for="question in dependentData" :key="question.id">
      <label class="font-semibold">{{question.question}}</label>
      <options :arrayOptions="question.options" :questionType="question.questionType" :questionId="question.id" productName="Vida" subcategoryName="Datos dependiente" :questionDescription="question.question" categoryName="PERSONALES" :validations="question.validations" />
    </div>
  </form>
  <div v-if="stepName == 'Historia clínica'">
    <h2 class="font-semibold text-base  mb-5 ">{{stepName}}</h2>
    <div v-for="question in dependentClinicHistory" :key="question.id">
      <label class="font-semibold">{{question.question}}</label>
      <options :arrayOptions="question.options" :questionType="question.questionType" :questionId="question.id" productName="Vida" subcategoryName="Historial clínico dependiente" :questionDescription="question.question" categoryName="COTIZACION" :validations="question.validations" :childQuestions="question.childQuestions" />
    </div>
  </div>
  <div v-if="stepName == 'Historia obstétrica' && dependentGender == 'FEMENINO'">
    <h2 class="font-semibold text-base  mb-5 ">Historia obstétrica</h2>
    <div v-for="question in dependentObstetricHistory" :key="question.id">
      <label class="font-semibold">{{question.question}}</label>
      <options :arrayOptions="question.options" :questionType="question.questionType" :questionId="question.id" productName="Vida" subcategoryName="Datos dependiente" :questionDescription="question.question" categoryName="PERSONALES" :validations="question.validations" />
    </div>
  </div>
  <p class="text-red-500 text-xs italic mt-5">{{message}}</p>
  <div class="flex justify-between  mt-16">
    <span class="inline-flex rounded-md shadow-sm">
      <button type="button" @click="back()" class="w-full inline-flex items-center justify-center px-6 py-3 border border-orange-500 text-base leading-6 font-medium rounded-md text-orange-500 bg-white hover:bg-orange-500 hover:text-white focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition ease-in-out duration-150">
        Atrás
      </button>
    </span>
    <span class="inline-flex rounded-md shadow-sm" v-show="stepName == 'Datos personales' || stepName == 'Historia clínica' && dependentGender == 'FEMENINO'">
      <button type="button" @click="next()" class="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-orange-500 hover:bg-orange-500 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition ease-in-out duration-150">
        Siguiente
      </button>
    </span>
    <span class="inline-flex rounded-md shadow-sm" v-show="stepName == 'Historia clínica' && dependentGender == 'MASCULINO' || stepName == 'Historia obstétrica'">
      <button type="button" @click="add()" class="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-orange-500 hover:bg-orange-500 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition ease-in-out duration-150">
        Agregar
      </button>
    </span>
  </div>
</div>
</template>

<script>
import {
  ref,
  inject,
  onMounted,
  watchEffect
  // useRouter
} from "vue";
import Options from "@/components/quoter/OptionsDependents.vue";
import Validation from '@/classes/Validation.js'
import Swal from "sweetalert2";
import {
  useRouter
} from 'vue-router';
export default {
  name: "Questions",
  components: {
    Options
  },
  setup() {
    const router = useRouter();
    const stepActive = ref(1);
    const stepName = inject('stepName');
    const dependentGender = ref("");
    const showMessage = ref(false);

    const contCategory = inject('contCategory');
    const contSteps = inject('contSteps');
    const dependentData = inject('dependentData');
    const dependentClinicHistory = inject('dependentClinicHistory');
    const dependentObstetricHistory = inject('dependentObstetricHistory');
    const arrayDependents = inject('arrayDependents');
    const arrayAnswersDependents = inject('arrayAnswersDependents');
    const arrayIdQuestions = inject('arrayIdQuestions');
    const message = inject('message');
    watchEffect(() => validateStep(stepActive.value))

    onMounted(() => {
    })

    function back() {
      stepActive.value--;
      if (stepActive.value < 1) {
        router.push({
          name: "Questions"
        });
      }
    }

    function next() {
      validateAnswer();
      if (showMessage.value) {
        message.value = "Ingrese una respuesta correcta"
        return;
      }
      stepActive.value++;
    }

    function validateStep(step) {
      let found = "";
      switch (step) {
        case 1:
          stepName.value = "Datos personales";
          found = arrayAnswersDependents.value.find(element => element.question.toUpperCase().includes("SEXO"));
          if (found != "" && found != undefined) {
            dependentGender.value = found.answer.toUpperCase();
          }
          break;
        case 2:
          stepName.value = "Historia clínica";
          break;
        case 3:
          stepName.value = "Historia obstétrica";
          break;
        default:
          stepName.value = "Datos";
          break;
      }
    }

    async function add() {
      validateAnswer();
      if (showMessage.value) {
        message.value = "Ingrese una respuesta correcta"
        return;
      }
      arrayDependents.value.push(arrayAnswersDependents.value);
      localStorage.setItem("arrayDependents", JSON.stringify(arrayDependents));
      await Swal.fire(
        "Aviso",
        "Dependiente agregado.",
        "success"
      ).then(()=>{
        arrayAnswersDependents.value = [];
      })
      Swal.fire({
        title: '¿Desea agregar otro dependiente?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#FF5733',
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
      }).then((result) => {
        console.log("result ",result)
        if (result.isConfirmed) {
          stepActive.value = 1;
          console.log("stepActive",stepActive.value);
          stepName.value = "Datos personales";
          console.log("stepActive",stepName.value);
        } else {
          router.push({
            name: "LifeIssuanceResume"
          });
        }
      })
    }

     function validateAnswer() {
      showMessage.value =  Validation.validateAllQuestionsNotEmpty(arrayAnswersDependents.value, arrayIdQuestions.value);
    }

    return {
      contSteps,
      contCategory,
      dependentData,
      dependentClinicHistory,
      stepActive,
      stepName,
      back,
      next,
      add,
      dependentGender,
      dependentObstetricHistory,
      message
    }
  }
}
</script>
