<template>
  <!-- Page header -->
  <div class="bg-white shadow">
    <div class="px-4 sm:px-6 lg:mx-auto lg:px-8">
      <div class="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-cool-gray-200" >
        <div class="flex-1 min-w-0">
          <!-- Profile -->
          <div class="flex items-center">
            <!-- User Image -->
            <!-- <img :class="{'animate-pulse': loading }" class="hidden h-15 w-15 bg-gray-300 rounded-full flex-shrink-0 sm:block" :src="(user.gender == 'Masculino') ? Hombre : Mujer" /> -->
            <!-- End User Image -->
            <div>
              <div class="flex items-center">
                <img :class="{'animate-pulse': loading }" class="h-15 w-15  bg-gray-300 rounded-full sm:hidden" :src="(user.gender == 'Masculino') ? Hombre : Mujer" />
                <h1 class="ml-3 text-2xl font-bold leading-7 text-cool-gray-900 sm:leading-9 sm:truncate">
                  Bienvenid@, {{ user.fullName }}!
                </h1>
              </div>
              <dl class="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                <dt class="sr-only">Company</dt>
                <dd class="flex items-center text-sm leading-5 text-cool-gray-500 font-medium sm:mr-6">
                  <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-cool-gray-400" x-description="Heroicon name: office-building" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z" clip-rule="evenodd"></path>
                  </svg>
                  {{ user.email }}
                </dd>
                <dt class="sr-only">Account status</dt>
                <dd class="mt-3 flex items-center text-sm leading-5 text-cool-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize">
                  <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"/>
                  </svg>
                  {{ user.role }}
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { getUserAuthInfo } from "@/helpers/auth.js";
import Hombre from '@/assets/images/dashboard/hombre.png';
import Mujer from '@/assets/images/dashboard/mujer.png';

export default {
  setup(){
    const user = ref({
      "image": "",
      "firstName": "",
      "middleName": "", 
      "lastName": "",
      "fullName": "",
      "email": "",
      "role": "",
      "gender":""
    });

    const loading = ref(true);

    const showUserInfo = () => {
      
      const data = getUserAuthInfo();

      if(data){
        user.value.image = (data.image != null)? data.image: '';
        user.value.firstName = data.persona.nombres;
        user.value.lastName = `${data.persona.apellido_paterno} ${data.persona.apellido_materno}`;
        user.value.fullName = `${ user.value.firstName } ${ user.value.lastName }`;

        user.value.email = data.email;
        user.value.role = data.rol;
        user.value.gender = data.persona.sexo;

        setTimeout(() => {
          loading.value = false;
        }, 2000);
        
      }
    }
    
    onMounted(() => {
      showUserInfo();
    });

    return {
      user,
      loading,
      Hombre, 
      Mujer
    };
  }
}
</script>