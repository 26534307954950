<template>
  <div class="py-0 overflow-hidden lg:py-4">
    <div class="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
      <dot-pattern-right/>
      <quote-service/>
      <about-us/>
      <dot-pattern-left/>
      <mision/>
    </div>
  </div>
</template>

<script>
import Mision from "@/components/main-page/info-section/Mision.vue";
import AboutUs from "@/components/main-page/info-section/AboutUs.vue";
import QuoteService from "@/components/main-page/info-section/QuoteService.vue";
import DotPatternRight from "@/components/common/DotPatternRight.vue";
import DotPatternLeft from "@/components/common/DotPatternLeft.vue";

export default {
  components:{
    Mision,
    AboutUs,
    QuoteService,
    DotPatternRight,
    DotPatternLeft
  },
  setup() {
  },
}
</script>

