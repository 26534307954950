<template>
<div>
  <div v-for="question in arrayQuestions" :key="question.id" required>
    <div v-if="question.id == 1"><p class="font-bold text-2xl">¿Recuerdas que te dije que te haría unas preguntas? Me gustaría empezar con tu nombre y algunos datos adicionales para mostrarte tu cotización.</p><br></div>
    <label v-if="question.id == 7" class="font-semibold">
      {{clientName}}, {{question.question}}: **</label>
    <label v-else-if="question.id == 6" class="font-semibold">
      {{question.question}} {{clientName}}? **</label>
    <label v-else-if="question.id == 19" class="font-semibold">
      {{question.question}}: </label>
    <label v-else class="font-semibold">
      {{question.question}}: **</label>
    <options :arrayOptions="question.options" :questionType="question.questionType" :questionId="question.id" :productName="question.productName" 
    :subcategoryName="question.subcategoryName" :questionDescription="question.question" :categoryName="categoryName"  :validations="question.validations"
    :childQuestions="question.childQuestions" />

  </div>
  <p style="color: #9fa6b2; font-size: 0.75rem; text-align: center;">No compartiremos tu información con nadie sin tu consentimiento</p>
</div>
</template>

<script>
import {
  inject,
  onMounted
} from "vue";
import Options from "@/components/quoter/Options.vue";
export default {
  name: "Questions",
  components: {
    Options
  },
  props: {
    arrayQuestions: {
      type: Array
    },
    categoryName: {
      type: String
    }

  },
  setup() {
    const clientName = inject("clientName");
    const contCategory = inject('contCategory');
    const step = inject('step');
    const contSteps = inject('contSteps');
    const arrayIdQuestions = inject('arrayIdQuestions');
    const arrayAnswers = inject('arrayAnswers');
    const parametrosCotizacion = inject('parametrosCotizacion');

    onMounted(() => {
      
      var usuario_actual = JSON.parse(sessionStorage.getItem("login"));
      if(usuario_actual){
        parametrosCotizacion.value.usuario_id = usuario_actual.id;
      }
    })

    return {
      step,
      contSteps,
      contCategory,
      arrayIdQuestions,
      arrayAnswers,
      clientName,
      parametrosCotizacion
    }
  }
}
</script>
