<template>
    <div x-data="{ open: false }" class="relative pt-6 pb-12 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">
      <header-component/>
      <landing-container/>
      <footer-component/>
    </div>
  </template>
  <script>
  import FooterComponent from '@/components/radames/FooterComponent.vue';
  import HeaderComponent from "@/components/radames/HeaderComponent.vue";
  import LandingContainer from "@/components/radames/LandingContainer.vue";

 

  export default {
    components: {
      HeaderComponent,
      FooterComponent,
      LandingContainer
    },
    setup() {},
  };
  </script>