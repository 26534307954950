<template>
  <div class="max-w-screen-xl mx-auto mb-8 px-4 sm:px-6">
    <nav class="relative flex items-center justify-between sm:h-10">
      <div class="flex items-center flex-1">
        <div class="flex items-center mt-18 mb-18 justify-between w-full md:w-auto">
          <a href="/radames" aria-label="Home">
            <img class="h-24 w-auto" src="@/assets/images/radames/rada-seguros.png" alt="Rad"/>
          </a>
        </div>
      </div>
    </nav>
  </div>
</template>
