// import Swal from "sweetalert2";
// import axios from "axios";
// var ccapi = process.env.VUE_APP_CCAPI;

export default class Payment {
  
  constructor() {
  }
  
  setPaymentResume(parametrosPago){
    var car_description = document.getElementById("car_description");
    var quote_coverage = document.getElementById("quote_coverage");
    var quote_payment_frequency = document.getElementById("quote_payment_frequency");
    var quote_price = document.getElementById("quote_price");
    var issuance_id = document.getElementById("issuance_id");
    var provider_img = document.getElementById("provider_img");

    provider_img.innerHTML = "<img class='w-full' style='margin-bottom:-75px; margin-top:-75px;' src= 'https://cabsacloud.ams3.digitaloceanspaces.com/store/ANTER/logos_aseguradoras/logo_"+parametrosPago.proveedor+".svg'/>"
    car_description.innerHTML = "<h3>Auto</h3><p>"+parametrosPago.informacion.auto.modelo + " " + parametrosPago.informacion.auto.descripcion+"</p>"  
    quote_coverage.innerHTML = "<h3>Cobertura</h3><p>"+parametrosPago.informacion.auto.cobertura+"</p>"  
    quote_payment_frequency.innerHTML = "<h3>Pago</h3><p>"+parametrosPago.informacion.auto.frecuencia_de_pago+"</p>"  
    quote_price.innerHTML = "<h3>Total a pagar</h3><p>"+parametrosPago.informacion.emision.primer_importe+"</p>"  
    issuance_id.innerHTML = "<br><p>FOLIO DE PAGO: "+parametrosPago.informacion.emision_id+"</p>"  
  }


  // setOptions(params){
  //   return {
  //     headers: {
  //       "Content-Type": "Application/Json"
  //     },
  //     params: btoa(params),
  //   };
  // }

  encryptBankCard(brand, bank, cardHolder, cardNumber, month, year, cvv){
    return {
      "tipo": btoa(brand),
      "banco": btoa(bank),
      "nombre_titular": btoa(cardHolder),
      "numero": btoa(cardNumber),
      "mes_expiracion": btoa(month),
      "año_expiracion": btoa(year),
      "cvv": btoa(cvv)
    }
  }
}