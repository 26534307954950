<template>
<div>
  <div class="bg-white">
    <div class="bg-white p-2 space-y-2 shadow-md rounded ">
      <div class="px-4  sm:px-6 mx-auto">
        <h1 class="text-2xl sm:text-4xl leading-6 font-bold text-gray-700  mb-5 text-center">
          Carga de documentos
        </h1>
        <p class="mt-1 text-sm text-gray-500 text-center">
          A continuación realiza la carga de los documentos listados en formato pdf, png o jpeg para concluir con tu proceso de registro.
        </p>
      </div>
      <div class="bg-white shadow overflow-hidden sm:rounded-lg max-w-6xl mx-auto">
        <div class=" px-4 py-5 sm:p-0">
          <dl class="sm:divide-y sm:divide-gray-200">
            <div class="py-2 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">
                Póliza
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {{lifeIssuanceResponseParams.ProcessId}}
              </dd>
              <dt class="text-sm font-medium text-gray-500">
                Nombre
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {{fullname}}
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <div class="hideInSmallScreens bg-white shadow overflow-hidden sm:rounded-lg max-w-6xl mx-auto">
        <!--encabezados-->
        <div class="flex flex-wrap m-4">
          <div class="w-8/12 sm:w-3/12  sm:p-4  mb-1">
            <div role="form">
              <p id="document" class="text-base leading-6 font-bold text-gray-700 mb-5">Documento</p>
            </div>
          </div>
          <div class="w-4/12 sm:w-2/12  sm:p-4  mb-1">
            <p id="status" class="text-base leading-6 font-bold text-gray-700 mb-5">Estatus</p>
          </div>
          <div class="w-8/12 sm:w-3/12  sm:p-4  mb-1">
            <p id="document_name" class="text-base leading-6 font-bold text-gray-700 mb-5">Nombre del archivo</p>
          </div>
          <div class="w-4/12 sm:w-4/12  sm:p-4  mb-1">
          </div>
          <!--Identificación titular-->
          <div class="w-8/12 sm:w-3/12  sm:p-4">
            <div role="form">
              <p class="leading-6 text-sm text-gray-500 mb-5">Identificación titular</p>
            </div>
          </div>
          <div class="w-4/12 sm:w-2/12  sm:p-4  mb-1">
            <Check v-if="name_holder_identification" />
            <div v-show="name_holder_identification == ''" class="relative pt-1">
              <div class="flex mb-2 items-center justify-between">
                <div class="text-right">
                  <span class="text-xs font-semibold inline-block text-teal-600 ">
                    {{progress +'%'}}
                  </span>
                </div>
              </div>
              <div class="overflow-hidden h-4 mb-4 text-xs flex rounded bg-green-200">
                <div :style="{ width: progress + '%'}" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"></div>
              </div>
            </div>
          </div>
          <div id="fileList" class="w-8/12 sm:w-3/12  sm:p-4  mb-1">
            <p id="name_holder_identification" class="leading-6 text-sm text-orange-500 mb-5">{{name_holder_identification}}</p>
          </div>
          <div class="w-4/12 sm:w-4/12  p-4  mb-1">
            <input type="button" id="btn_load_holder_identification" class="text-gray-500 bg-white p-2 space-y-2 shadow-md rounded border-gray-900" value="Seleccionar archivo" v-on:click="getFile('holder_identification')" />
            <input type="file" id="holder_identification" accept=".pdf,.png,.PNG,.jpeg" @change="onFileSelected($event,'holder_identification')" name="myfile" class="hidden">
          </div>

          <!-- Comprobante de pago-->
          <div class="w-8/12 sm:w-3/12  p-4  mb-1">
            <div role="form">
              <p id="document_type_payment" class="leading-6 text-sm text-gray-500 mb-5">Comprobante de pago</p>
            </div>
          </div>
          <div class="w-4/12 sm:w-2/12  p-4  mb-1">
            <Check v-show="name_payment" />
            <div v-show="name_payment==''" class="relative pt-1">
              <div class="flex mb-2 items-center justify-between">
                <div class="text-right">
                  <span class="text-xs font-semibold inline-block text-teal-600 ">
                    {{progress +'%'}}
                  </span>
                </div>
              </div>
              <div class="overflow-hidden h-4 mb-4 text-xs flex rounded bg-green-200">
                <div :style="{ width: progress + '%'}" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"></div>
              </div>
            </div>
          </div>
          <div class="w-8/12 sm:w-3/12  p-4  mb-1">
            <p id="name_payment" class="leading-6 text-sm text-orange-500 mb-5">{{name_payment}}</p>
            <!-- Comprobante <progress-bar :options="options" :value="value" />-->
          </div>
          <div class="w-4/12 sm:w-4/12  p-4  mb-1">
            <input type="button" id="btn_load_payment" class="text-gray-500 bg-white p-2 space-y-2 shadow-md rounded border-gray-900" value="Seleccionar archivo" v-on:click="getFile('payment')" />
            <input type="file" id="payment" accept=".pdf,.png,.PNG,.jpeg" @change="onFileSelected($event,'payment')" name="myfile" class="hidden">
          </div>

          <!-- Carta aclaratoria-->
          <div class="w-8/12 sm:w-3/12  p-4  mb-1">
            <div role="form">
              <p class="leading-6 text-sm text-gray-500 mb-5">Carta aclaratoria</p>
            </div>
          </div>
          <div class="w-4/12 sm:w-2/12  p-4  mb-1">
            <Check v-show="name_medical_exclamation_letter" />
            <div v-show="name_medical_exclamation_letter==''" class="relative pt-1">
              <div class="flex mb-2 items-center justify-between">
                <div class="text-right">
                  <span class="text-xs font-semibold inline-block text-teal-600 ">
                    {{progress +'%'}}
                  </span>
                </div>
              </div>
              <div class="overflow-hidden h-4 mb-4 text-xs flex rounded bg-green-200">
                <div :style="{ width: progress + '%'}" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"></div>
              </div>
            </div>
          </div>
          <div class="w-8/12 sm:w-3/12  p-4  mb-1">
            <p id="name_medical_exclamation_letter" class="leading-6 text-sm text-orange-500 mb-5">{{name_medical_exclamation_letter}}</p>
          </div>
          <div class="w-4/12 sm:w-4/12  p-4  mb-1">
            <input type="button" id="btn_load_medical_exclamation_letter" class="text-gray-500 bg-white p-2 space-y-2 shadow-md rounded border-gray-900" value="Seleccionar archivo" v-on:click="getFile('medical_exclamation_letter')" />
            <input type="file" id="medical_exclamation_letter" accept=".pdf,.png,.PNG,.jpeg" @change="onFileSelected($event,'medical_exclamation_letter')" name="myfile" class="hidden">
          </div>
          <!-- Reconocimiento de antigüedad-->
          <div class="w-8/12 sm:w-3/12  p-4  mb-1">
            <div role="form">
              <p class="leading-6 text-sm text-gray-500 mb-5">Reconocimiento de antigüedad</p>
            </div>
          </div>
          <div class="w-4/12 sm:w-2/12  p-4  mb-1">
            <Check v-show="name_recognition_antiquity" />
            <div v-show="name_recognition_antiquity==''" class="relative pt-1">
              <div class="flex mb-2 items-center justify-between">
                <div class="text-right">
                  <span class="text-xs font-semibold inline-block text-teal-600 ">
                    {{progress +'%'}}
                  </span>
                </div>
              </div>
              <div class="overflow-hidden h-4 mb-4 text-xs flex rounded bg-green-200">
                <div :style="{ width: progress + '%'}" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"></div>
              </div>
            </div>
          </div>
          <div class="w-8/12 sm:w-3/12  p-4  mb-1">
            <p id="name_recognition_antiquity" class="leading-6 text-sm text-orange-500 mb-5">{{name_recognition_antiquity}}</p>
          </div>
          <div class="w-4/12 sm:w-4/12  p-4  mb-1">
            <input type="button" id="btn_load_recognition_antiquity" class="text-gray-500 bg-white p-2 space-y-2 shadow-md rounded border-gray-900" value="Seleccionar archivo" v-on:click="getFile('recognition_antiquity')" />
            <input type="file" id="recognition_antiquity" accept=".pdf,.png,.PNG,.jpeg" @change="onFileSelected($event,'recognition_antiquity')" name="myfile" class="hidden">
          </div>
          <!-- Contrato MUTUUS-->
          <div class="w-8/12 sm:w-3/12  p-4  mb-1">
            <div role="form">
              <p id="document_type_mutuus_contract" class="leading-6 text-sm text-gray-500 mb-5">Contrato MUTUUS</p>
            </div>
          </div>
          <div class="w-4/12 sm:w-2/12  p-4  mb-1">
            <Check v-show="name_mutuus_contract" />
            <div v-show="name_mutuus_contract==''" class="relative pt-1">
              <div class="flex mb-2 items-center justify-between">
                <div class="text-right">
                  <span class="text-xs font-semibold inline-block text-teal-600 ">
                    {{progress +'%'}}
                  </span>
                </div>
              </div>
              <div class="overflow-hidden h-4 mb-4 text-xs flex rounded bg-green-200">
                <div :style="{ width: progress + '%'}" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"></div>
              </div>
            </div>
          </div>
          <div class="w-8/12 sm:w-3/12  p-4  mb-1">
            <p id="name_mutuus_contract" class="leading-6 text-sm text-orange-500 mb-5">{{name_mutuus_contract}}</p>
          </div>
          <div class="w-4/12 sm:w-4/12  p-4  mb-1">
            <input type="button" id="btn_load_mutuus_contract" class="text-gray-500 bg-white p-2 space-y-2 shadow-md rounded border-gray-900" value="Seleccionar archivo" v-on:click="getFile('mutuus_contract')" />
            <input type="file" id="mutuus_contract" accept=".pdf,.png,.PNG,.jpeg" @change="onFileSelected($event,'mutuus_contract')" name="myfile" class="hidden">
          </div>
          <!-- Cédula fiscal-->
          <div class="w-8/12 sm:w-3/12  p-4  mb-1">
            <div role="form">
              <p id="document_type_identification_card" class="leading-6 text-sm text-gray-500 mb-5">Cédula fiscal</p>
            </div>
          </div>
          <div class="w-4/12 sm:w-2/12  p-4  mb-1">
            <Check v-show="name_identification_card" />
            <div v-show="name_identification_card==''" class="relative pt-1">
              <div class="flex mb-2 items-center justify-between">
                <div class="text-right">
                  <span class="text-xs font-semibold inline-block text-teal-600 ">
                    {{progress +'%'}}
                  </span>
                </div>
              </div>
              <div class="overflow-hidden h-4 mb-4 text-xs flex rounded bg-green-200">
                <div :style="{ width: progress + '%'}" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"></div>
              </div>
            </div>
          </div>
          <div class="w-8/12 sm:w-3/12  p-4  mb-1">
            <p id="name_identification_card" class="leading-6 text-sm text-orange-500 mb-5">{{name_identification_card}}</p>
          </div>
          <div class="w-4/12 sm:w-4/12  p-4  mb-1">
            <input type="button" id="btn_load_identification_card" class="text-gray-500 bg-white p-2 space-y-2 shadow-md rounded border-gray-900" value="Seleccionar archivo" v-on:click="getFile('identification_card')" />
            <input type="file" id="identification_card" accept=".pdf,.png,.PNG,.jpeg" @change="onFileSelected($event,'identification_card')" name="myfile" class="hidden">
          </div>
          <!-- Otros-->
          <div class="w-8/12 sm:w-3/12  p-4  mb-1">
            <div role="form">
              <p id="document_type_others" class="leading-6 text-sm text-gray-500 mb-5">Otros</p>
            </div>
          </div>
          <div class="w-4/12 sm:w-2/12  p-4  mb-1">
            <Check v-show="name_others" />
            <div v-show="name_others==''" class="relative pt-1">
              <div class="flex mb-2 items-center justify-between">
                <div class="text-right">
                  <span class="text-xs font-semibold inline-block text-teal-600 ">
                    {{progress +'%'}}
                  </span>
                </div>
              </div>
              <div class="overflow-hidden h-4 mb-4 text-xs flex rounded bg-green-200">
                <div :style="{ width: progress + '%'}" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"></div>
              </div>
            </div>
          </div>
          <div class="w-8/12 sm:w-3/12  p-4  mb-1">
            <p id="name_others" class="leading-6 text-sm text-orange-500 mb-5">{{name_others}}</p>
          </div>
          <div class="w-4/12 sm:w-4/12  p-4  mb-1">
            <form>
              <div class="form-group">
                <input type="button" id="btn_load_others" class="text-gray-500 bg-white p-2 space-y-2 shadow-md rounded border-gray-900" value="Seleccionar archivo" v-on:click="getFile('others')" />
                <input type="file" id="others" accept=".pdf,.png,.PNG,.jpeg" @change="onFileSelected($event,'others')" name="myfile" class="hidden">
              </div>
            </form>
          </div>
          <!-- Identificación dependientes-->
          <div class="w-8/12 sm:w-3/12  p-4  mb-1">
            <div role="form">
              <p id="document_type_dependent_identification" class="leading-6 text-sm text-gray-500 mb-5">Identificación dependientes</p>
            </div>
          </div>
          <div class="w-4/12 sm:w-2/12  p-4  mb-1">
            <Check v-show="name_dependent_identification" />
            <div v-show="name_dependent_identification==''" class="relative pt-1">
              <div class="flex mb-2 items-center justify-between">
                <div class="text-right">
                  <span class="text-xs font-semibold inline-block text-teal-600 ">
                    {{progress +'%'}}
                  </span>
                </div>
              </div>
              <div class="overflow-hidden h-4 mb-4 text-xs flex rounded bg-green-200">
                <div :style="{ width: progress + '%'}" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"></div>
              </div>
            </div>
          </div>
          <div class="w-8/12 sm:w-3/12  p-4  mb-1">
            <p id="name_dependent_identification" class="leading-6 text-sm text-orange-500 mb-5">{{name_dependent_identification}}</p>
          </div>
          <div class="w-4/12 sm:w-4/12  p-4  mb-1">
            <input type="button" id="btn_load_dependent_identification" class="text-gray-500 bg-white p-2 space-y-2 shadow-md rounded border-gray-900" value="Seleccionar archivo" v-on:click="getFile('dependent_identification')" />
            <input type="file" id="dependent_identification" accept=".pdf,.png,.PNG,.jpeg" @change="onFileSelected($event,'dependent_identification')" name="dependent_identification" class="hidden">
          </div>
        </div>
      </div>
      <div v-for="documentType in documentsType" :key="documentType" class="showInSmallScreens flex flex-wrap bg-white shadow overflow-hidden sm:rounded-lg max-w-6xl mx-auto mb-10">
        <div class="w-8/12 pl-4 pt-4  mb-1">
          <div role="form">
            <p id="document" class="text-xs  leading-6 font-bold text-gray-700 mb-2">Documento</p>
            <hr />
            <p id="document_type_dependent_identification" class="mt-2 leading-6 text-sm text-gray-500 mb-5">{{getNameDocument(documentType)}}</p>
          </div>
        </div>
        <div class="w-4/12 pt-4 pr-4">
          <p id="status" class="text-xs leading-6 font-bold text-gray-700 mb-2">Estatus</p>
          <hr class="pt-2"/>
          <Check v-show="'name_'+documentType" /> 
          <div v-show="'name_'+documentType == ''" class="relative pt-1">
            <div class="flex mb-2 items-center justify-between">
              <div class="text-right">
                <span class="text-xs font-semibold inline-block text-teal-600 ">
                  {{progress +'%'}}
                </span>
              </div>
            </div>
            <div class="overflow-hidden h-4 mb-4 text-xs flex rounded bg-green-200">
              <div :style="{ width: progress + '%'}" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"></div>
            </div>
          </div>
        </div>
        <div class="w-6/12 pl-4 pt-0 mb-1">
          <p id="document_name" class="text-xs leading-6 font-bold text-gray-700 mb-2">Nombre del archivo</p>
          <hr />
          <p :id="'name_'+documentType" class="leading-6 text-xs text-orange-500 mb-5"></p>
        </div>
        <div class="w-6/12  pt-2 pr-4 pb-5">
          <br />
          <hr class="mb-2" />
          <div class=" flex justify-center">
            <input type="button" :id="'btn_load_'+documentType" class="text-gray-500 bg-white text p-2  text-xs shadow-md rounded border-gray-900" value="Seleccionar archivo" v-on:click="getFile(documentType)" />
            <input type="file" :id="documentType" accept=".pdf,.png,.PNG,.jpeg" @change="onFileSelected($event,documentType)" :name="documentType" class="hidden">
          </div>
        </div>
      </div>
      <div class="bg-white  overflow-hidden  max-w-6xl mx-auto">
        <div class="flex  sm:justify-end  sm:mt-5 sm:mb-5">
          <span class=" rounded-md shadow-sm  w-full sm:w-4/12">
            <button type="button" id="btn_quote" @click="validateStep()" class=" w-full inline-flex items-center justify-center px-6 py-3 border border-orange-500 text-base leading-6 font-medium rounded-md text-white bg-orange-500 hover:bg-white hover:text-orange-500 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition ease-in-out duration-150">
              Realizar otra cotización
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Check from '@/components/loading/VueCheck.vue'
import Swal from "sweetalert2";
import {
  ref,
  inject,
  onMounted,
  getCurrentInstance
} from "vue";

import {
  useRouter
} from 'vue-router';
import axios from "axios";
export default {
  name: "PaymentCompleted",
  components: {
    Check
  },
  setup() {
    onMounted(async () => {
      getName();
    })
    //const internalInstance = getCurrentInstance();
    const ccapi = process.env.VUE_APP_CCAPI;
    const router = useRouter();
    const app = getCurrentInstance()

    const moment = app.appContext.config.globalProperties.$moment;
    const lifeIssuanceResponseParams = inject('lifeIssuanceResponseParams');
    const step = inject("step");
    const selectedProducts = inject('selectedProducts');
    const issuedProducts = inject('issuedProducts');
    const products = inject('products');
    const aspects = inject('aspects');
    const categories = inject('categories');
    const arrayAnswers = inject('arrayAnswers');
    const issuanceParams = inject('issuanceParams');
    const contCategory = inject('issuanceParams');
    const isLoading = inject('isLoading');

    /*const loader = inject('loader');
    loader.value = "dots";*/
    const name_holder_identification = ref(null);
    const name_payment = ref(null);
    const name_medical_exclamation_letter = ref(null);
    const name_recognition_antiquity = ref(null);
    const name_mutuus_contract = ref(null);
    const name_identification_card = ref(null);
    const name_others = ref(null);
    const name_dependent_identification = ref(null);
    const progress = ref(0);
    const fullname = ref("");
    const documentsType = ref([
      "holder_identification",
      "payment",
      "medical_exclamation_letter",
      "recognition_antiquity",
      "mutuus_contract",
      "identification_card",
      "others",
      "dependent_identification"
    ]);
   

    function getName() {
      arrayAnswers.value.forEach(answer => {
        if (answer.question.toUpperCase().includes("NOMBRE")) {
          fullname.value.concat(answer.answer);
        }
        if (answer.question.toUpperCase().includes("APELLIDO PATERNO")) {
          fullname.value.concat(answer.answer);
        }
        if (answer.question.toUpperCase().includes("APELLIDO MATERNO")) {
          fullname.value.concat(answer.answer);
        }
      });
    }

    function getFile(document_type) {
      document.getElementById(document_type).value = "";
      Swal.fire({
          title: "Aviso",
          text: '¿Desea cargar ' + getNameDocument(document_type) + '?',
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: '#FEB72B',
          confirmButtonText: "SI",
          cancelButtonText: "NO"
        })
        .then((result) => {
          if (result.value) {
            console.log(document_type);
            document.getElementById(document_type).click();
          }
        });
    }

    function getNameDocument(document_type) {
      let document = "";
      switch (document_type) {
        case "holder_identification":
          document = "Identificación del titular";
          break;
        case "payment":
          document = "Comprobante de pago";
          break;
        case "medical_exclamation_letter":
          document = "Carta aclaratoria";
          break;
        case "recognition_antiquity":
          document = "Reconocimiento de antiguedad";
          break;
        case "mutuus_contract":
          document = "Contrato de MUTUUS";
          break;
        case "identification_card":
          document = "Cédula fiscal";
          break;
        case "dependent_identification":
          document = "Identificación de dependientes";
          break;
        case "others":
          document = "Otros";
          break;
        default:
          document = "el documento"
          break;
      }
      return document;
    }

    function validateStep() {
      issuedProducts.value.push(issuanceParams.value.product); //productos emitidos
      if (selectedProducts.value.length > 1) { //si se selecciono mas de un producto
        if (issuedProducts.value.length > 0) { //si hay productos emitidos
          for (let item of selectedProducts.value) {
            const found = issuedProducts.value.find(element => element == item);
            if (!found) {
              step.value = 5; //si el producto seleccionado no esta emitido, se regresa a la cotización
              router.push({
                name: "Quotation" //"Questions"
              });
              break;
            } else {
              step.value = 1; //se regresa a cotizar
              router.push({
                name: "Quotation"
              });
            }
          } //);
        } else {
          //ningun producto a sido emitido
          step.value = 5; // se regresa a la cotización
        }
      } else {
        step.value = 1; //se regresa a cotizar
        aspects.value = [];
        products.value = [];
        categories.value = [];
        arrayAnswers.value = [];
        contCategory.value = 0;
      }
    }

    function percentage() {
      var intval = setInterval(() => {
        if (progress.value < 100)
          progress.value += 5;
        else
          clearInterval(intval);
      }, 100);
    }

    function onFileSelected(event, document_type) {
      console.log("document_tyoe ", document_type);
      var d = document.getElementById("fileList");
      if (!document_type.length) {
        d.innerHTML = "<p>¡No se han seleccionado archivos!</p>";
      }
      const file = event.target.files[0];
      console.log(file);
      console.log(file.type);
      const formData = new FormData();
      formData.append("policy_request_id", 1);
      formData.append(document_type, file);

      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
      setNameDocument(document_type, "");
      percentage();
      axios.post(ccapi + '/anter/mutuus/register_request_document', formData, config).then((response) => {
          console.log("response!! ", response);
          Swal.fire({
            title: "Aviso",
            text: "Archivo subido con éxito.",
            icon: "info",
          });
          setNameDocument(document_type, file.name);
        }).catch((error) => {
          console.log("Se presento un error: ", error);
          Swal.fire({
            title: "Aviso",
            text: "Ocurrió un error al subir " + getNameDocument(document_type) + ". Por favor intente de nuevo.",
            icon: "error",
          });
          setNameDocument(document_type, null);
        })
        .finally(() => {})
    }

    function setNameDocument(document_type, name) {
      switch (document_type) {
        case "holder_identification":
          name_holder_identification.value = name;         
          break;
        case "payment":
          name_payment.value = name;
          break;
        case "medical_exclamation_letter":
          name_medical_exclamation_letter.value = name;
          break;
        case "recognition_antiquity":
          name_recognition_antiquity.value = name;
          break;
        case "mutuus_contract":
          name_mutuus_contract.value = name;
          break;
        case "identification_card":
          name_identification_card.value = name;
          break;
        case "others":
          name_others.value = name;
          break;
        case "dependent_identification":
          name_dependent_identification.value = name;
          break;

      }
      progress.value = 0;
    }

    return {
      step,
      lifeIssuanceResponseParams,
      isLoading,
      validateStep,
      onFileSelected,
      moment,
      getFile,
      name_holder_identification,
      name_payment,
      name_medical_exclamation_letter,
      name_recognition_antiquity,
      name_mutuus_contract,
      name_identification_card,
      name_others,
      name_dependent_identification,
      progress,
      fullname,
      documentsType,
      getNameDocument,
    }
  }
}
</script>

<style>
@media only screen and (max-width: 650px) {
  .hideInSmallScreens {
    display: none;
  }
}

@media only screen and (min-width: 650px) {
  .showInSmallScreens {
    display: none;
  }
}

hr {
  border-top: 2px solid #3d3b3b;
}
</style>
