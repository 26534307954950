<template>
  <loading v-show="isLoading" :enforce-focus="false" :active="true" :can-cancel="false" :color="'#bfd243'" :height="100" :width="350" :is-full-page="false" :loader="loader">
  </loading>
  <div v-show="!isLoading" style="min-height: 640px;">
    <div
      class="h-screen flex overflow-hidden bg-white"
      x-data="{ sidebarOpen: false }"
      keydown.window.escape="sidebarOpen = false"
    >
      <side-bar-menu :key="keySideBarDesktop" />
      <div
        x-init="$el.focus()"
        class="flex-1 overflow-auto focus:outline-none"
        tabindex="0"
      >
        <side-bar-menu-mobile :key="keySideBarMobile" />
        <main class="flex-1 relative pb-8 z-0 overflow-y-auto bg-gray-50">
          <header-component ref="dashboardHeader" :userInfo="userInfo" :key="keyHeader" />
          <div class="mt-8 max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <!-- User Information -->
            <div
              class="bg-white overflow-hidden shadow rounded-lg flex flex-col justify-betweeen col-span-3 p-4"
            >
              <div class="w-full max-w-4xl mx-auto my-9">
                <div class="flex flex-wrap -mx-3 mb-6">
                  <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Nombres
                    </label>
                    <input
                      class="block w-full py-3 px-4 mb-3 leading-tight form-input py-3 px-4 block w-full transition ease-in-out duration-150"
                      id="grid-first-name"
                      type="text"
                      placeholder="Nombres"
                      v-model="customerInfo.names"
                      disabled
                    />
                  </div>
                  <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Apellido paterno
                    </label>
                    <input
                      class="block w-full py-3 px-4 mb-3 leading-tight form-input py-3 px-4 block w-full transition ease-in-out duration-150"
                      id="grid-first-name"
                      type="text"
                      placeholder="Apellido paterno"
                      v-model="customerInfo.lastName"
                      disabled
                    />
                  </div>
                </div> 

                <div class="flex flex-wrap -mx-3 mb-6">
                  <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Apellido materno
                    </label>
                    <input
                      class="block w-full py-3 px-4 mb-3 leading-tight form-input py-3 px-4 block w-full transition ease-in-out duration-150"
                      id="grid-first-name"
                      type="text"
                      placeholder="Apellido materno"
                      v-model="customerInfo.maternalLastName"
                      disabled
                    />
                  </div>

                  <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      RFC
                    </label>
                    <input
                      class="block w-full py-3 px-4 mb-3 leading-tight form-input py-3 px-4 block w-full transition ease-in-out duration-150"
                      id="grid-first-name"
                      type="text"
                      placeholder="RFC"
                      v-model="customerInfo.rfc"
                      disabled
                    />
                  </div>
                </div>

                <div class="flex flex-wrap -mx-3 mb-6">
                  <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-city"
                    >
                      Email
                    </label>
                    <input
                      class="block w-full py-3 px-4 mb-3 leading-tight form-input py-3 px-4 block w-full transition ease-in-out duration-150"
                      id="grid-email"
                      type="email"
                      placeholder="anter@anter.com.mx"
                      v-model="customerInfo.email"
                      disabled
                    />
                  </div>

                  <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-state"
                    >
                      Teléfono
                    </label>
                    <input
                      class="block w-full py-3 px-4 mb-3 leading-tight form-input py-3 px-4 block w-full transition ease-in-out duration-150"
                      id="grid-telephone"
                      type="telephone"
                      placeholder="644 00 0000"
                      v-model="customerInfo.phone"
                    />
                    <!-- <div class="input-errors" v-for="error of v1$.telefono.$errors" :key="error.$uid">
                      <div class="error-msg text-red-400">{{ error.$message }}</div>
                    </div> -->
                  </div>
                </div>

                <div class="flex flex-wrap -mx-3 mb-6">
                  <div class="w-full px-3 md:px-1 lg:px-3  xl:px-3 mx-auto items-center text-center">
                    <button
                      class="btn-save inline-flex items-center px-3 py-2 text-white border-4 rounded mx-auto shadow-md"
                      @click="submitFormCustomerInfo"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4" />
                      </svg>
                      Actualizar telefono
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- End Form 1 -->
          </div>
        </main>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderComponent from "@/components/customer/HeaderComponent.vue";
import SideBarMenu from '@/components/customer/SideBarMenu';
import SideBarMenuMobile from '@/components/customer/SideBarMenuMobile';

import Swal from 'sweetalert2';
import { reactive } from "vue";
// import useVuelidate from "@vuelidate/core";
// import { required, helpers } from "@vuelidate/validators";

import { ref, onMounted } from "@vue/runtime-core";

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Customer from '@/classes/customer.js';
import User from '@/classes/User.js';
// import { getUserAuthInfo } from '@/helpers/auth.js';
// import { hasSpecialcharacters, hasLetters } from '@/helpers/text.js';
import Img from '@/assets/images/dashboard/circle_transparent.png';

export default {

  setup(){
    const loadingImage = ref(true);
    const keyHeader = ref(Math.random());
    const keySideBarDesktop = ref(Math.random());
    const keySideBarMobile = ref(Math.random());
    
    const dashboardHeader = ref(null);

    const customerInfo = reactive({
      names: "",
      lastName: "",
      maternalLastName: "",
      email: "",
      phone: "",
      rfc: ""
    });

    const formPassword = reactive({
      actualPassword: "",
      password: "",
      passwordConfirm: ""
    });
  
    const isCameraOpen = ref(false);
    const isPhotoTaken = ref(false);
    const isShotPhoto = ref(false);
    const isLoading = ref(true);
    const link = ref("#");
    const photo = ref("");
    
    const loadProfile = async () => {
      isLoading.value = true;
      var customer = new Customer();
      customer.getProfile().then(response => {
        console.log(response.data);
        customerInfo.names = response.data.respuesta.informacion.datos_generales.nombres;
        customerInfo.lastName = response.data.respuesta.informacion.datos_generales.apellido_paterno;
        customerInfo.maternalLastName = response.data.respuesta.informacion.datos_generales.apellido_materno;
        customerInfo.rfc = response.data.respuesta.informacion.datos_generales.rfc;
        customerInfo.email = response.data.respuesta.informacion.contacto.email;
        customerInfo.phone = response.data.respuesta.informacion.contacto.telefono;
  
        isLoading.value = false;
      }).catch(err => {
        console.log(err.errors);
        isLoading.value = false;
      });
    }

    onMounted(() => {
      loadProfile();
    });

    return {
      customerInfo,
      
      formPassword,
      isCameraOpen,
      isPhotoTaken,
      isShotPhoto,
      isLoading,
      link,
      photo,
      Loading,
      dashboardHeader,
      keyHeader,
      keySideBarDesktop,
      keySideBarMobile,
      Img,
      loadingImage
    };
  },

  components: {
    SideBarMenu,
    SideBarMenuMobile,
    HeaderComponent,
    Loading
  },

  methods: {
    submitFormCustomerInfo() {
      this.isLoading = true;
      console.log("Success");
      
      User.updateUserInfo(
        this.customerInfo.names,
        this.customerInfo.lastName,
        this.customerInfo.maternalLastName, 
        this.customerInfo.email,
        this.customerInfo.phone
        
      ).then(resp => {
        this.isLoading = false;

        Swal.fire({
          title: "Actualización exitosa",
          text: "Se ha actualizado la información de su perfil correctamente",
          icon: "success",
        });

        var customer = new Customer();
        const usuario = customer.getProfile();

        usuario.persona.nombres = this.customerInfo.names ;
        usuario.persona.apellido_paterno = this.customerInfo.lastName ;
        usuario.persona.apellido_materno = this.customerInfo.maternalLastName ; 
        usuario.email = this.customerInfo.email ;
        usuario.persona.telefono = this.customerInfo.phone ; 
        
        sessionStorage.setItem('login', JSON.stringify(usuario));

        this.keyHeader = Math.random();
        this.keySideBarDesktop = Math.random();
        this.keySideBarMobile = Math.random();

        console.log(resp);
      }).catch(err => {
        this.isLoading.value = false;
        Swal.fire({
          title: "Actualización fallida",
          text: "Ha ocurrido un problema, por favor intente más tarde",
          icon: "error",
        });
        console.log(err);
      });

      
    },

    // submitFormPassword() {
      
    //   this.v2$.$validate();
    //   if (!this.v2$.$error) {
    //     this.isLoading = true;
       
    //     User.changePasswordDashboard(this.formPassword.password, this.formPassword.passwordConfirm).then(res => {
          
    //       this.isLoading = false;
    //       Swal.fire({
    //         title: "Actualización exitosa",
    //         text: "Se actualizó la contraseña correctamente",
    //         icon: "success",
    //       });

    //       this.formPassword.password = "";
    //       this.formPassword.passwordConfirm = "";
    //       console.log(res);
    //     }).catch(err => {
    //       this.isLoading = false;

    //       Swal.fire({
    //         title: "Actualización fallida",
    //         text: "Ha ocurrido un problema, por favor intente más tarde",
    //         icon: "error",
    //       });
          
    //       console.log(err);
    //     });
    //     console.log("Success");
    //   } else {
    //     console.log("Failded");
    //   }
    // }
  },
};
</script>
<style>
  .toggle-checkbox:checked {
    @apply: right-0 border-green-400;
    right: 0;
    border-color: #68d391;
  }
  .toggle-checkbox:checked + .toggle-label {
    @apply: bg-green-400;
    background-color: #68d391;
  }
  .btn-red:hover{
    background: #EE5835;
  }
  video{
    border-color: #EE5835;
  }
  .btn-save {
    background: #EE5835;
    border-color: #EE5835;
  }
  .btn-save:hover {
    background: #e9866e;
    border-color: #e9866e;
    color: white;
  }
</style>