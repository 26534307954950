<template>
  <h3>V. LIMITACIONES EN EL USO Y DIVULGACIÓN DE SUS DATOS PERSONALES</h3>
  <p>
    Usted tiene el derecho de acceder a sus Datos Personales que poseemos y a
    los detalles del tratamiento de los mismos, así como a rectificarlos en caso
    de ser inexactos o bien cancelar su uso a través del formulario de Acceso,
    Rectificación, Cancelación u Oposición (ARCO), solicitándolo en la siguiente
    dirección de correo electrónico contacto@anter.com.mx donde se les
    informarán los procedimientos a seguir.
  </p>
</template>
