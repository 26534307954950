<template>
  <div class="py-16 overflow-hidden lg:py-24">
    <div class="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
      <dot-pattern-right/>

      <div id="trigger-second-services" class="relative lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center" >
        <div class="text-base max-w-prose mx-auto">
          <h1 class="mt-2 mb-8 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            AXA Keralty
          </h1>
          <div class="prose text-gray-500 text-lg">
            <p>Fieles a nuestro compromiso de protección y brindar el mejor servicio, nos hemos aliado a <b>Axa Keralty</b> 
              para ofrecerle un nuevo plan de protección en dos modalidades; Óptimo e Híbrido.</p>
          </div>
        </div>

        <div class="mt-10 -mx-4 relative lg:mt-0">
          <img class="relative mx-auto" width="490" data-aos="fade-left" data-aos-duration="1000" data-aos-delay="100" data-aos-anchor-placement="top-center" src="@/assets/images/ilustraciones/ilustraciones_online.svg" alt=""/>
        </div>
      </div>

      <dot-pattern-left/>

      <benefits/>
    </div>
  </div>
</template>
<script>
import DotPatternRight from '@/components/common/DotPatternRight.vue';
import DotPatternLeft from '@/components/common/DotPatternLeft.vue';
import Benefits from '@/components/health/Benefits.vue';
export default {
  components:{
    DotPatternRight,
    DotPatternLeft,
    Benefits
  },
  setup() {
    
  },
}
</script>

