<template>
	<div :class="open ? 'policy open' : 'policy'" @click="showInfo(policy.receipt, index), $emit('toggleOpen', index)">
		<div class="name">{{ policy.name }}</div>
		<div :id="`divReceipt_${index}`"  class="receipt">{{ policy.receipt }}</div>
	</div>
</template>

<script>
import { onMounted } from "@vue/runtime-core";
import Customer from '@/classes/customer.js';
export default {
	name: 'Receipt',
	props: ['policy', 'index', 'open'],
  setup(){
    onMounted(async () => {
    });
    function showInfo(record, index) {
      console.log(record.value);
      Customer.setInfo(record, index);
    }

    return{
      showInfo
    }
  }
}
</script>