<template>
  <div class="py-0 bg-white mt-24">
    <div class="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-screen-xl lg:px-8">
      <div class="lg:grid lg:grid-cols-3 lg:gap-8">
        <div data-aos="fade-up" data-aos-anchor-placement="top-top" data-aos-anchor="#trigger-benefits">
          <div class="flex items-center justify-center h-12 w-12 rounded-md">
            <img src="@/assets/icons/1_disponibilidad.svg"/>
          </div>
          <div class="mt-5">
            <h5 class="text-lg leading-6 font-medium text-gray-900">
              Disponible para ti, cuando lo necesites
            </h5>
            <p class="mt-2 text-base leading-6 text-gray-500">
              Nuestra plataforma ha simplificado el proceso de compra de tus
              seguros, dejamos de lado las complicaciones de acudir a diferentes
              lugares para proteger los aspectos importantes de tu vida, ANTER
              te ofrece la manera más fácil de asegurar tu auto, tu vida y tu
              salud.
            </p>
          </div>
        </div>
        <div class="mt-10 lg:mt-0" data-aos="fade-up" data-aos-anchor-placement="top-top" data-aos-anchor="#trigger-benefits" data-aos-delay="100">
          <div class="flex items-center justify-center h-12 w-12 rounded-md">
            <img src="@/assets/icons/1_compativilidad.svg"/>
          </div>
          <div class="mt-5">
            <h5 class="text-lg leading-6 font-medium text-gray-900">
              Amigables con cualquier dispositivo
            </h5>
            <p class="mt-2 text-base leading-6 text-gray-500">
              En ANTER llevamos tu experiencia de compra fuera de tu
              computadora, nuestro sitio está optimizado para ser visto desde
              celular o tablet, dándole la oportunidad a nuestros clientes de
              asegurarse donde sea que se encuentren, solo con una conexión a
              internet.
            </p>
          </div>
        </div>
        <div class="mt-10 lg:mt-0" data-aos="fade-up" data-aos-anchor-placement="top-top" data-aos-anchor="#trigger-benefits" data-aos-delay="200">
          <div class="flex items-center justify-center h-12 w-12 rounded-md">
            <img src="@/assets/icons/1_seguridad.svg"/>
          </div>
          <div class="mt-5">
            <h5 class="text-lg leading-6 font-medium text-gray-900">
              La experiencia de asegurar los aspectos de tu vida en un solo
              lugar y en menos de 10 minutos
            </h5>
            <p class="mt-2 text-base leading-6 text-gray-500">
              En ANTER sabemos que el tiempo es algo valioso, por ello hemos
              sumado esfuerzos para ofrecer a nuestros clientes una forma rápida
              y segura de proteger todos los aspectos de su vida.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <providers/>
</template>

<script>
import Providers from "@/components/common/Providers.vue";

export default {
  components: {
    Providers,
  },
  setup() {},
};
</script>
