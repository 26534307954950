<template>
  
  <div class="relative px-4 sm:px-6 lg:px-8">
    
    <div class="text-lg max-w-prose mx-auto mb-6">
      <h1 class="mt-2 mb-8 text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
        Aviso de privacidad
      </h1>
      <p class="text-xl text-gray-500 leading-8">
        ANTER SEGUROS, AGENTE DE SEGUROS Y DE FIANZAS S.A DE C. V. 
        en cumplimiento a la Ley Federal de Protección de Datos Personales 
        en Posesión de Particulares, informa los términos y condiciones siguientes:
      </p>
    </div>
    <div class="prose prose-lg text-gray-500 mx-auto">
      <h3>
        I. RESPONSABLE DE PRESERVAR Y GARANTIZAR LA CONFIDENCIALIDAD Y SEGURIDAD DE DATOS PERSONALES 
      </h3>
      <p>
        ANTER SEGUROS, AGENTE DE SEGUROS Y DE FIANZAS S.A DE C. V, (en lo 
        sucesivo Anter), con domicilio en calle Flavio Bórquez 1613, colonia Prados del
        Tepeyac, C.P. 85150 en Ciudad Obregón, Sonora, México, quien evitará su
        alteración, pérdida o acceso por terceros no autorizados. 
      </p>
      <personal-data/>
      <info-finality/>
      <data-transfer/>
      <use-limits/>
      <rights-request/>
      <privacy-updates/>

    </div>
  </div>
</template>
<script>
import PersonalData from '@/components/privacy/PersonalData';
import InfoFinality from '@/components/privacy/InfoFinality';
import DataTransfer from '@/components/privacy/DataTransfer';
import UseLimits from '@/components/privacy/UseLimits';
import RightsRequest from '@/components/privacy/RightsRequest';
import PrivacyUpdates from '@/components/privacy/PrivacyUpdates';

export default {
  components:{
    PersonalData,
    InfoFinality,
    DataTransfer,
    UseLimits,
    RightsRequest,
    PrivacyUpdates
  },
  setup() {
    
  },
}
</script>

