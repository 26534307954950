<template>
  <div class="px-4 sm:px-6 lg:px-8 absolute left-0  ml-2 xl:ml-32 hidden lg:block" v-show="step < 13">
				<nav class="flex justify-center">
					<ul class="space-y-6" >
						<li v-for="(category,index) of categories" :key="category.id">
								<!-- Current Step -->
								<div class="flex items-start space-x-3">
										<div class="flex-shrink-0 h-5 w-5 relative flex items-center justify-center">
												<span :class="{'animation_ping' : index == contCategory }"></span>
												<span class="ping" :class="{'ping_active': index == contCategory , 'ping_inactive' : index != contCategory }"></span>
										</div>
										<p  class="capitalize" :class="{'inactive': index > contCategory , 'current_step' : index == contCategory , 'complete_step' : index < contCategory }">
                    {{category.name == 'PERSONALES' || category.name == 'EMISION' ? category.name.toLowerCase() : currentProductName }}</p>
								</div>
						</li>
					</ul>
				</nav>
		</div>
</template>
<script>
import { inject, onUpdated, computed } from '@vue/runtime-core';
import {onMounted,ref} from "vue";
export default {
  name: "QuoterNav",
  components: {},
  setup() {
    const currentProductName = inject('currentProductName');//category.steps[contSteps].questions[0].productName
    const contCategory = inject('contCategory');
    const contSteps = inject('contSteps');
    const step = inject('step');
    const categories = inject('categories');
    const aspects = inject('aspects');
    const showBasicInfo = ref(0)
    const dataQuestions = ref({})
    const arr = ref([]);
    const classList = computed(()=>{
      if (contCategory) {
            return 'complete_step' ;
        }
    })
    //console.log("categories:Navbar ",categories);
    onMounted(() => {
    });
    onUpdated(()=> {
      //console.log("NavBar: ",contSteps);
    })

    
    /*function getQuestionsBySelectedProducts(){
      let cont = 0;
      console.log("PROPS: ",props.questions.length)

			if(props.questions.length > 0){
        arr.value = []
				for (let i =0 ; i < props.questions.length; i++){
          console.log("props.questions[i].response: ",props.questions[i].response)
					props.questions[i].response.forEach(product => {
						//if(product.id == e){
              console.log("showBasicInfo: ",product);
						//}
					})
          cont++;
        }
      }
              console.log("showBasicInfo: ",cont);

		}*/
 
   
    return{
      step,
      categories,
      showBasicInfo,
      aspects,
      dataQuestions,
      arr,
      classList,
      contCategory,
      contSteps,
      currentProductName
    }
  },
}
</script>
<style scoped>
  .current_step{
    @apply text-sm leading-5 font-medium text-red-500;
  }
  .complete_step{
    @apply line-through text-sm leading-5 font-medium text-gray-500 hover:text-gray-900 focus:text-gray-900 transition ease-in-out duration-150;
  }
  .inactive{
    @apply text-sm leading-5 font-medium text-gray-500 hover:text-gray-900 focus:text-gray-900 transition ease-in-out duration-150;
  }
  .animation_ping{
    @apply absolute h-4 w-4 rounded-full  bg-red-500 animate-ping ;
  }
  .ping{
    @apply relative block w-2 h-2 rounded-full;
  }
  .ping_inactive{
    @apply  bg-gray-300;
  }
  .ping_active{
    @apply bg-red-500 ;
  }
</style>