<template>

    <login-form/>
    <login-image/>

</template>
<script>
import LoginForm from '@/components/login/LoginForm';
import LoginImage from '@/components/login/LoginImage';

export default {
  components:{
    LoginForm,
    LoginImage
  }
}
</script>