<template>

    <confirm-account-form/>
    <confirm-account-image/>

</template>
<script>
import ConfirmAccountForm from '@/components/ConfirmAccount/ConfirmAccountForm';
import ConfirmAccountImage from '@/components/ConfirmAccount/ConfirmAccountImage';

export default {
  components:{
    ConfirmAccountForm,
    ConfirmAccountImage
  }
}
</script>