<template>
  <div style="min-height: 640px;">
    <div class="h-screen flex overflow-hidden bg-white" x-data="{ sidebarOpen: false }" keydown.window.escape="sidebarOpen = false">
      <dashboard-side-bar-menu/>
      <div x-init="$el.focus()" class="flex-1 overflow-auto focus:outline-none" tabindex="0">
        <dashboard-side-bar-menu-mobile/>
        <main class="flex-1 relative pb-8 z-0 overflow-y-auto bg-gray-50">
          <dashboard-header/>
          <div class="mt-8">
            <!-- Filters -->
            <div class="max-w-6xl mx-auto px-4 px-6 py-6 md:py-4 lg:py-4 xl:py-4 lg:px-8 text-center md:text-center lg:text-right xl:text-right">
              <span class="shadow-sm rounded-md mx-1">
                <button @click="setDateRange('weeks')" type="button" :class="{ 'bg-orange-600 text-white': filterActive.weeks, 'bg-white text-orange-600': !filterActive.weeks }" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md hover:bg-orange-500 hover:text-white border-current border-orange-600 focus:outline-none focus:shadow-outline-orange focus:border-orange-700 active:bg-orange-700 transition duration-150 ease-in-out">
                  Semanal
                </button>
              </span>
              <span class="shadow-sm rounded-md mx-1">
                <button @click="setDateRange('months')"  type="button" :class="{ 'bg-orange-600 text-white': filterActive.months, 'bg-white text-orange-600': !filterActive.months }" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md hover:bg-orange-500 hover:text-white border-current border-orange-600 focus:outline-none focus:shadow-outline-orange focus:border-orange-700 active:bg-orange-700 transition duration-150 ease-in-out">
                  Mensual
                </button>
              </span>
              <span class="shadow-sm rounded-md mx-1">
                <button @click="setDateRange('years')"  type="button" :class="{ 'bg-orange-600 text-white': filterActive.years, 'bg-white text-orange-600': !filterActive.years }" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md hover:bg-orange-500 hover:text-white border-current border-orange-600 focus:outline-none focus:shadow-outline-orange focus:border-orange-700 active:bg-orange-700 transition duration-150 ease-in-out">
                  Anual
                </button>
              </span>
            </div>
            <!-- End Filters -->
            <!-- Graphs -->
            <dashboard-summary ref="dashboardSummary" :rangeType="rangeType" />

           <!-- Activities -->
           
          </div>
        </main>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardSideBarMenu from '@/components/dashboard/DashboardSideBarMenu';
import DashboardSideBarMenuMobile from '@/components/dashboard/DashboardSideBarMenuMobile';
import DashboardHeader from '@/components/dashboard/DashboardHeader';
import DashboardSummary from '@/components/dashboard/summary/Summary';
// import DashboardQuotesTable from '@/components/dashboard/DashboardQuotesTable';
import { onMounted, ref } from '@vue/runtime-core';
// import Quote from '@/classes/quote.js';
// import { dateFormatForUserView, dateFormatForSystem  } from '@/helpers/date.js';
// import { currencyFilter  } from '@/helpers/currency.js';
export default {
  components:{
    DashboardSideBarMenu,
    DashboardSideBarMenuMobile,
    DashboardHeader,
    DashboardSummary,
  },
  setup() {
    
    const activities = ref([]);
    const columns = ref([]);
    const rangeType = ref("months");
    const dashboardSummary = ref(null);
    const filterActive = ref({
      'weeks': true,
      'months': false,
      'years': false
    });
    const tableTitle = ref({type: Object, default: function(){  return {display: false, text: ''} } });
    const tableLabel = ref({text: '', classes: ''});
    const setDateRange = (type) => {
      switch (type) {
        case 'years':
          filterActive.value.months = false;
          filterActive.value.weeks = false;
          filterActive.value.years = true;
          break;
        case 'months':
          filterActive.value.months = true;
          filterActive.value.weeks = false;
          filterActive.value.years = false;
          break;
      
        default:
          filterActive.value.months = false;
          filterActive.value.weeks = true;
          filterActive.value.years = false;
          break;
      }
      rangeType.value = type;
      dashboardSummary.value.setRangeDates();
    }
    // const getActivities = async (start, end) => {

    //   tableLabel.value.text = `De ${dateFormatForUserView(new Date(start))} a ${dateFormatForUserView(new Date(end))}`;
    //   tableLabel.value.classes = "text-right";

    //   const data = [];
    //   Quote.getQuoteAndSalesByRangeOfDate(dateFormatForSystem(new Date(start)), dateFormatForSystem(new Date(end))).then(res => {
       
    //     let response = res.response.data.activities;
    //     columns.value.push([ {title:'Actividad'}, {title:'Monto'}, {title:'Vendedor'}, {title:'Fecha'}]);
        
    //     response.forEach(activity => {
    //         data.push([
    //         `<td class="whitespace-no-wrap text-sm leading-8 text-cool-gray-900 border-b-2"> <a
    //           href="#"
    //           class="group inline-flex space-x-2 truncate text-sm leading-5 align-middle"
    //         >
    //           <svg
    //             class="
    //               flex-shrink-0
    //               h-5
    //               w-5
    //               text-cool-gray-400
    //               group-hover:text-cool-gray-500
    //               transition
    //               ease-in-out
    //               duration-150
    //             "
    //             x-description="Heroicon name: cash"
    //             xmlns="http://www.w3.org/2000/svg"
    //             viewBox="0 0 20 20"
    //             fill="currentColor"
    //           >
    //             <path
    //               fill-rule="evenodd"
    //               d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
    //               clip-rule="evenodd"
    //             ></path>
    //           </svg>
    //           <p
    //             class="
    //               text-cool-gray-500
    //               truncate
    //               group-hover:text-cool-gray-900
    //               transition
    //               ease-in-out
    //               duration-150
    //             "
    //           >
    //             ${activity.description}
    //             <span class="text-orange-400">#${activity.quote_number}</span>
    //           </p>
    //         </a></td>`,
    //         `<span class="uppercase">${currencyFilter(activity.amount, 'MXN')}</span>`,
    //         `<span class="text-right text-sm leading-8 text-cool-gray-500"><span class="bg-cool-gray-100 text-cool-gray-800 rounded-full px-1.5 py-1 text-xs font-medium">${activity.seller} </span>`,
    //         dateFormatForUserView(activity.date_quote.split('T')[0]),
    //       ]); 
    //     });
    //     activities.value = data;
    //     console.log(data);
    //   }).catch(err=> {
    //     console.log(err);
    //   });
    //   tableTitle.value = { display: true, text:`De ${ dateFormatForUserView(start)} a ${dateFormatForUserView(end)}`};
    // }
    // function getMonday ( type, dates) {
    //   var now = new Date();
    //   var nowTime = now.getTime();
    //   var day = now.getDay();
    //   var longTime = 24 * 60 * 60 * 1000;
    //   var n = longTime * 7 * (dates || 0);
    //   var dd;
    //   if (type == "s") {
    //     dd = nowTime - (day - 1) * longTime + n;
    //   }
    //   if (type == "e") {
    //     dd = nowTime + (7 - day) * longTime + n;
    //   }
    //   dd = new Date(dd);
    //   var y = dd.getFullYear();
    //   var m = dd.getMonth() + 1;
    //   var d = dd.getDate();
    //   m = m < 10 ? "0" + m: m;
    //   d = d < 10 ? "0" + d: d;
    //   day = y + "-" + m + "-" + d;
    //   return day;
    // }
    onMounted(() => {
      // let dateStart = getMonday("s", 0);
      // let dateEnd = getMonday("e", 0);
      // getActivities(dateStart, dateEnd);
    });
    return {
      activities,
      columns,
      setDateRange,
      rangeType,
      dashboardSummary,
      filterActive,
      tableTitle,
      tableLabel
    };
  },
}
</script>