<template>
<div>
  <div class="vld-parent">
    <loading :active="isLoading" :can-cancel="false" :color="'#bfd243'" :height="120" :width="400" :is-full-page="true">
    </loading>
  </div>
  <div class="bg-orange-500 p-12 text-white mt-1">
    <div class="md:flex md:justify-between">
      <p class="text-2xl font-bold">Seguro para tu auto</p>
      
    </div>
  </div>
  <div class="bg-white">
    <div class="bg-white p-12 space-y-20 shadow-md rounded">
      <div class="md:flex items-center justify-center">
        <p class="text-4xl font-bold text-gray-700 mr-3">Gracias por tu compra {{clientName}}</p>
        <div class="flex justify-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-16  w-16     text-orange-500" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
          </svg>
        </div>
      </div>
      <div class="bg-white shadow overflow-hidden sm:rounded-lg max-w-lg mx-auto">
        <div class=" px-4 py-5 sm:p-0">
          <dl class="sm:divide-y sm:divide-gray-200">
            <!-- <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">
                Aseguradora
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {{providerName}}
              </dd>
            </div> -->
            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500"> 
                Aseguradora 
              </dt> 
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"> 
                {{providerName}} 
              </dd> 
            </div>
            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">
                Póliza
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {{policyNumber}}
              </dd>
            </div>
            <div id="div_follow" class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">
                Número de seguimiento
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {{trackingNumber}}
              </dd>
            </div>
            <div id="div_confirmation" class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">
                Número de confirmación </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {{confirmationNumber}}
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <div class="space-y-6">
        <div class="flex justify-center">
          <p class="text-center max-w-md">Estimado cliente, hemos enviado tu póliza a tu correo electrónico, no olvides revisar que tus datos esten correctos.</p>
        </div>
        <div class="flex justify-center">
          <p class="text-center max-w-md">Cualquier duda escribenos a clientes@anter.com.mx</p>
        </div>
      </div>
      <!-- <div class="flex justify-center">
        <div class="rounded-md shadow">
          <a href="#" id="router_download" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-orange-500 hover:bg-orange-400 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10" />
            </svg>
            Descargar
          </a>
        </div>
      </div> -->
      <div class="flex justify-end  mt-16">
        <span class=" rounded-md shadow-sm">
          <button type="button" id="btn_quote" @click="validateStep()" class="w-full inline-flex items-center justify-center px-6 py-3 border border-orange-500 text-base leading-6 font-medium rounded-md text-orange-500 bg-white hover:bg-orange-500 hover:text-white focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition ease-in-out duration-150">
            Realizar otra cotización
          </button>
        </span>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  useRouter
} from 'vue-router';
import {
  inject,
  ref,
  onMounted
} from "vue";
import Loading from 'vue-loading-overlay';
//import axios from "axios";
export default {
  name: "PaymentCompleted",
  components: {
    Loading
  },
  
  setup() {
    const router = useRouter();
    const paymentResponse = inject('paymentResponse');
    const trackingNumber = null;
    const confirmationNumber = null;
    const quoteParams = inject('quoteParams');
    const isLoading = inject('isLoading');
    
    const clientName = inject('clientName');
    const policyNumber = inject('policyNumber');
    const providerName = ref(""); 
    const issuanceResponseParams = inject('issuanceResponseParams')
    const step = inject("step");
    // const selectedProducts = inject('selectedProducts');
    // const issuedProducts = inject('issuedProducts');
    // const products = inject('products');
    // const aspects = inject('aspects');
    // const categories = inject('categories');
    // const arrayAnswers = inject('arrayAnswers');
    // const contCategory = inject('contCategory');
    // const contSteps = inject('contSteps');
    // const currentCategoryName = inject('currentCategoryName');


    onMounted(async () => {
      var paramsString = "q=URLUtils.searchParams&"+window.location.search
      const urlParams = new URLSearchParams(paramsString)
      // this.urlParams.get('file')
      // this.urlParams.get('json_file')
      
      if(urlParams.get('?Pol') != null){
        providerName.value = "Gnp";
        policyNumber.value = urlParams.value.get('?Pol')
        trackingNumber.value = urlParams.value.get('Seg')
        confirmationNumber.value = urlParams.value.get('Con')
      }else{
        providerName.value = paymentResponse.value.proveedor; 
        policyNumber.value = paymentResponse.value.informacion_emision.numero_poliza;
        if(paymentResponse.value.proveedor == "Atlas"){
          policyNumber.value = "H14-1-1-"+paymentResponse.value.informacion_emision.numero_poliza; 
        }
        var follow = document.getElementById("div_follow")
        follow.style.display = "none";
        var confirmation = document.getElementById("div_confirmation")
        confirmation.style.display = "none";
      }
      
    })

    function validateStep() {
      router.push({
        name: "Quoter"
      });
      // issuedProducts.value.push(issuanceParams.value.product); //productos emitidos
      // if (selectedProducts.value.length > 1) {//si se selecciono mas de un producto
      //   if (issuedProducts.value.length > 0) { //si hay productos emitidos
      //     for (let item of selectedProducts.value) {
      //       const found = issuedProducts.value.find(element => element == item);
      //       if (!found) {
      //         //si el producto seleccionado no esta emitido, se regresa a la cotización
      //         router.push({
      //           name: "Quotation"
      //         });
      //         break;
      //       } else {
      //         currentCategoryName.value = "PERSONALES";
      //         router.push({
      //           name: "Quoter"
      //         }); //se regresa a cotizar
      //       }
      //     }
      //   }
      // } else {
      //   currentCategoryName.value = "PERSONALES";
      //   router.push({
      //     name: "Quoter"
      //   }); //se regresa a cotizar
      //   aspects.value = [];
      //   products.value = [];
      //   categories.value = [];
      //   arrayAnswers.value = [];
      //   contCategory.value = 0;
      //   step.value = 1;
      //   contSteps.value = 0;

      // }
    }

    return {
      step,
      issuanceResponseParams,
      quoteParams,
      isLoading,
      validateStep,
      policyNumber,
      trackingNumber,
      confirmationNumber,
      clientName,
      providerName
    }
  }
}
</script>
