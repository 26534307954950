<template>
 <!-- Card -->
  <div class="bg-white overflow-hidden shadow rounded-lg flex flex-col justify-between" >
    <div class="flex items-center p-6">
      <div class=" w-0 flex-1">
        <dl>
          <dt class="text-sm leading-5 font-medium text-cool-gray-500 truncate" >
            {{ title }}
          </dt>
          <dd>
            <div class="text-lg leading-7 font-medium text-cool-gray-900">
              {{ totalSales }}
            </div>
          </dd>
        </dl>
      </div>
      <div class=" w-0 flex-1">
        <dl>
          <dt class="text-sm leading-5 font-medium text-cool-gray-500 truncate" >
            {{ secondTitle }}
          </dt>
          <dd>
            <div class="text-lg leading-7 font-medium text-cool-gray-900">
              {{ totalSalesAmount }}
            </div>
          </dd>
        </dl>
      </div>
    </div>
    <div class="flex items-center p-6">
      <div class=" w-0 flex-1">
        <dl>
          <dt class="text-sm leading-5 font-medium text-cool-gray-500 truncate" >
            {{ thirdTitle }}
          </dt>
          <dd>
            <div class="text-lg leading-7 font-medium text-cool-gray-900">
              {{ totalIssuances }}
            </div>
          </dd>
        </dl>
      </div>
      <div class=" w-0 flex-1">
        <dl>
          <dt class="text-sm leading-5 font-medium text-cool-gray-500 truncate" >
            {{ fourthTitle }}
          </dt>
          <dd>
            <div class="text-lg leading-7 font-medium text-cool-gray-900">
              {{ totalIssuancesAmount }}
            </div>
          </dd>
        </dl>
      </div>
    </div>
    
  </div>
  <!-- End Card -->
</template>

<script>
import { 
  onMounted, onBeforeMount 
} from '@vue/runtime-core';
// import Chart from "chart.js";

export default {
  
  props: {
    'chartId': Number, 
    'title': String, 
    'secondTitle': String, 
    'thirdTitle': String, 
    'fourthTitle': String, 
    'totalSales': String, 
    'totalSalesAmount': String, 
    'totalIssuances': String,
    'totalIssuancesAmount': String,
    'chartTitle': {
      type: Object, 
      default: function(){  
        return {
          display: false, 
          text: ''
        } 
      } 
    }, 
    'data': {
      type:Object, 
      default: function(){ 
        return { 
          "labels": [], 
          "values": []
        } 
      }
    } 
  },

  components:{},

  setup(props) {
    // const totalQuotes = ref(10);
    // const createChart = async() => {
    //   await props.data
    //   showChart();
    // }

    // const showChart = () => {
    //   if(typeof props.data == "undefined"){
    //     return false;
    //   }
      
    //   var id = document.getElementById(props.chartId).getContext('2d');
    //   let chartLine = new Chart(id);
    //   chartLine.destroy();
    //   new Chart(id, {
    //     type: 'line',
    //     data: {
    //       labels: props.data.labels,
    //       datasets: [{
    //         backgroundColor: '#c1d5fccc',
    //         borderColor: '#5a8dee',
    //         data: props.data.values
    //       }]
    //     },
    //     options: {
    //       title: props.chartTitle,
    //       legend: {
    //         display: false
    //       },
    //       tooltips: {
    //         callbacks: {
    //           label: function (tooltipItem) {
    //             return tooltipItem.yLabel;
    //           }
    //         }
    //       },
    //       scales: {
    //         xAxes: [{
    //           display: false
    //         }],
    //         yAxes: [{
    //           display: false
    //         }]
    //       }
    //     }
    //   });
    // }

    onBeforeMount(() => {
    });

    onMounted(async () => {
      // createChart();
      await props.data;
    });

    return {
      // createChart
    }
  }

}
</script>