<template>
  
  <div class="relative overflow-hidden">
    <questions/>
  </div>
</template>
<script>
import Questions from '@/components/faq/Questions.vue';

export default {
  components:{
    Questions
  },
  setup() {
    
  },
}
</script>
