<template>
  <main class="lg:relative">
    <div class="relative py-16 bg-white overflow-hidden" >
      <!-- <pattern-bg/> -->
      <div class="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24 mx-auto w-full">
        <contact-jumbotron/>
        <Contacto-Imagen/>
        <br>
        <contact-form/>
      </div>
      <contact-support/>
    </div>
  </main>
</template>
<script>
  // import PatternBg from '@/components/common/PatternBg';
  import ContactJumbotron from '@/components/contact/ContactJumbotron';
  import ContactForm from '@/components/contact/ContactForm';
  import ContactSupport from '@/components/contact/ContactSupport';
  import ContactoImagen from '@/components/contact/ContactoImagen';

  export default {
    name : 'ContactContainer',
    components:{
      ContactJumbotron,
      ContactForm,
      ContactSupport,
      ContactoImagen
      // PatternBg
    }
  }
</script>