
import axios from "axios"
export default class SendEmail {

  anterapi = process.env.VUE_APP_ANTERAPI;

  constructor() {
  }

  async sendEmail( process_type, title, body_mail, mailing_list, attachment_list) {
    let message = this.encryptBody(body_mail);
    return axios.post(this.anterapi + "/enviar_correo", {
          tipo_de_proceso: process_type,
          titulo: title,
          mensaje_encriptado: message,
          correos: mailing_list,
          lista_de_adjuntos: attachment_list
      })
      .then((response) => {
        console.log("respuesta clase js: ",response);
        return response;
      })
      .catch((error) => {
        console.log("Se presentó un error al enviar el correo clase js: ",error);
      });
  }

  async sendQuotationmail(email, quote_id) {
    return axios.post(this.anterapi + "/enviar_email_cotizaciones", {
        email: email,
        cotizacion_id: quote_id
      })
      .then((response) => {
        console.log("respuesta clase js: ",response);
        return response;
      })
      .catch((error) => {
        console.log("Se presentó un error al enviar el correo clase js: ",error);
      });
  }

  async sendPolicymail(email, issuance_id) {
    return axios.post(this.anterapi + "/enviar_email_poliza", {
        email: email,
        emision_id: issuance_id
      })
      .then((response) => {
        console.log("respuesta clase js: ",response);
        return response;
      })
      .catch((error) => {
        console.log("Se presentó un error al enviar el correo clase js: ",error);
      });
  }

  async sendRadamesRequest(car, prospect) {
    return axios.post(this.anterapi + "/radames/enviar_solicitud_cotizaciones", {
        auto: {
          tipo: car.type,
          modelo: car.year,
          marca: car.brand,
          submarca: car.sub_brand,
          descripcion: car.version,
          cobertura: car.coverage
        },
        prospecto: {
          nombre: prospect.name,
          email: prospect.email,
          telefono: prospect.phone,
          sexo: prospect.gender,
          codigo_postal: prospect.postal_code,
          fecha_nacimiento: prospect.birth_date
        }
      })
      .then((response) => {
        console.log("respuesta Radames API: ",response);
        return response;
      })
      .catch((error) => {
        return error.response;
        // console.log("Se presentó un error al enviar el correo Radames API: ",error);
      });
  }

  encryptBody(html) {
    const header = {
      "alg": "HS256",
      "typ": "JWT"
    }
    const encodedHeader = Buffer.from(JSON.stringify(header)).toString('base64')

    var data = {
      "html": html
    }

    const encodedPayload = Buffer.from(JSON.stringify(data)).toString('base64')
    var jwtSecret = "u3E*aWShrxOv";
    const crypto = require('crypto')
    const signature = crypto.createHmac('sha256', jwtSecret).update(encodedHeader + '.' + encodedPayload).digest('base64')
    return encodedHeader + "." + encodedPayload + "." + signature
  }

}