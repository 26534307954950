<template>
  <!-- modal agregar usuario -->
  <div class="modal" id="show-modal-active-user">
    <div
      class="z-10 w-11/12 sm:w-4/6 m-auto bg-white border-2 p-5 modal-active"
    >
      <div id="modal-header" class="flex">
        <div class="w-6/12">
          <h3 class="text-3xl font-medium leading-5 text-gray-700">
            Registrar usuario
          </h3>
        </div>
        <div class="w-6/12 text-right">
          <label
            class="
              text-gray-400 text-xl
              hover:text-gray-700
              transition
              duration-150
            "
            v-on:click="CloseModal()"
            >X</label
          >
        </div>
      </div>
      <div class="border-t-2 w-full mb-5 mt-5"></div>
      <div id="modal-body mb-5">
        <div class="block sm:flex">
          <div class="px-3 w-full mt-5">
            <label
              class="block text-sm font-medium leading-5 text-gray-700"
              :class="{ 'text-red-600': v$.nombres.$error }"
              >Nombres</label
            >
            <input
              v-model="userForm.nombres"
              type="text"
              class="
                form-input
                py-3
                px-4
                block
                w-full
                transition
                ease-in-out
                duration-150
              "
              :class="{ 'bg-red-100 border-red-400': v$.nombres.$error }"
              :state="v$.nombres.$dirty ? !v$.nombres.$error : null"
              id="input_name"
            />
            <div v-if="v$.nombres.$error" class="text-red-600">
              <span>
                {{ v$.nombres.$errors[0].$message }}
              </span>
            </div>
          </div>
        </div>
        <div class="block sm:flex">
          <div class="px-3 w-full mt-5">
            <label
              class="block text-sm font-medium leading-5 text-gray-700"
              :class="{ 'text-red-600': v$.apellido_paterno.$error }"
              >Primer Apellido</label
            >
            <input
              v-model="userForm.apellido_paterno"
              type="text"
              class="
                form-input
                py-3
                px-4
                block
                w-full
                transition
                ease-in-out
                duration-150
              "
              :class="{ 'bg-red-100 border-red-400': v$.apellido_paterno.$error }"
              id="input_paternal_name"
            />
            <div v-if="v$.apellido_paterno.$error" class="text-red-600">
              <span>
                {{ v$.apellido_paterno.$errors[0].$message }}
              </span>
            </div>
          </div>
          <div class="px-3 w-full mt-5">
            <label
              class="block text-sm font-medium leading-5 text-gray-700"
              :class="{ 'text-red-600': v$.apellido_materno.$error }"
              >Segundo Apellido</label
            >
            <input
              v-model="userForm.apellido_materno"
              class="
                form-input
                py-3
                px-4
                block
                w-full
                transition
                ease-in-out
                duration-150
              "
              :class="{ 'bg-red-100 border-red-400': v$.apellido_materno.$error }"
              id="input_maternal_name"
            />
            <div v-if="v$.apellido_materno.$error" class="text-red-600">
              <span>
                {{ v$.apellido_materno.$errors[0].$message }}
              </span>
            </div>
          </div>
        </div>
        <div class="block sm:flex">
          <div class="px-3 w-full mt-5">
            <label
              class="block text-sm font-medium leading-5 text-gray-700"
              :class="{ 'text-red-600': v$.sexo.$error }"
              >Genero</label
            >
            <select
              v-model="userForm.sexo"
              name="select-gender"
              id="select-gender"
              class="form-select w-full"
              :class="{ 'bg-red-100 border-red-400': v$.sexo.$error }"
            >
              <option value="0" disabled>Seleccionar</option>
              <option value="Masculino">Masculino</option>
              <option value="Femenino">Femenino</option>
            </select>
            <div v-if="v$.sexo.$error" class="text-red-600">
              <span>
                {{ v$.sexo.$errors[0].$message }}
              </span>
            </div>
          </div>

          <div class="px-3 w-full mt-5">
            <label
              class="block text-sm font-medium leading-5 text-gray-700"
              :class="{ 'text-red-600': v$.rol.$error }"
              >Roles</label
            >
            <select
              v-model="userForm.rol"
              name="select-role"
              id="select-role"
              class="form-select w-full"
              :class="{ 'bg-red-100 border-red-400': v$.rol.$error }"
            >
              <option v-for="rol in roles" :key="rol" :value="rol.nombre">
                {{ rol.nombre.toUpperCase() }}
              </option>
            </select>
            <div v-if="v$.rol.$error" class="text-red-600">
              <span>
                {{ v$.rol.$errors[0].$message }}
              </span>
            </div>
          </div>
        </div>
        <div class="block sm:flex">
          <div class="px-3 w-full mt-5">
            <label
              class="block text-sm font-medium leading-5 text-gray-700"
              :class="{ 'text-red-600': v$.email.$error }"
              >Correo electónico</label
            >
            <input
              v-model="userForm.email"
              type="text"
              class="
                form-input
                py-3
                px-4
                block
                w-full
                transition
                ease-in-out
                duration-150
              "
              :class="{ 'bg-red-100 border-red-400': v$.email.$error }"
              id="input_email"
            />
            <div v-if="v$.email.$error" class="text-red-600">
              <span>
                {{ v$.email.$errors[0].$message }}
              </span>
            </div>
          </div>

          <div class="px-3 w-full mt-5">
            <label
              class="block text-sm font-medium leading-5 text-gray-700"
              :class="{ 'text-red-600': v$.telefono.$error }"
              >Número de teléfono</label
            >
            <input
              v-model="userForm.telefono"
              type="text"
              class="
                form-input
                py-3
                px-4
                block
                w-full
                transition
                ease-in-out
                duration-150
              "
              :class="{ 'bg-red-100 border-red-400': v$.telefono.$error }"
              id="input_cellphone"
            />
            <div v-if="v$.telefono.$error" class="text-red-600">
              <span>
                {{ v$.telefono.$errors[0].$message }}
              </span>
            </div>
          </div>
        </div>
        <div class="block sm:flex">
          <div class="px-3 w-full mt-5">
            <label
              class="block text-sm font-medium leading-5 text-gray-700"
              :class="{ 'text-red-600': v$.password.$error }"
              >Contraseña</label
            >
            <input
              v-model="userForm.password"
              type="password"
              class="
                form-input
                py-3
                px-4
                block
                w-full
                transition
                ease-in-out
                duration-150
              "
              :class="{ 'bg-red-100 border-red-400': v$.password.$error }"
              id="input_password"
            />
            <div v-if="v$.password.$error" class="text-red-600">
              <span>
                {{ v$.password.$errors[0].$message }}
              </span>
            </div>
          </div>
          <div class="px-3 w-full mt-5">
            <label
              class="block text-sm font-medium leading-5 text-gray-700"
              :class="{ 'text-red-600': v$.password2.$error }"
              >Confirmar contraseña</label
            >
            <input
              v-model="userForm.password2"
              type="password"
              class="
                form-input
                py-3
                px-4
                block
                w-full
                transition
                ease-in-out
                duration-150
              "
              :class="{ 'bg-red-100 border-red-400': v$.password2.$error }"
              id="input_password2"
            />
            <div v-if="v$.password2.$error" class="text-red-600">
              <span>
                {{ v$.password2.$errors[0].$message }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="border-t-2 w-full mb-5 mt-5"></div>
      <div id="modal-footer">
        <div class="text-right">
          <button
            id="btn-save-user"
            v-on:click="saveUser()"
            class="
              bg-white
              hover:transition
              duration-150
              border-green-700
              hover:bg-green-500 hover:border-green-500
              text-green-700
              hover:text-white
              px-3
              py-2
              border border-green-600
              rounded-xl
              focus:outline-none focus:border-green-700
            "
          >
            Agregar
          </button>
          <button
            id="btn-closed"
            class="
              bg-white
              hover:transition
              duration-150
              hover:bg-orange-500 hover:border-orange-500
              text-orange-700
              hover:text-white
              px-3
              py-2
              border border-orange-700
              rounded-xl
              ml-3
              focus:outline-none
              focus:shadow-outline-orange
              focus:border-orange-700
            "
            v-on:click="CloseModal()"
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- final modal agregar usuario -->
</template>

<script>
import $ from "jquery";
import { computed, onMounted, reactive, ref } from "vue-demi";
import {
  helpers,
  minLength,
  required,
  sameAs,
  email,
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import User from "@/classes/User.js";
import Roles from "@/classes/Roles.js";
import { getUserAuth } from "@/helpers/auth.js";
import Swal from "sweetalert2";
import {
  hasCharactersNumbers,
  hasSpecialcharacters,
  hasLetters,
} from "@/helpers/text.js";
export default {
  name: "ModalAddUser",
  setup(props, { emit }) {
    let roles = ref([]);
    let userForm = reactive({
      nombres: "",
      apellido_paterno: "",
      apellido_materno: "",
      email: "",
      status: true,
      telefono: "",
      create_at: "",
      password: "",
      password2: "",
      sexo: "",
      rol: "",
    });
    const rulesUserForm = computed(() => {
      return {
        nombres: {
          required: helpers.withMessage(
            "El campo NOMBRES es requerido",
            required
          ),
          minLength: helpers.withMessage(
            "El campo NOMBRES tiene que tener como minimo 4 caracteres",
            minLength(4)
          ),
          hasCharactersNumbers: helpers.withMessage(
            "Este campo no puede contener números",
            hasCharactersNumbers
          ),
          hasSpecialcharacters: helpers.withMessage(
            "Este campo no permite caracteres especiales",
            hasSpecialcharacters
          ),
        },
        apellido_paterno: {
          required: helpers.withMessage(
            "El campo APELLIDO PATERNO es requerido",
            required
          ),
          hasCharactersNumbers: helpers.withMessage(
            "Este campo no puede contener números",
            hasCharactersNumbers
          ),
          hasSpecialcharacters: helpers.withMessage(
            "Este campo no permite caracteres especiales",
            hasSpecialcharacters
          ),
        },
        apellido_materno: {
          required: helpers.withMessage(
            "El campo APELLIDO MATERNO es requerido",
            required
          ),
          hasCharactersNumbers: helpers.withMessage(
            "Este campo no puede contener números",
            hasCharactersNumbers
          ),
          hasSpecialcharacters: helpers.withMessage(
            "Este campo no permite caracteres especiales",
            hasSpecialcharacters
          ),
        },
        email: {
          required: helpers.withMessage(
            "El campo CORRERO ELECTRÓNICO es requerido",
            required
          ),
          email: helpers.withMessage(
            "El campo CORRERO ELECTRÓNICO no es del formato correcto",
            email
          ),
        },
        telefono: {
          required: helpers.withMessage(
            "El campo NÚMERO DE TELÉFONO es requerido",
            required
          ),
          hasLetters: helpers.withMessage(
            "Este campo no permite ningun tipo de letra",
            hasLetters
          ),
          minLength: helpers.withMessage(
            "El campo NÚMERO DE TELÉFONO tiene que tener como minimo 10 caracteres",
            minLength(10)
          ),
        },
        password: {
          required: helpers.withMessage(
            "El campo CONTRASEÑA es requerido",
            required
          ),
          minLength: helpers.withMessage(
            "El campo PASSWORD tiene que tener como minimo 6 caracteres",
            minLength(6)
          ),
        },
        password2: {
          required: helpers.withMessage(
            "El campo CONFIRMAR CONTRASEÑA es requerido",
            required
          ),
          sameAs: helpers.withMessage(
            "El campo CONFIRMAR CONTRASEÑA no coincide con CONTRASEÑA",
            sameAs(userForm.password)
          ),
        },
        sexo: {
          required: helpers.withMessage(
            "Porfavor de seleccionar una opción",
            required
          ),
        },
        rol: {
          required: helpers.withMessage(
            "Porfavor de seleccionar una opción",
            required
          ),
        },
      };
    });
    onMounted(async () => {
      const rolesClass = new Roles("/empleados/consultar_roles");
      rolesClass
        .getAllRoles()
        .then((res) => {
          if (res.status == 200) {
            roles.value = res.data.respuesta.informacion;
          }
        })
        .catch((error) => console.log(error));
    });
    let v$ = useVuelidate(rulesUserForm, userForm);
    function CloseModal() {
      $("#show-modal-active-user").removeClass("show-modal-active");
      v$.$reset();
      userForm.nombres = "";
      userForm.apellido_paterno = "";
      userForm.apellido_materno = "";
      userForm.email = "";
      userForm.status = "";
      userForm.telefono = "";
      userForm.create_at = "";
      userForm.password = "";
      userForm.password2 = "";
    }
    async function saveUser() {
      sweetalertLoading();
      let tokenUser = getUserAuth();
      if (tokenUser == false) {
        return false;
      }
      this.v$.$validate();
      if (!this.v$.$error) {
        const response = new User(
          userForm.nombres,
          userForm.apellido_paterno,
          userForm.apellido_materno,
          userForm.sexo,
          userForm.telefono,
          userForm.email,
          userForm.password,
          userForm.rol
        );
        response
          .createAccount()
          .then((x) => {
            Swal.fire({
              icon: "success",
              title: x.data.respuesta.mensaje,
              showConfirmButton: false,
              timer: 1500,
            });
            emit("reload_data_user");
            CloseModal(); // cerrar la ventana
          })
          .catch((error) => {
            console.log(error.response.status);
            if (error.respuesta.status == 401 || error.respuesta.status == 422) {
              Swal.fire({
                icon: "error",
                title: error.respuesta.data.respuesta.mensaje,
                text:
                  "Error en email " +
                  error.respuesta.data.errors.email.map((res) => res),
                showConfirmButton: false,
              });
            }
          });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error de validaciones",
          text: "favor de corregir las validaciones",
        });
      }
    }
    function sweetalertLoading() {
      Swal.fire({
        title: "Espere un momento...!",
        timerProgressBar: true
      });
    }
    return {
      saveUser,
      CloseModal,
      userForm,
      roles,
      v$,
    };
  },
};
</script>

<style>
.modal-active {
  align-items: center;
  position: relative;
}
.modal {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  align-items: center;
  pointer-events: none;
}
.show-modal-active {
  display: flex;
  opacity: 1;
  align-items: center;
  pointer-events: auto;
}
@media (max-width: 900px) {
  .modal-active {
    margin-top: 70vh;
    margin-bottom: 100px;
  }
  .modal {
    overflow-y: scroll;
  }
}
</style>