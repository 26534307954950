<template>
<form>
  <svg class="w-8 h-8 text-orange-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
  </svg>
  <div class="space-y-4 mt-6">
    <div v-if="contCategory < categories.length">
      <div v-for="(step,index) of  arrCategories[contCategory].steps" :key="step.step">
        <div v-if="index == contSteps ">
          <questions :arrayQuestions="step.questions" :categoryName="categories[contCategory].name" />
        </div>
      </div>
      <p class="text-red-500 text-xs italic mt-5">{{message}}</p>
    </div>
  </div>
  <div class="flex justify-between  mt-16">
    <span class="inline-flex rounded-md shadow-sm">
      <button type="button" id="btn_back" @click="decrementSteps()" class="w-full inline-flex items-center justify-center px-6 py-3 border border-orange-500 text-base leading-6 font-medium rounded-md text-orange-500 bg-white hover:bg-orange-500 hover:text-white focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition ease-in-out duration-150">
        Atrás
      </button>
    </span>
    <span class="inline-flex rounded-md shadow-sm">
      <button type="button" @click="incrementSteps()"  class="w-full  items-center justify-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-orange-500 hover:bg-orange-500 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition ease-in-out duration-150 ">
        Siguiente
      </button>
    </span>
  </div>
</form>
</template>

<script>
//:disabled="buttonDisabled"  :class="buttonDisabled ? 'disabled' : ''"
import Category from "@/classes/category.js";
import Validation from "@/classes/Validation.js";
import {
  inject,
  ref,
  watchEffect,
  onMounted
} from "vue";
import {
  useRouter
} from 'vue-router';
import Questions from "@/components/quoter/Questions.vue"
import axios from "axios";
import Swal from "sweetalert2";
import { getUserAuthInfo } from "@/helpers/auth.js";
export default {
  name: "Steps",
  components: {
    Questions
  },
  setup() {
    const router = useRouter();
    const message = inject('message');
    const categories = inject('categories');
    const arrCategories = ref(JSON.parse(JSON.stringify(categories.value)));

    const titularGender = inject('titularGender');
    const contCategory = inject('contCategory');

    const step = inject('step');
    const contSteps = inject('contSteps');
    const currentCategoryName = inject('currentCategoryName');
    const arrayIdQuestions = inject('arrayIdQuestions');
    const arrayAnswers = inject('arrayAnswers');
    // const currentProductName = inject('currentProductName');
    const showMessage = inject('showMessage');
    const buttonDisabled = inject('buttonDisabled');
    const parametrosSalesforce = inject('parametrosSalesforce');
    const isLoading = inject('isLoading');
    const aspects = inject('aspects');
    const json = ref([]);
    const anterapi = process.env.VUE_APP_ANTERAPI;
    let config = ref({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getUserAuthInfo().token}`
      }
    }); 

    watchEffect(() => {
      if (categories.value) {
        arrCategories.value = JSON.parse(JSON.stringify(categories.value));
        arrCategories.value.forEach((category) => {
          //category.steps.forEach((step, index) => {
          for (let i = 0; i < category.steps.length; i++) {
            if (category.name == "EMISION") {
              for (let j = 0; j < category.steps[i].questions.length; j++) {
                if (titularGender.value != undefined || titularGender.value != "" || titularGender.value == null) {
                  if (titularGender.value.toUpperCase() == 'MASCULINO') {
                    if (category.steps[i].questions[j].subcategoryName == 'Historia obstétrica titular') {
                      category.steps[i].questions.splice(j, 1);
                      //arrCategories.value[ind].steps[index].questions.splice(i,1);
                      i--;
                    }
                  }
                }
              }
            }
            if (category.steps[i].questions.length == 0) {
              category.steps.splice(i, 1);
              //arrCategories.value[ind].steps.splice(index,1);
              i--;
            }
          }
        });
      }
    })

    onMounted(() => {
      if(categories.value.length == 0){
        arrayAnswers.value = [];
        localStorage.clear();
        aspects.value = [];
        categories.value = [];
        
        step.value = 2;
        getQuestionsBySelectedProducts();
      }
    })

    async function getQuestionsBySelectedProducts() {
      try {
        isLoading.value = true;
        let requestUrl = anterapi + "/pregunta/obtener_por_productos_y_categorias?procedimiento_almacenado=sp_obtener_preguntas_por_productos_y_categorias&nombre_productos[]=Auto&categorias[]=PERSONALES&categorias[]=COTIZACION";
        
        await axios.get(requestUrl, config.value).then((response) => {
          json.value = response.data.respuesta;
          console.log("response ", response);

          localStorage.setItem("JSON", JSON.stringify(response.data.respuesta));
        }).catch((error) => {
          console.log("Se presento un error al obtener las preguntas: ", error);
        }).finally(() => {
          isLoading.value = false;
        })
        validateQuestions();
      } catch (error) {
        console.log("Componente Producto: Se presento un error: ", error);
      }
    }

    function validateQuestions() {
      console.log("json: ", json.value);
      if (json.value.length > 0) {
        for (let i = 0; i < json.value.length; i++) { //categories
          const categories = json.value[i];
          for (let j = 0; j < categories.steps.length; j++) { //steps
            const arrayQuestions = categories.steps[j].questions;
            for (let k = 0; k < arrayQuestions.length; k++) { //questions   
              if (arrayIdQuestions.value.length == 0) {
                arrayIdQuestions.value.push(arrayQuestions[k].id);
              } else {
                const found = arrayIdQuestions.value.find(element => element == arrayQuestions[k].id);
                if (found == undefined) {
                  arrayIdQuestions.value.push(arrayQuestions[k].id);
                } else {
                  json.value[i].steps[j].questions.splice(k, 1);
                  k--;
                }
              }
            }
            if (arrayQuestions.length == 0) {
              json.value[i].steps.splice(j, 1);
              j--;
            }
          }
        }
        // step.value++;
        // router.push({
        //   name: "Questions"
        // });
      } else {
        Swal.fire({
          title: "Aviso",
          text: "No se encontró información con los datos seleccionados.",
          icon: "info",
        });
        return;
      }
      console.log("json modificado", json.value);
      let arrayCategories = Category.buildFromJson(json.value);
      arrayCategories.forEach(element => {
        categories.value.push(element);
      });

      console.log("categories.value ", categories.value);
    }

    function decrementSteps() {
      // if (showMessage.value && contSteps.value != 0) {
      //   message.value = "Para poder continuar es necesario,";
      //   return;
      // }
      contSteps.value--;
      if (contSteps.value < 0) {
        if (contCategory.value > 0) {
          contCategory.value--;
          let arrQuestions = arrCategories.value;
          let contStepsForCategory = arrQuestions[contCategory.value].steps.length - 1;

          contSteps.value = contStepsForCategory;
        } else {
          contSteps.value = 0;
          if (currentCategoryName.value == "EMISION") {
            router.push({
              name: "Quotation"
            });
          } else {
            router.push({
              name: "Products"
            });
          }
        }
      }
      arrayIdQuestions.value = [];
    }


    function incrementSteps() {
      validateAnswer();
      if (showMessage.value) {
        message.value = "Para poder avanzar es necesario que contestes las preguntas correctamente, sin dejarlas vacías"
        return;
      }
      message.value = "";
      if (contCategory.value >= categories.value.length) {
        return;
      }
      if(arrayAnswers.value.length == 8){
        saveProspectSalesforce();
      }
      contSteps.value++;

      let arrQuestions = arrCategories.value;
      let contStepsForCategory = arrQuestions[contCategory.value].steps.length;

      if (contSteps.value >= contStepsForCategory) {
        contSteps.value = 0;
        contCategory.value++;
      }

      if (contCategory.value == Category.countCategory()) {
        contCategory.value--;
        contSteps.value = contStepsForCategory - 1;
        if (currentCategoryName.value == "EMISION") {
          console.log("currentCategoryName", currentCategoryName.value);
          // if (currentProductName.value == 'Vida') {
          //   console.log("currentProductName", currentProductName.value);
          //    router.push({
          //     name: "QuestionsDependents"
          //   });
          // } else {
          //   console.log(currentProductName.value.toUpperCase());
          //   router.push({
          //     name: "IssuanceResume"
          //   });
          // }
          router.push({
            name: "IssuanceResume"
          });
        } else {
          router.push({
            name: "QuoteResume"
          });
        }
      }
    }


    function validateAnswer() {
      showMessage.value = Validation.validateAllQuestionsNotEmpty(arrayAnswers.value,arrayIdQuestions.value);
      
    }

    function saveProspectSalesforce(){
      arrayAnswers.value.forEach(answer => {
        if (answer.question.includes("Nombre(s)")) {
          parametrosSalesforce.value.cliente.nombres = answer.answer;
        }
        if (answer.question.includes("Apellido Paterno")) {
          parametrosSalesforce.value.cliente.apellido_paterno = answer.answer;
        }
        if (answer.question.includes("Apellido Materno")) {
          parametrosSalesforce.value.cliente.apellido_materno = answer.answer;
        }
        
        if (answer.question.includes("Correo electrónico")) {
          parametrosSalesforce.value.cliente.email = answer.answer;
        }
        if (answer.question.includes("Código Postal")) {
          parametrosSalesforce.value.cliente.codigo_postal = answer.answer;
        }
        if (answer.question.includes("Teléfono")) {
          parametrosSalesforce.value.cliente.telefono = answer.answer;
        }
        if (answer.question.includes("Cuándo naciste")) {
          parametrosSalesforce.value.cliente.fecha_nacimiento = answer.answer;
        }
        if (answer.question.includes("tú eres")) {
          parametrosSalesforce.value.cliente.sexo = "Masculino";
          if(answer.answer != "Hombre")
          { 
            parametrosSalesforce.value.cliente.sexo = "Femenino";
          }
        }
      });
      
      let config = ref({
        headers: {
          'Content-Type': 'application/json'
        }
      });
      axios.post(anterapi + "/salesforce/registrar_cliente", parametrosSalesforce.value, config.value).then((response) => {
        // parametrosCotizacion.value.prospecto_id = response.data.respuesta.id;
        console.log("respuesta de /salesforce/registrar_cliente", response.data);
      }).catch(error => {
        console.log("Se presentó un errror: ",error)
      });
    }

    return {
      step,
      contSteps,
      categories,
      decrementSteps,
      incrementSteps,
      contCategory,
      parametrosSalesforce,
      message,
      titularGender,
      arrCategories,
      buttonDisabled
    }
  }
}
</script>

<style>
.disabled {
  @apply  opacity-75;
}
</style>

