<template>
  <h3>VII. MODIFICACIONES AL AVISO DE PRIVACIDAD</h3>
  <p>
    Nos reservamos el derecho de efectuar en cualquier momento cambios o
    modificaciones al presente Aviso de Privacidad, con intención de cumplir con
    las actualizaciones a la propia ley. Los cambios y modificaciones estarán
    disponibles a través de los siguientes medios: Anuncios en nuestras
    oficinas, en nuestra páginas de internet, a través de nuestro personal que
    recabe sus Datos Personales y a través del envio a su último correo
    electrónico proporcionado.
  </p>
</template>
