<template>
  <!-- Dashboard Resume -->
  <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
    <h2 class="text-lg leading-6 font-medium text-cool-gray-900">Resumen</h2>
    <div class="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-2" x-max="1">
      <!-- Quotes -->
      <QuoteChartLineCard :key="keyChartLine" ref="quotesChartLineCard" :chartId="2" :title="'Cotizaciones'" :chartTitle="chartsTitle.chartQuotes" :tableTitle="tableTitleQuotes" :totalQuotes="totalCotizaciones ? totalCotizaciones : '0'" :data="quotes"></QuoteChartLineCard>
      <!-- Sales -->
      <SaleChartLineCard :key="keyChartLine" ref="salesChartLineCard" :chartId="3" :title="'Ventas'" :secondTitle="'Ganancia'" :thirdTitle="'Pendientes'" :fourthTitle="'Pérdida'" :chartTitle="chartsTitle.chartSales" :tableTitle="tableTitleSales" :totalSales="totalVentas ? totalVentas : '0'" :totalSalesAmount="currencyFilter(montoVentas ? montoVentas : '0', 'MXN')" :totalIssuances="totalEmisiones ? totalEmisiones : '0'" :totalIssuancesAmount="currencyFilter(montoEmisiones ? montoEmisiones : '0', 'MXN')" :data="sales"></SaleChartLineCard>
      <!-- End Cards -->
    </div>
    <!-- Sales by insurer -->
    <div class="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-2" x-max="1">
      <!-- <ChartBarSalesCard :key="keyChartBar" ref="chartBarSalesCard" :title="'Ventas por aseguradora'" :chartTitle="chartsTitle.chartSalesProgress" :data="insurersSales"></ChartBarSalesCard> -->
      <div class="bg-white overflow-hidden shadow rounded-lg flex flex-col justify-between" >
        <div class="flex items-center p-6">
          <div class=" w-0 flex-1">
            <dl>
              <dt class="text-sm leading-5 font-medium text-cool-gray-500 truncate" >
                {{ 'Ventas por aseguradora' }}
              </dt>
              <dd>
                <div class="text-lg leading-7 font-medium text-cool-gray-900">
                  <canvas height="200" id="myChart"></canvas>
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
      <!-- <ChartDoughnutAmountsCard :key="keyChartBar" ref="chartDoughnutAmountsCard" :title="'Monto total por aseguradora'" :chartTitle="chartsTitle.chartSalesProgress" :data="amountsPerProvider"></ChartDoughnutAmountsCard> -->
      <div class="bg-white overflow-hidden shadow rounded-lg flex flex-col justify-between" >
        <div class="flex items-center p-6">
          <div class=" w-0 flex-1">
            <dl>
              <dt class="text-sm leading-5 font-medium text-cool-gray-500 truncate" >
                {{ 'Monto total por aseguradora' }}
              </dt>
              <dd>
                <div class="text-lg leading-7 font-medium text-cool-gray-900">
                  <canvas height="200" id="myChart2"></canvas>
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import QuoteChartLineCard from '@/components/dashboard/summary/QuoteChartLineCard';
import SaleChartLineCard from '@/components/dashboard/summary/SaleChartLineCard';
// import ChartBarSalesCard from '@/components/dashboard/summary/ChartBarSalesCard';
// import ChartDoughnutAmountsCard from '@/components/dashboard/summary/ChartDoughnutAmountsCard';

import { onMounted, onBeforeMount, ref } from '@vue/runtime-core';
import Summary from '@/classes/summary.js';
import { currencyFilter } from '@/helpers/currency.js'
import { getMonday, getMonthFirstAndLastDayOfMonth, getDayFirstAndLastDayOfYear} from '@/helpers/date.js';
import Chart from "chart.js";
export default {
  props:['rangeType'],

  components:{
    QuoteChartLineCard,
    SaleChartLineCard,
    // ChartBarSalesCard,
    // ChartDoughnutAmountsCard
  },

  setup(props) {
    const totalCotizaciones = ref(0);
    const totalEmisiones = ref(0);
    const totalVentas = ref(0);
    const montoEmisiones = ref(0);
    const montoVentas = ref(0);

    const totalSales = ref(0);
    const totalSalesAmount = ref(0);

    const insurersSales = ref({});
    const amountsPerProvider = ref ({});
    const insurersData = ref([]);

    const quantityQuotes = ref(0);
    const totalQuotes = ref(0);
    const sales =  ref([]);
    const quotes =  ref({});
    
    const tableTitleQuotes = ref({display: false, text: ''});
    const tableTitleSales = ref({display: false, text: ''});
    const tableTitleInsurer = ref({display: false, text: ''});

    const chartLineCardQuotes = ref(null);
    const chartLineCardSales = ref(null);

    const chartBarSalesCard = ref(null);
    const chartDoughnutAmountsCard = ref(null);
    const salesChartBarCard = ref(null);
    const keyChartLine = ref(1);
    const keyChartBar = ref(1);
    const chartsTitle = ref({
      "chartVisits": { display: false, text:''},
      "chartQuotes": { display: false, text:''},
      "chartSales": { display: false, text:''},
      "chartSalesProgress": { display: false, text:''}
    });

    var token = sessionStorage.login ? JSON.parse(sessionStorage.getItem('login')).token : '';
    var userId = sessionStorage.login ? JSON.parse(sessionStorage.getItem('login')).id : '';
    var role = sessionStorage.login ? JSON.parse(sessionStorage.getItem('login')).rol : '';

    async function setRangeDates(){
      
      await props.rangeType;
      
      totalCotizaciones.value = 0;
      totalEmisiones.value = 0;
      totalVentas.value = 0;
      montoEmisiones.value = 0;
      montoVentas.value = 0;
      
      switch (props.rangeType) {
        case 'years':
          await getSummaryByYear();
          break;
        case 'months':
          await getSummaryByMonth();
          break;
        default:
          await getSummaryByWeek();
          break;
      }
      await showChart('bar','myChart',insurersSales.value);
      await showChart('horizontalBar','myChart2',amountsPerProvider.value);
    }

    onBeforeMount(async () =>  {
      
    });

    onMounted(async () =>  {
      await getSummaryByWeek();
      await showChart('bar','myChart',insurersSales.value);
      await showChart('horizontalBar','myChart2',amountsPerProvider.value);
    });

    async function getSummaryByWeek() {
      const initialDate = getMonday("s", 0);
      const endDate = getMonday("e", 0);
      
      await Summary.getByRangeOfDates(initialDate, endDate, token, userId, role).then(result => {
        totalCotizaciones.value = result.respuesta.data.total_cotizaciones;
        totalEmisiones.value = result.respuesta.data.total_emisiones_pendientes;
        totalVentas.value = result.respuesta.data.total_ventas;
        montoEmisiones.value = result.respuesta.data.monto_total_emisiones_pendientes;
        montoVentas.value = result.respuesta.data.monto_total_ventas;
        insurersSales.value = result.respuesta.data.ventas_aseguradoras;
        amountsPerProvider.value = result.respuesta.data.montos_aseguradoras;
      });
      
    }

    async function getSummaryByMonth() {
      const weeksRange = getMonthFirstAndLastDayOfMonth(new Date());
      await Summary.getByRangeOfDates(weeksRange.first, weeksRange.last, token, userId, role).then(result => {
        totalCotizaciones.value = result.respuesta.data.total_cotizaciones;
        totalEmisiones.value = result.respuesta.data.total_emisiones_pendientes;
        totalVentas.value = result.respuesta.data.total_ventas;
        montoEmisiones.value = result.respuesta.data.monto_total_emisiones_pendientes;
        montoVentas.value = result.respuesta.data.monto_total_ventas;
        insurersSales.value = result.respuesta.data.ventas_aseguradoras;
        amountsPerProvider.value = result.respuesta.data.montos_aseguradoras;
      });
      
    }

    async function getSummaryByYear(){
      const daysByMonths =  getDayFirstAndLastDayOfYear(new Date());

      await Summary.getByRangeOfDates(daysByMonths.first, daysByMonths.last, token, userId, role).then(result => {
        totalCotizaciones.value = result.respuesta.data.total_cotizaciones;
        totalEmisiones.value = result.respuesta.data.total_emisiones_pendientes;
        totalVentas.value = result.respuesta.data.total_ventas;
        montoEmisiones.value = result.respuesta.data.monto_total_emisiones_pendientes;
        montoVentas.value = result.respuesta.data.monto_total_ventas;
        insurersSales.value = result.respuesta.data.ventas_aseguradoras;
        amountsPerProvider.value = result.respuesta.data.montos_aseguradoras;
      });
      
    }

    async function showChart(type, chart, data){
        var ctx4 = document.getElementById(chart).getContext('2d');
        new Chart(ctx4, {
          type: type,
          data: {
            labels: Object.keys(data),
            datasets: [{
              backgroundColor: '#1c3a69',
              borderWidth: 2,
              borderColor: 'black',
              data: Object.values(data)
            }]
          },
          options: {
            title: chartsTitle.value.chartSalesProgress,
            responsive: true,
            legend: {
              display: false
            },
          }
        });
      }
    

    return {
      totalCotizaciones,
      totalEmisiones,
      totalVentas,
      montoEmisiones,
      montoVentas,
      sales,
      quotes,
      totalSales,
      totalSalesAmount, 
      totalQuotes,
      quantityQuotes,
      chartLineCardQuotes,
      chartLineCardSales,
      insurersData,
      chartBarSalesCard,
      chartDoughnutAmountsCard,
      salesChartBarCard,
      insurersSales,
      amountsPerProvider,
      currencyFilter,
      setRangeDates,
      keyChartLine,
      keyChartBar,
      tableTitleQuotes,
      tableTitleSales,
      tableTitleInsurer,
      chartsTitle
    }
  }
}
</script>