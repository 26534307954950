<template>
  <div class="relative overflow-hidden">
    <div x-data="{ open: true }" class="relative pt-6 pb-12 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32" >
      <header-component />
      <main-quoter-component />
      <!-- Your Chat Plugin code -->
      <div class="fb-customerchat"
        attribution=install_email
        attribution_version="biz_inbox"
        page_id="103137977784270"
        theme_color="#ff7a00"
          logged_in_greeting="&#xa1;Hola!, &#xbf;en qu&#xe9; podemos ayudarte?"
          logged_out_greeting="&#xa1;Hola!, &#xbf;en qu&#xe9; podemos ayudarte?">
      </div>

      <div style="margin-top: 80px;"  >
        <a href="https://bit.ly/WhatsAppAnter" class="float" target="_blank">
          <i class="fa fa-whatsapp my-float"></i>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderComponent from "@/components/quoter/HeaderComponent.vue";
import MainQuoterComponent from "@/components/quoter/MainQuoterComponent.vue";


export default {
  name: 'Quoter',
  components: {
    HeaderComponent,
    MainQuoterComponent
  
  }
};
</script>
