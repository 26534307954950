<template>
  <div>
    <table id="data-table" 
      class="table w-full dataTable no-footer min-w-full divide-y divide-cool-gray-200 pt-8 rwd-table" style="width:90%">
      <thead>
        <tr>
          <th class="bg-cool-gray-50 text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider" 
            v-for="column in columns"
            :key="column">
            {{ column }}
          </th>
        </tr>
      </thead>
      <tbody></tbody>
    </table>
  </div>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Excel from "@/helpers/excel";
import "@/assets/css/custom.css";
import { onMounted, ref } from "@vue/runtime-core";
import $ from "jquery";
import { dateFormatForSystem} from "@/helpers/date.js";
import Reports from "@/classes/reports.js";
import Customer from '@/classes/customer.js';
import Swal from 'sweetalert2';
export default {
  name: "ReportTable",
  props: {
    columns: {type: Array},
    rows: {type: Array},
    search: { type: Boolean, default: function(){ return true}},
    dataLength: {type: Boolean, default: function(){ return true }},
    arrayFields: {type: Array},
    reportType:{type: String}
  },
  setup(props) {
    const dataTable = ref(null);
    let Fields = ref([]);
    const language = {
          decimal: "",
          emptyTable: "No hay información",
          info: "Mostrando _START_ a _END_ de _TOTAL_ Entradas",
          infoEmpty: "Mostrando 0 to 0 of 0 Entradas",
          infoFiltered: "(Filtrado de _MAX_ total entradas)",
          infoPostFix: "",
          thousands: ",",
          lengthMenu: "Mostrar _MENU_ Entradas",
          loadingRecords: "Cargando...",
          processing: "Procesando...",
          search: "Buscar:",
          zeroRecords: "Sin resultados encontrados",
          paginate: {
            first: "Primero",
            last: "Ultimo",
            next: "Siguiente",
            previous: "Anterior",
          },
        }
    async function createTable() {
      Fields.value = await props.arrayFields;
      await props.rows;
      showTable();
    }
    async function createTablePolicy() {
      Fields.value = await props.arrayFields;
      await props.rows;
      addPolicyEvents();
    }
    const addPolicyEvents = () => {
      $.fn.dataTable.ext.errMode = 'none';
      
      var quantityColumns = 0;
      const columnAlignment = [];
      if(props.rows.length > 0){
        quantityColumns = props.rows[0].length;
        for (let index = 0; index < quantityColumns; index++){
          columnAlignment[index] = {
            "targets": index,
            "className": (index>0)?"text-right":"text-left"
          };
        }
      }

      let column_values = [];
      Fields.value.forEach((element) => {
        column_values.push({ data: element });
      });
      
      if ( $.fn.DataTable.isDataTable('#data-table') ) {
        dataTable.value.destroy();
      }
      dataTable.value = $("#data-table").DataTable({
        data: props.rows,
        scrollX: false,
        bFilter: props.search,
        bLengthChange: props.dataLengt,
        language: language,          
        columnsDefs: columnAlignment,
        columns: column_values          
      });
      dataTable.value = $("#data-table").on('click.dt','td', async function(){
        var table = $('#data-table').DataTable();
        var data = table.row(this).data();
        await downloadPdf(data.PDF, data.num_poliza);
        console.log(data.num_poliza, data.PDF);
      });
    }

    async function downloadPdf(id, policyNumber){
      let response = await Customer.getPolicyPdf(id, policyNumber)
      if(response.data.respuesta.error == false){
        var urls = [];
        urls.push(response.data.respuesta.url_poliza_pdf);
        if(response.data.respuesta.url_poliza_usa_pdf != ""){
          urls.push(response.data.respuesta.url_poliza_usa_pdf);
          await Swal.fire({
            title: 'Selecciona el documento',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Poliza PDF',
            denyButtonText: `Poliza USA`,
            icon: 'success',
          }).then((result) => {
            if (result.isConfirmed) {
              window.open(urls[0]);
            } else if (result.isDenied) {
              window.open(urls[1]);
            }
          })
        }else{
          await Swal.fire({
            icon: 'success',
            title: 'Selecciona el documento',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Poliza PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              window.open(urls[0]);
            }
          })
        }
      }else{
        Swal.fire(
          "Aviso",
          "No se pudo obtener el pdf de la póliza, apoyate con un asesor.",
          "info"
        );
      }
    }

    const showTable = () => {
      $.fn.dataTable.ext.errMode = 'none';
      
      var quantityColumns = 0;
      const columnAlignment = [];
      if(props.rows.length > 0){
        quantityColumns = props.rows[0].length;
        for (let index = 0; index < quantityColumns; index++){
          columnAlignment[index] = {
            "targets": index,
            "className": (index>0)?"text-right":"text-left"
          };
        }
      }

      let column_values = [];
      Fields.value.forEach((element) => {
        column_values.push({ data: element });
      });
      
      if ( $.fn.DataTable.isDataTable('#data-table') ) {
        dataTable.value.destroy();
      }
      dataTable.value = $("#data-table").DataTable({
        data: props.rows,
        scrollX: false,
        bFilter: props.search,
        bLengthChange: props.dataLengt,
        language: language,          
        columnsDefs: columnAlignment,
        columns: column_values        
      });
      
        
    };

    const exportExcel = (initialDate, finalDate) => {
      console.log("sirve");
      if(props.rows.length > 0){
        initialDate=dateFormatForSystem(initialDate)
        finalDate= dateFormatForSystem(finalDate)
        let exportDataExcel=[];
        const reports = new Reports(initialDate.value, finalDate.value, props.reportType);
        props.rows.forEach((values) => {
          exportDataExcel.push(reports.buildTable(values));
        });
        Excel.exportExcel(exportDataExcel, initialDate, finalDate);
        Swal.fire({
          "title": "Reportes",
          "icon": "success",
          "text": "Reporte exportado satisfactoriamente"
        });
      }else{
        Swal.fire({
          "title": "Reportes",
          "icon": "warning",
          "text": "No se encontro información para exportar en Excel"
        });
      }
    };

    async function createTableSortColumnIndex(index){
      Fields.value = await props.arrayFields;
      await props.rows;
      showTableSorted(index);
    }

    const showTableSorted = (index) => {
      $.fn.dataTable.ext.errMode = 'none';
      
      var quantityColumns = 0;
      const columnAlignment = [];
      if(props.rows.length > 0){
        quantityColumns = props.rows[0].length;
        for (let index = 0; index < quantityColumns; index++){
          columnAlignment[index] = {
            "targets": index,
            "className": (index>0)?"text-right":"text-left"
          };
        }
      }

      let column_values = [];
      Fields.value.forEach((element) => {
        column_values.push({ data: element });
      });
      
      if ( $.fn.DataTable.isDataTable('#data-table') ) {
        dataTable.value.destroy();
      }
      dataTable.value = $("#data-table").DataTable({
        data: props.rows,
        scrollX: false,
        bFilter: props.search,
        bLengthChange: props.dataLengt,
        language: language,          
        columnsDefs: columnAlignment,
        columns: column_values,
        order: [[index, 'desc']]           
      });
        
    };

    onMounted(() => {
    });

    return {
      createTablePolicy,
      createTable,
      exportExcel,
      createTableSortColumnIndex
    };
  },
};
</script>