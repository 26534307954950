<template>
  <div style="min-height: 640px">
    <div
      class="h-screen flex overflow-hidden bg-white"
      x-data="{ sidebarOpen: false }"
      keydown.window.escape="sidebarOpen = false"
    >
      <dashboard-side-bar-menu />
      <div
        x-init="$el.focus()"
        class="flex-1 overflow-auto focus:outline-none"
        tabindex="0"
      >
        <dashboard-side-bar-menu-mobile />
        <main class="flex-1 relative pb-8 z-0 overflow-y-auto bg-gray-50">
          <dashboard-header />
          <div class="mt-8">
            <staff />
          </div>
        </main>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardSideBarMenu from "@/components/dashboard/DashboardSideBarMenu";
import DashboardSideBarMenuMobile from "@/components/dashboard/DashboardSideBarMenuMobile";
import DashboardHeader from "@/components/dashboard/DashboardHeader";
import Staff from "@/components/dashboard/personal/Staff";

import { onMounted } from "@vue/runtime-core";

export default {
  components: {
    DashboardSideBarMenu,
    DashboardSideBarMenuMobile,
    DashboardHeader,
    Staff,
  },
  setup() {
    onMounted(() => {});
  },
};
</script>
