<template lang="en">
<div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
  <loading :active="isLoading" :can-cancel="false" :color="'#bfd243'" :height="100" :width="350" :is-full-page="true" :loader="loader">
  </loading>
  <div class="mx-auto w-full max-w-sm lg:w-96">
    <div>
      <img
        class="h-6 w-auto"
        src="@/assets/images/logo.png"
        alt="Workflow"
      />
      <h2 class="mt-6 text-3xl leading-9 font-extrabold text-orange-500">
        Recuperar cuenta
      </h2>
      <p class="mt-2 text-sm leading-5 text-gray-600 max-w">Parece que haz olvidado tu contraseña. Ingresa una nueva constraseña y confirma para tener acceso nuevamente a tu cuenta Anter.</p>
    </div>
    <div class="mt-8">
      <div>
        <div class="mt-6">
          <form @submit="changePassword($event)" id="form_change" class="space-y-6">
            <div>
              <label for="password" class="block text-sm font-medium leading-5 text-gray-700"> Contraseña </label>
              <div class="rounded-md shadow-sm">
                <input id="input_password" type="password" v-model="password" required minlength="6" maxlength="30" class="appearance-none w-10/12 px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                <span tabIndex="-1" class="input-group-addon" style="background-color: #ffffff">
                  <button type="button" id="btn_show_password" tabIndex="-1" v-on:click="showPassword()" class="appearance-none w-2/12 px-4 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out">
                    <div tabIndex="-1" id="divImageHidePasswordOn">
                      <img id="imageHidePasswordOn" src="@/assets/visibility-off.svg" width="20" height="20" s />
                    </div>
                    <div tabIndex="-1" id="divImageHidePasswordOff" class="invisible">
                      <img tabIndex="-1" id="imageHidePasswordOff" src="@/assets/visibility-on.svg" width="0" height="0" />
                    </div>
                  </button></span>
              </div>
            </div>

            <div class="form-group">
              <label for="password" class="block text-sm font-medium leading-5 text-gray-700">Confirmar contraseña:</label>
              <div class="input-group">
                <input type="password" id="input_confirmPassword" minlength="6" maxlength="30" v-model="confirmPassword" required class="appearance-none w-10/12 px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                <span tabIndex="-1" class="input-group-addon" style="background-color: #ffffff">
                  <button type="button" tabIndex="-1" id="btn_show_confirm_password" v-on:click="showConfirmPassword()" class="appearance-none w-2/12 px-4 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out">
                    <div tabIndex="-1" id="divImageHidePasswordConfirmOn">
                      <img id="imageHidePasswordConfirmOn" src="@/assets/visibility-off.svg" width="20" height="20" s />
                    </div>
                    <div tabIndex="-1" id="divImageHidePasswordConfirmOff" class="invisible">
                      <img tabIndex="-1" id="imageHidePasswordConfirmOff" src="@/assets/visibility-on.svg" width="0" height="0" />
                    </div>
                  </button></span>
              </div>
            </div>
            <div>
              <span class="block w-full rounded-md shadow-sm">
                <button type="submit" id="btn_change_password" class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-orange-500 hover:bg-orange-400 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition duration-150 ease-in-out">
                  Recuperar mi cuenta
                </button>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import User from "@/classes/User.js"
import {
  ref
} from 'vue';
import Swal from 'sweetalert2';
import {
  useRouter,
  useRoute
} from 'vue-router';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  name: "ChangePassword",
  components: {
    Loading
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const isLoading = ref(false);
    const loader = ref('spinner');
    const password = ref("");
    const confirmPassword = ref("");
    const token = ref(route.params.token);
    const email = ref(route.params.email);
    const errors = ref([]);

    async function changePassword(e) {
      e.preventDefault();
      isLoading.value = true;
      if (validateFilds()) {
        User.changePassword(password.value, confirmPassword.value, token.value, email.value)
          .then((response) => {
            console.log("response", response);
            Swal.fire({
              title: "Aviso",
              text: "Su contraseña ha sido cambiada exitosamente",
              icon: "success",
            }).then(function () {
              router.push({
                name: "Login"
              });
            });
          })
          .catch((error) => {
            console.log("Se presentó un error: ", error.response.data);
            let messageError = getErrors(error.response.data);
            Swal.fire({
              title: "Aviso",
              html: `<span style="font-weight: bold; color:red">${messageError}</span>`,
              icon: "error",
            });

          }).finally(() => {
            isLoading.value = false;
          });
      } else {
        isLoading.value = false;
        Swal.fire({
          title: "Aviso",
          text: errors.value,
          icon: "warning",
        });
      }
    }

    function validateFilds() {
      errors.value = [];
      if (
        password.value.includes(" ") == true ||
        confirmPassword.value.includes(" ") == true
      ) {
        errors.value.push("La contraseña no puede contener espacios");
        return false;
      }
      if (password.value != confirmPassword.value) {
        errors.value.push("Las contraseñas son diferentes");
        return false;
      }
      return true;

    }

    function getErrors(data) {
      let error = "";
      for (const property in data) {
        console.log(`${property}: ${data[property]}`);
        switch (property) {
          case "mensaje":
            error = data[property];
            break;
          case "errores":
            error = data[property];
            break;
          default:
            error = "No fue posible recuperar su cuenta. Contacte una asesor.";
            break;
        }
      }
      return error;
    }

    function showPassword() {
      var tipo = document.getElementById("input_password");
      if (tipo.type == "password") {
        tipo.type = "text";
        document.getElementById("divImageHidePasswordOn").className = "invisible";
        document.getElementById("imageHidePasswordOn").width = "0";
        document.getElementById("imageHidePasswordOn").height = "0";
        document.getElementById("divImageHidePasswordOff").className = "visible";
        document.getElementById("imageHidePasswordOff").width = "20";
        document.getElementById("imageHidePasswordOff").height = "20";
      } else {
        tipo.type = "password";
        document.getElementById("divImageHidePasswordOn").className = "visible";
        document.getElementById("imageHidePasswordOn").width = "20";
        document.getElementById("imageHidePasswordOn").height = "20";
        document.getElementById("divImageHidePasswordOff").className = "invisible";
        document.getElementById("imageHidePasswordOff").width = "0";
        document.getElementById("imageHidePasswordOff").height = "0";
      }
    }

    function showConfirmPassword() {
      var tipo = document.getElementById("input_confirmPassword");
      if (tipo.type == "password") {
        tipo.type = "text";
        document.getElementById("divImageHidePasswordConfirmOn").className = "invisible";
        document.getElementById("imageHidePasswordConfirmOn").width = "0";
        document.getElementById("imageHidePasswordConfirmOn").height = "0";
        document.getElementById("divImageHidePasswordConfirmOff").className = "visible";
        document.getElementById("imageHidePasswordConfirmOff").width = "20";
        document.getElementById("imageHidePasswordConfirmOff").height = "20";
      } else {
        tipo.type = "password";
        document.getElementById("divImageHidePasswordConfirmOn").className = "visible";
        document.getElementById("imageHidePasswordConfirmOn").width = "20";
        document.getElementById("imageHidePasswordConfirmOn").height = "20";
        document.getElementById("divImageHidePasswordConfirmOff").className = "invisible";
        document.getElementById("imageHidePasswordConfirmOff").width = "0";
        document.getElementById("imageHidePasswordConfirmOff").height = "0";
      }
    }

    return {
      isLoading,
      loader,
      password,
      confirmPassword,
      changePassword,
      showPassword,
      showConfirmPassword
    };
  }
}
</script>
