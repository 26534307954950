import Question from "@/classes/question.js"
export default class Step {
  step = null;
  questions = [];

  constructor(step, questions) {
    this.step = step;
    this.questions = questions;
  }

  static buildFromJson(jsonArray) {
    let steps = [];
    for (let i = 0; i < jsonArray.length; i++) {
      let questions = Question.buildFromJson(jsonArray[i].questions);
      steps.push(new Step(jsonArray[i].step, questions));
    }
    return steps;
  }
}