<template>
  <div class="min-h-screen bg-white flex">
    <account-confirmation-container/>
  </div>
</template>
<script>
import AccountConfirmationContainer from '@/components/AccountConfirmation/AccountConfirmationContainer';

export default {
  components: {
    AccountConfirmationContainer
  }
};
</script>