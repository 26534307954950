<template>
  <div style="min-height: 640px">
    <div class="h-screen flex overflow-hidden bg-white"
      x-data="{ sidebarOpen: false }"
      keydown.window.escape="sidebarOpen = false">
      <side-bar-menu />
      <div x-init="$el.focus()"
        class="flex-1 overflow-auto focus:outline-none"
        tabindex="0"
      >
        <side-bar-menu-mobile />
        <main class="flex-1 relative pb-8 z-0 overflow-y-auto bg-gray-50">
          <header-component />
          <div class="mt-8 max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="bg-white overflow-hidden shadow rounded-lg flex flex-col justify-betweeen col-span-3 p-4">
              <div class="vld-parent w-full">
                <loading :active="isLoading" :can-cancel="false" :color="'#bfd243'" :height="120" :width="400" :is-full-page="true">
                </loading>
              </div>
              <ReportTable ref="tableRef" :arrayFields="dataFields" :rows="rows" :columns="columns" :reportType="reportType"/>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>
  <script>
import HeaderComponent from '@/components/customer/HeaderComponent.vue';
import SideBarMenu from '@/components/customer/SideBarMenu';
import SideBarMenuMobile from '@/components/customer/SideBarMenuMobile';
import { onMounted, ref, provide } from 'vue';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import ReportTable from '@/components/dashboard/reports/ReportTable.vue';
import Customer from '@/classes/customer.js'
export default{
  components: {
    SideBarMenu,
    SideBarMenuMobile,
    HeaderComponent,
    Loading,
    ReportTable
  },
  setup() {
    const tableRef = ref();
    let dataFields = ref();
    let columns = ref();
    let rows = ref([]);
    let reportType = ref("Quotation");
    const isLoading = ref(false);
    provide('isLoading',isLoading);

    onMounted(async() => {
      getQuotations();
    });

    const getQuotations = async () => {
      isLoading.value = true;
      var customer = new Customer();
      columns.value = Customer.setHeaderQuotationTable();
      dataFields.value = Customer.setOptionsQuotationTable();
      let response = await customer.getQuotations();

      rows.value = response.data.respuesta.informacion;
      let arrayRows = [];
      rows.value.forEach(row =>{
        row.folio = "<a href='/cotizaciones?id="+row.folio+"'>IR A COTIZACIÓN "+row.folio+"</a>";
        arrayRows.push(row);
      });
      rows.value = arrayRows;
      tableRef.value.createTable();
      isLoading.value = false;
    };

    return{
      isLoading,
      tableRef,
      reportType,
      dataFields,
      columns,
      rows
    }
  },
};
  </script>
  