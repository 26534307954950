<template>
  <div x-data="{ open: false }" class="relative pt-6 pb-12 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">
    <header-component/>
    <contact-container/>
    <footer-component/>
  </div>
</template>
<script>
import FooterComponent from '@/components/common/FooterComponent';
import HeaderComponent from "@/components/common/HeaderComponent";
import ContactContainer from "@/components/contact/ContactContainer";

export default {
  components: {
    HeaderComponent,
    FooterComponent,
    ContactContainer
    
  },
  setup() {},
};
</script>