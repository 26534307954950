import numeral from "numeral";

const currencyFilter = function(value, simbol = '') {
  if (!value) {
    return "$ 0";
  }

  return numeral(value).format("($ 0,0)")+` ${simbol}`;
};

const percentFilter = function(value) {
  if (!value) {
    return "0%";
  }

  return `${Number(value).toFixed(2)}%`;
};
export { currencyFilter, percentFilter };
