<template>
<h3>III. FINALIDADES DE LA INFORMACIÓN</h3>
<p>
  Los Datos Personales que Anter 
  recabe serán utilizados para analizar los riesgos y 
  circunstancias que formalizan los contratos de 
  productos y servicios que dan origen a la relación 
  entre Anter y usted como titular de los 
  Datos Personales. Los Datos Personales recabados 
  serán utilizados para las siguientes actividades:
</p>
<ul>
  <li>Cotización de algún tipo de seguro de los ramos de vida, gastos médicos, daños, automóviles, entre otros.</li>
  <li>La emisión de las pólizas subsecuente a la aceptación de la cotización presentada.</li>
  <li>Resolución de eventos como reclamaciones de siniestros.</li>
  <li>Contactarlo para gestionar la cobranza de productos o servicios adquiridos.</li>
  <li>Realizar consultas, investigaciones y revisiones en relación a sus quejas o reclamaciones.</li>
  <li>Enviar publicidad y ofertas de cualquier otro de nuestros productos o servicios.</li>
  <li>Enviar encuestas para evaluar la calidad de nuestros productos y servicios.</li>
  <li>La realización de cualquier actividad complementaria y adicional para la prestación de nuestros servicios.</li>
</ul>
</template>