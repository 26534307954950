<template>
  <div class="bg-white">
    <div class="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
      <div class="max-w-lg mx-auto md:max-w-none md:grid md:grid-cols-2 md:gap-8">
        <div>
          <h2 class="text-2xl leading-8 font-extrabold text-gray-900 sm:text-3xl sm:leading-9">
            Soporte de ventas
          </h2>
          <div class="mt-3">
            <p class="text-lg leading-7 text-gray-500">
              Si necesitas ayuda para comprar un seguro, con gusto uno de
              nuestros asesores podrá guiarte hasta realizar la compra con
              éxito.
            </p>
          </div>
          <div class="mt-9">
            <div class="flex">
              <div class="flex-shrink-0">
                <phone-icon />
              </div>
              <div class="ml-3 text-base leading-6 text-gray-500">
                <p><a href="tel:+526441961187"> Llámanos</a></p>
              </div>
            </div>
            <div class="mt-6 flex">
              <div class="flex-shrink-0">
                <mail-icon />
              </div>
              <div class="ml-3 text-base leading-6 text-gray-500">
                <p>contacto@anter.com.mx</p>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-12 sm:mt-16 md:mt-0">
          <h2 class="text-2xl leading-8 font-extrabold text-gray-900 sm:text-3xl sm:leading-9">
            Soporte técnico
          </h2>
          <div class="mt-3">
            <p class="text-lg leading-7 text-gray-500">
              ¡Ayúdanos a mejorar!, si nuestra herramienta presenta algún
              problema, no dudes en ponerte en contacto con nosotros para darle
              seguimiento y resolverlo a la brevedad
            </p>
          </div>
          <div class="mt-9">
            <div class="flex">
              <div class="flex-shrink-0">
                <phone-icon />
              </div>
              <div class="ml-3 text-base leading-6 text-gray-500">
                <p><a href="tel:+526441441982"> Llámanos</a></p>
              </div>
            </div>
            <div class="mt-6 flex">
              <div class="flex-shrink-0">
                <mail-icon />
              </div>
              <div class="ml-3 text-base leading-6 text-gray-500">
                <p>contacto@anter.com.mx</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PhoneIcon from "@/components/contact/icons/PhoneIcon";
import MailIcon from "@/components/contact/icons/MailIcon";
export default {
  components: {
    PhoneIcon,
    MailIcon,
  },
};
</script>
