<template>
  <div class="relative max-w-xl mx-auto px-4 lg:max-w-screen-xl">
    <div class="container mx-auto">
      <div class="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-5">
        <div class="col-span-3 md:col-span-3 lg:col-span-3">
          <cars-business-jumbotron/>
        </div>
        <div class="col-span-9 md:col-span-3 lg:col-span-2">
          <cars-business-image/> 
        </div>
      </div>
    </div>
  </div>
  <providers/>
  <cars-business-insurance-experts/>
  <div class="relative max-w-xl mx-auto px-4 lg:max-w-screen-xl">
    <div class="container mx-auto">
      <div class="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-5">
        <div class="col-span-3 md:col-span-3 lg:col-span-12">
          <cars-business-contact-form/>
        </div>
      </div>
    </div>
  </div>
  
</template>
<script>
import CarsBusinessJumbotron from '@/components/carsBusiness/CarsBusinessJumbotron.vue';
import CarsBusinessImage from '@/components/carsBusiness/CarsBusinessImage.vue';
import Providers from '@/components/cars/Providers.vue';
import CarsBusinessInsuranceExperts from '@/components/carsBusiness/CarsBusinessInsuranceExperts.vue';
import CarsBusinessContactForm from '@/components/carsBusiness/CarsBusinessContactForm.vue';



export default {
  components:{
    CarsBusinessJumbotron,
    CarsBusinessImage,
    Providers,
    CarsBusinessInsuranceExperts,
    CarsBusinessContactForm

  },
  setup() {
    
  },
}
</script>
