<template>
  <h3>
    II. DATOS PERSONALES QUE PUEDEN RECABARSE
  </h3>
  <p>
    Anter recabara Datos Personales necesarios para
    cumplir con la adecuada prestación de sus servicios, 
    ya sea directa o personalmente a través de sus promotores, 
    asesores, socios productores y comisionistas. Los Datos 
    Personales podrán incluir todo o alguno de los siguientes:
  </p>
  <ul>
    <li>Datos de Identificación: nombre completo, dirección, teléfono de casa u oficina, celular, estado civil, sexo, lugar y fecha de nacimiento, edad, identificación oficial vigente, registro federal de contribuyentes (RFC), clave única de registro de población (CURP) y correo electrónico.</li>
    <li>Datos Laborales: ocupación, dirección del centro de trabajo, teléfono del centro de trabajo, fecha de ingreso al servicio, nivel del centro de trabajo, nombre del secretario general o representante del centro de trabajo, códigos de ubicación escolar y actividades productivas adicionales.</li>
    <li>Datos de Salud: padecimientos u enfermedades actuales, estatura, peso, tipo de sangre, hábitos de salud como deportes o aficiones practicados, antecedentes de salud familiar, antecedentes penales. Y otros como huella, foto, firma digital, antecedentes penales, etc. Los datos Personales recabados serán tratados bajo estrictas medidas de seguridad, siempre garantizando su confidencialidad.</li>
  </ul>
</template>