<template>
  <div>
    <label>
      <select @change="filterDate($event)" style="width: 200px">
        <option :value="dateRanges[0]">Semanal</option>
        <option :value="dateRanges[1]">Mensual </option>
        <option :value="dateRanges[2]" selected="selected">Anual</option>
        <option :value="dateRanges[3]">Semana pasada</option>
        <option :value="dateRanges[4]">Mes pasado</option>
      </select> 
      <center>Reporte de
        {{ dateFormatForUserView(initialDate) }} al 
        {{ dateFormatForUserView(finalDate) }}
      </center>
    </label>
    <table :id="tableId"
      class="table dataTable no-footer min-w-full divide-y divide-cool-gray-200 pt-8 hover rwd-table" style="width:90%"
      >
      <thead>
        <tr>
          <th class="bg-cool-gray-50 leading-4 font-medium text-cool-gray-500 uppercase" 
            v-for="column in columns"
            :key="column">
            {{ column }}
          </th>
        </tr>
      </thead>
      <tbody>
      </tbody>
    </table>
  </div>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "@/assets/css/custom.css";
import { ref } from "@vue/runtime-core";
import $ from "jquery";
import { dateFormatForUserView } from '@/helpers/date.js';
export default {
  name: "ReportTable",
  props: {
    columns: {type: Array},
    rows: {type: Array},
    search: { type: Boolean, default: function(){ return true}},
    dataLength: {type: Boolean, default: function(){ return true }},
    arrayFields: {type: Array},
    tableId:{type: String},
    dateRanges: {type: Array},
    isOpen: { type: Boolean}
  },
  setup(props) {
    const dataTable = ref(null);
    let Fields = ref([]);
    let initialDate = ref();
    let finalDate = ref();
   const language = {
          decimal: "",
          emptyTable: "No hay información",
          info: "Mostrando _START_ a _END_ de _TOTAL_ Entradas",
          infoEmpty: "Mostrando 0 to 0 of 0 Entradas",
          infoFiltered: "(Filtrado de _MAX_ total entradas)",
          infoPostFix: "",
          thousands: ",",
          lengthMenu: "Mostrar _MENU_ Entradas",
          loadingRecords: "Cargando...",
          processing: "Procesando...",
          search: "Buscar:",
          zeroRecords: "Sin resultados encontrados",
          paginate: {
            first: "Primero",
            last: "Ultimo",
            next: "Siguiente",
            previous: "Anterior",
          },
        }
    async function createTable() {
      Fields.value = await props.arrayFields;
      await props.rows;
      initialDate.value = props.dateRanges[2][0];
      finalDate.value = props.dateRanges[2][1];
      showTable();
    }

    const showTable = () => {
      $.fn.dataTable.ext.errMode = 'none';
      var quantityColumns = 0;
      const columnAlignment = [];
      if(props.rows.length > 0){
        quantityColumns = props.rows[0].length;
        for (let index = 0; index < quantityColumns; index++){
          columnAlignment[index] = {
            "targets": index,
            "className": (index>0)?"text-right cell":"text-left cell",
          };
        }
      }
      let column_values = [];
      Fields.value.forEach((element) => {
        column_values.push({ data: element });
      });
      if ( $.fn.DataTable.isDataTable('#'+props.tableId) ) {
        dataTable.value.destroy();
      }
      dataTable.value = $('#'+props.tableId).DataTable({
        data: props.rows,
        scrollX: false,
        bFilter: props.search,
        bLengthChange: props.dataLength,
        language: language,          
        columnsDefs: columnAlignment,
        columns: column_values,
        pageLength: 10   
      });

      dataTable.value = $('#'+props.tableId).on('click.dt','td', async function(){
        var table = $('#'+props.tableId).DataTable();
        var data = table.row(this).data();
        if(data.show_ext){
          data.respuesta = data.respuesta.match(/.{1,100}/g)[0];
          data.show_ext = false;
        }else if(data.respuesta_ext.length > 100){
          data.respuesta = data.respuesta_ext;
          data.show_ext = true;
        }
        $('#'+props.tableId).dataTable().fnUpdate(data,this,undefined,false);
      });

      $('#'+props.tableId).dataTable.ext.search.push(
     function (settings, data) {
      if(props.isOpen){
      settings = 0;
      initialDate.value = new Date(initialDate.value);
      finalDate.value = new Date(finalDate.value);
      var date = new Date(data[2].split("T")[0]);
      if (( initialDate.value <= date   && finalDate.value === null ) ||
          ( initialDate.value <= date   && date <= finalDate.value )) {
          return true;
      }
      return false;
     }
     return true;
    }
    );
    };

  function filterDate(event){
    var dates = event.target.value.split(",");
    initialDate.value = dates[0];
    finalDate.value = dates[1];
    $('#'+props.tableId).DataTable().draw();
  } 

    return {
      createTable,
      filterDate,
      dateFormatForUserView,
      initialDate,
      finalDate
    };
  },
};
</script>