<template>
  <div class="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
    <div class="mx-auto max-w-7xl w-full text-center lg:text-left">
      <div class="px-4 sm:px-8">
        <p class="text-4xl tracking-tight leading-7 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl lg:text-5xl xl:text-6xl">
          <span class="text-4xl tracking-tight leading-7 font-extrabold sm:text-5xl sm:leading-none md:text-6xl lg:text-5xl xl:text-6xl text-orange-500">
            Tu salud para nosotros
          </span>
          <br />es lo más importante
        </p>
        <p class="mt-3 mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 xl:pr-64">
          En <b>ANTER</b> nos hemos enfocado en la protección de nuestros clientes, por
          ello además de ofrecer un <b>Seguro de Autos</b>, hemos sumado una
          <b>suscripción médica</b> para proteger uno de los aspectos más
          importantes de su vida, la salud.
        </p>
      </div>
    </div>
  </div>
</template>
