<template>
  <loading v-show="isLoading" :enforce-focus="false" :active="true" :can-cancel="false" :color="'#bfd243'" :height="100" :width="350" :is-full-page="false" :loader="loader">
  </loading>
  <div style="min-height: 640px;">
    <div
      class="h-screen flex overflow-hidden bg-white"
      x-data="{ sidebarOpen: false }"
      keydown.window.escape="sidebarOpen = false"
    >
      <dashboard-side-bar-menu />
      <div
        x-init="$el.focus()"
        class="flex-1 overflow-auto focus:outline-none"
        tabindex="0"
      >
        <dashboard-side-bar-menu-mobile />
        <main class="flex-1 relative pb-8 z-0 overflow-y-auto bg-gray-50">
          <dashboard-header />
          <div class="mt-8 max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <div
              class="overflow-hidden rounded-lg flex flex-col justify-betweeen col-span-3"
            >
                <!-- Filters -->
                <div
                  class="
                    md:flex
                    lg:flex
                    xl:flex
                    md:items-center
                    bg-blue-gray-400
                    md:shadow
                    lg:shadow
                    xl:shadow
                    overflow-hidden
                    sm:rounded-lg
                    max-w-auto
                    mt-5
                    py-3
                    mb-5
                    sm:mb-0
                    md:mb-8
                    lg:mb-10
                    xl:mb-10
                    bg-white
                    mx-1
                  "
                >
                  <div
                    class="
                      input-group
                      sm:py-1
                      md:py-1
                      flex-initial
                      my-5
                      md:my-auto
                      lg:my-auto
                      xl:my-auto
                      mx-5
                      mt-6
                    "
                  >
                    <label class="m-auto mr-3 text-md leading-7 font-medium text-cool-gray-900">Fecha inicial:</label>
                  
                    <DatePicker :max-date='new Date()' v-model="dateStart.data">
                      <template v-slot="{ inputValue, inputEvents }">
                        <input
                          class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                          :value="inputValue"
                          v-on="inputEvents"
                          placeholder="00/00/0000"
                        />
                      </template>
                    </DatePicker>
                    <small
                      v-show="dateStart.error"
                      class="message-alert text-red-500 block text-right"
                      >{{ dateStart.message }}</small
                    >
                  </div>

                  <div
                    class="
                      input-group
                      sm:py-1
                      md:py-1
                      flex-initial
                      my-5
                      md:my-auto
                      lg:my-auto
                      xl:my-auto
                      mx-5
                    "
                  >
                    <label class="m-auto mr-3 text-md leading-7 font-medium text-cool-gray-900">Fecha final:</label>
                    <DatePicker :max-date='new Date()' v-model="dateEnd.data">
                      <template v-slot="{ inputValue, inputEvents }">
                        <input
                          class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                          :value="inputValue"
                          v-on="inputEvents"
                          placeholder="00/00/0000"
                        />
                      </template>
                    </DatePicker>
                    <small
                      v-show="dateEnd.error"
                      class="message-alert text-red-500 block text-right"
                      >{{ dateEnd.message }}</small
                    >
                  </div>
                  <div class="input-group flex-initial my-auto px-5 md:px-5 lg:px-0 xl:px-0">
                    <button
                      class="
                        w-full
                        md:w-auto
                        lg:w-2/2
                        xl:w-2/2
                        sm:mx-auto
                        md:mx-auto
                        lg:mx-2
                        xl:mx-2
                        my-1
                        shadow
                        bg-green-500
                        hover:bg-green-600
                        focus:shadow-outline focus:outline-none
                        text-white
                        font-bold
                        py-2
                        px-4
                        rounded
                        inline-flex 
                        items-center
                      "
                      type="button"
                      @click="getQuotesByDateRange()"
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                    </svg>
                      Buscar
                    </button>

                    <button
                      class="
                        w-full
                        md:w-auto
                        lg:w-2/2
                        xl:w-2/2
                        sm:mx-auto
                        md:mx-auto
                        lg:mx-2
                        xl:mx-2
                        my-1
                        shadow
                        bg-orange-400
                        hover:bg-orange-600
                        focus:shadow-outline focus:outline-none
                        text-white
                        font-bold
                        py-2
                        px-4
                        rounded
                        inline-flex 
                        items-center
                      "
                      @click="exportExcel()"
                      type="button"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                      </svg>
                      Exportar
                    </button>
                  </div>
                </div>
                <!-- End Filters -->
              <dashboard-quotes-table v-show="!isLoading" class="pb-20" ref="quoteList" :title="'Cotizaciones'" :columns="columns" :data="quotes" />
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardSideBarMenu from "@/components/dashboard/DashboardSideBarMenu";
import DashboardSideBarMenuMobile from "@/components/dashboard/DashboardSideBarMenuMobile";
import DashboardHeader from "@/components/dashboard/DashboardHeader";
import DashboardQuotesTable from "@/components/dashboard/reports/DashboardQuotesTable.vue";
import { onMounted, ref } from "@vue/runtime-core";
import XLSX from "xlsx";
import Quotes from "@/classes/quote.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { dateFormatForUserView, dateFormatForSystem } from '@/helpers/date.js';
import { DatePicker } from 'v-calendar';
export default {
  components: {
    DashboardHeader,
    DashboardSideBarMenuMobile,
    DashboardSideBarMenu,
    DashboardQuotesTable,
    Loading,
    DatePicker
  },
  setup() {
    
    const quoteList = ref(null);
    const isLoading = ref(true);
    const dateStart = ref({
      data: null,
      error: false,
      message: ""
    });
    const dateEnd = ref({
      data: null,
      error: false,
      message: ""
    });
    const quotes = ref([]);
    const quotesExport = ref([]);
    const columns = ref([]);
    const datesValidate = (start, end) =>  {
      dateStart.value.error = false;
      dateEnd.value.error = false;
      dateStart.value.message = "";
      dateEnd.value.message = "";
      
      if(typeof start == 'undefined' || start == "" || start == null){
        dateStart.value.error = true;
        dateStart.value.message = "Campo requerido";
        return false;
      }else if(typeof end == 'undefined' || end == "" || end == null){
        dateEnd.value.error = true;
        dateEnd.value.message = "Campo requerido";
        return false;
      }
      start = dateFormatForSystem(start);
      end = dateFormatForSystem(end);
     if(start > end){
        dateStart.value.error = true;
        dateStart.value.message = "La fecha inical supera a la final";
        return false;
     }
      return true;
    }
    const exportExcel = () => {
      let dataJson = XLSX.utils.json_to_sheet(quotesExport.value);
      const workbook = XLSX.utils.book_new();
      const filename = `Cot_${dateFormatForSystem(dateStart.value.data)}_${dateFormatForSystem(dateEnd.value.data)}`;
      XLSX.utils.book_append_sheet(workbook, dataJson, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    };
    const getQuotesByDateRange = async() => {
      
      quotes.value = [];
      quoteList.value.createTable();
      
      isLoading.value = true;
      if(!datesValidate(new Date(dateStart.value.data), new Date(dateEnd.value.data))){
        isLoading.value = false;
        return false;
      }
      const data = [];
      Quotes.getQuotesByAsociate(dateFormatForSystem(new Date(dateStart.value.data)), dateFormatForSystem(new Date(dateEnd.value.data))).then(res => {
        
        const response = res.response.data;
        for (let index = 0; index < response.length; index++) {
          quotesExport.value.push({'Folio': response[index].quote_number,'Cliente': response[index].client_name, "Vehículo": response[index].description_vehicle, "Año del vehículo": response[index].year_vehicle, 'Covertura': response[index].coverage,'Fecha': dateFormatForUserView(response[index].date_quote.split('T')[0])});
          data.push([response[index].quote_number, response[index].client_name, response[index].description_vehicle, response[index].year_vehicle, response[index].coverage, dateFormatForUserView(response[index].date_quote.split('T')[0])]);
        }
        setTimeout(() => {
          isLoading.value = false;
          quotes.value = data;
          quoteList.value.createTable();
        }, 2000);
      }).catch(err => {
        isLoading.value = false;
        console.log(err);
      });
      return true;
    }
    onMounted(() => {
      setTimeout(() => {
        isLoading.value = false;
        columns.value.push([ {title:'Folio'}, {title:'Cliente'},  {title: 'Vehículo'},  {title: 'Año del vehículo'},  {title:'Covertura'}, {title:'Fecha'}]);
        quotes.value = [];
        quoteList.value.createTable();
      }, 2000);
    });
    return {
      dateStart,
      dateEnd,
      datesValidate,
      exportExcel,
      getQuotesByDateRange, 
      quotes,
      columns,
      quoteList,
      isLoading
    }
  },
  methods: {
    toggleInputPassword(){
      this.showInputPassword = !this.showInputPassword;
    }
  }
};
</script>