<template>
  <div
    class="bg-white shadow-lg border p-4 rounded-lg py-3 px-1 max-w-3xl m-auto mb-5 truncate"
  >
    <div class="grid grid-cols-1 sm:grid-cols-5 pl-5 sm:pl-0">
      <div class="sm:col-span-1">
        <div class="w-full h-full pt-10">
          <img
            v-if="userObj.persona.sexo == null"
            style="margin: 0 auto"
            class="hidden sm:block h-30 w-20 rounded-full ml-5 m-auto"
            src="@/assets/images/dashboard/usuario.png"
            alt=""
          />
          <img
            v-if="userObj.persona.sexo == 'Masculino'"
            style="margin: 0 auto"
            class="hidden sm:block h-30 w-20 rounded-full ml-5 m-auto"
            src="@/assets/images/dashboard/hombre.png"
            alt=""
          />
          <img
            v-if="userObj.persona.sexo == 'Femenino'"
            style="margin: 0 auto"
            class="hidden sm:block h-30 w-20 rounded-full ml-5 m-auto"
            src="@/assets/images/dashboard/mujer.png"
            alt=""
          />
        </div>
      </div>
      <div class="sm:col-span-3" @click="OpenModalMovil()">
        <p class="w-full truncate">Nombre</p>

        <div class="mt-2 truncate">
          <strong>{{ userObj.persona.nombres }} {{ userObj.persona.apellido_paterno }}</strong>
        </div>

        <div class="mt-2 truncate">
          <p class="truncate">Correo electronico</p>
          <strong>{{ userObj.email }}</strong>
        </div>

        <div class="mt-2 truncate">
          <p class="truncate">Fecha alta</p>
          <strong>{{ $filters.parseDate(userObj.created_at) }}</strong>
        </div>
      </div>

      <div>
        <div class="mr-10 sm:text-right">
          <div>
            <div class="hidden sm:block">
              <button
                v-on:click="OpenModal()"
                class="bg-orange-700 px-3 py-2 rounded-lg hover:bg-orange-500 focus:outline-none text-white mb-6"
                id="btn-edit"
              >
                Editar
              </button>
            </div>
          </div>

          <div>
            <div class="flex flex-col-reverse sm:block sm:col">
              <div
                class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
              >
                <input
                  for="btn-is-active"
                  v-if="userObj.status == true"
                  v-on:click="changeStateUser"
                  type="checkbox"
                  name="toggle"
                  id="btn-is-active"
                  checked
                  class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                />
                <input
                  for="btn-is-active"
                  v-if="userObj.status == false"
                  v-on:click="changeStateUser"
                  type="checkbox"
                  name="toggle"
                  id="btn-is-active"
                  class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                />
                <label
                  class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                ></label>
                <label
                  v-if="userObj.status == false || userObj.status == ''"
                  class="text-xs text-gray-700"
                  >Inactivo</label
                >
                <label v-if="userObj.status == true" class="text-xs text-gray-700"
                  >Activo</label
                >
              </div>
              <div class="mt-2 sm:mt-6 w-full h-full">
                <p class="w-full">Rol</p>
                <strong>{{ userObj.rol }}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal de usuario -->
  <div class="modal show-modal-active" v-if="ShowModalEditUser">
    <div class="z-10 w-11/12 sm:w-4/6 m-auto bg-white border-2 p-5 modal-active">
      <div id="modal-header" class="flex">
        <div class="w-6/12">
          <h3 class="text-3xl font-medium leading-5 text-gray-700">Editar usuario</h3>
        </div>
        <div class="w-6/12 text-right">
          <label
            class="text-gray-400 text-xl hover:text-gray-700 transition duration-150"
            v-on:click="CloseModal()"
            >X</label
          >
        </div>
      </div>
      <div class="border-t-2 w-full mb-5 mt-5"></div>
      <div id="modal-body mb-5">
        <div class="block sm:flex">
          <div class="px-3 w-full mt-5">
            <label
              class="block text-sm font-medium leading-5 text-gray-700"
              :class="{ 'text-red-600': v$.nombres.$error }"
              >Nombres</label
            >
            <input
              v-model="userFormEdit.nombres"
              type="text"
              class="form-input py-3 px-4 block w-full transition ease-in-out duration-150"
              :class="{ 'bg-red-100 border-red-400': v$.nombres.$error }"
              id="input_name"
            />
            <div v-if="v$.nombres.$error" class="text-red-600">
              <span>
                {{ v$.nombres.$errors[0].$message }}
              </span>
            </div>
          </div>
        </div>
        <div class="block sm:flex">
          <div class="px-3 w-full mt-5">
            <label class="block text-sm font-medium leading-5 text-gray-700"
              >Primer apellido</label
            >
            <input
              v-model="userFormEdit.apellido_paterno"
              type="text"
              class="form-input apellido_materno-3 px-4 block w-full transition ease-in-out duration-150"
              :class="{ 'bg-red-100 border-red-400': v$.apellido_paterno.$error }"
              id="apellido_paterno"
            >
            <div v-if="v$.apellido_paterno.$error" class="text-red-600">
              <span>
                {{ v$.apellido_paterno.$errors[0].$message }}
              </span>
            </div>
          </div>
          <div class="px-3 w-full mt-5">
            <label class="block text-sm font-medium leading-5 text-gray-700"
              >Segundo apellido</label
            >
            <input
              v-model="userFormEdit.apellido_materno"
              type="text"
              class="form-input py-3 px-4 block w-full transition ease-in-out duration-150"
              :class="{ 'bg-red-100 border-red-400': v$.apellido_materno.$error }"
              id="input_maternal_name"
            />
            <div v-if="v$.apellido_materno.$error" class="text-red-600">
              <span>
                {{ v$.apellido_materno.$errors[0].$message }}
              </span>
            </div>
          </div>
        </div>
        <div class="block sm:flex">
          <div class="px-3 w-full mt-5">
            <label class="block text-sm font-medium leading-5 text-gray-700"
              >Correo electrónico</label
            >
            <input
              v-model="userFormEdit.email"
              type="text"
              class="form-input py-3 px-4 block w-full transition ease-in-out duration-150"
              :class="{ 'bg-red-100 border-red-400': v$.email.$error }"
            />
            <div v-if="v$.email.$error" class="text-red-600">
              <span>
                {{ v$.email.$errors[0].$message }}
              </span>
            </div>
          </div>

          <div class="px-3 w-full mt-5">
            <label class="block text-sm font-medium leading-5 text-gray-700"
              >Número de teléfono</label
            >
            <input
              v-model="userFormEdit.telefono"
              type="text"
              class="form-input py-3 px-4 block w-full transition ease-in-out duration-150"
              :class="{ 'bg-red-100 border-red-400': v$.telefono.$error }"
              id="input_cellphone"
            />
            <div v-if="v$.telefono.$error" class="text-red-600">
              <span>
                {{ v$.telefono.$errors[0].$message }}
              </span>
            </div>
          </div>
        </div>
        <div class="block sm:flex">
          <div class="px-3 w-full mt-5">
            <label
              class="block text-sm font-medium leading-5 text-gray-700"
              :class="{ 'text-red-600': v$.rol.$error }"
              >Roles</label
            >
            <select
              v-model="userFormEdit.rol"
              name="select-role"
              id="select-role"
              class="form-select w-full"
              :class="{ 'bg-red-100 border-red-400': v$.rol.$error }"
            >
              <option v-for="rol in roles" :key="rol" :value="rol.nombre">
                {{ rol.nombre.toUpperCase() }}
              </option>
            </select>
            <div v-if="v$.rol.$error" class="text-red-600">
              <span>
                {{ v$.rol.$errors[0].$message }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="border-t-2 w-full mb-5 mt-5"></div>
      <div id="modal-footer">
        <div class="text-right">
          <button
            id="btn-save-user"
            v-on:click="saveDataUser()"
            class="bg-white hover:transition duration-150 border-green-700 hover:bg-green-500 hover:border-green-500 text-green-700 hover:text-white px-3 py-2 border border-green-600 rounded-xl focus:outline-none focus:border-green-700"
          >
            Guardar
          </button>
          <button
            id="btn-closed"
            class="bg-white hover:transition duration-150 hover:bg-orange-500 hover:border-orange-500 text-orange-700 hover:text-white px-3 py-2 border border-orange-700 rounded-xl ml-3 focus:outline-none focus:shadow-outline-orange focus:border-orange-700"
            v-on:click="CloseModal()"
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal de usuario -->
</template>

<script>
import $ from "jquery";
import { onMounted, ref, computed } from "vue";
import { required, minLength, helpers, email } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import swal from "sweetalert2";
import User from "@/classes/User.js";
import {
  hasCharactersNumbers,
  hasSpecialcharacters,
  hasLetters,
} from "@/helpers/text.js";
import Roles from "@/classes/Roles.js";
export default {
  name: "CardsUsers",
  props: {
    userObj: Array,
  },
  setup(props, { emit }) {
    let roles = ref([]);
    let ShowModalEditUser = ref(false);
    let screen = ref(false);
    let userFormEdit = ref({
      email: "",
      apellido_materno: "",
      nombres: "",
      apellido_paterno: "",
      telefono: "",
      sexo: "",
      rol: "",
      status: props.userObj.status
    });
    const rules = computed(() => {
      return {
        nombres: {
          required: helpers.withMessage("El campo NOMBRES es requerido", required),
          minLength: helpers.withMessage(
            "El campo NOMBRES tiene que tener como minimo 4 caracteres",
            minLength(4)
          ),
          hasCharactersNumbers: helpers.withMessage(
            "Este campo no puede contener números",
            hasCharactersNumbers
          ),
          hasSpecialcharacters: helpers.withMessage(
            "Este campo no permite caracteres especiales",
            hasSpecialcharacters
          ),
        },
        apellido_paterno: {
          required: helpers.withMessage(
            "El campo APELLIDO PATERNO es requerido",
            required
          ),
          hasCharactersNumbers: helpers.withMessage(
            "Este campo no puede contener números",
            hasCharactersNumbers
          ),
          hasSpecialcharacters: helpers.withMessage(
            "Este campo no permite caracteres especiales",
            hasSpecialcharacters
          ),
        },
        apellido_materno: {
          required: helpers.withMessage(
            "El campo APELLIDO MATERNO es requerido",
            required
          ),
          hasCharactersNumbers: helpers.withMessage(
            "Este campo no puede contener números",
            hasCharactersNumbers
          ),
          hasSpecialcharacters: helpers.withMessage(
            "Este campo no permite caracteres especiales",
            hasSpecialcharacters
          ),
        },
        email: {
          required: helpers.withMessage(
            "El campo CORRERO ELECTRÓNICO es requerido",
            required
          ),
          email: email,
        },
        telefono: {
          required: helpers.withMessage(
            "El campo NÚMERO DE TELÉFONO es requerido",
            required
          ),
          hasLetters: helpers.withMessage(
            "Este campo no permite ningun tipo de letra",
            hasLetters
          ),
          minLength: helpers.withMessage(
            "El campo NÚMERO DE TELÉFONO tiene que tener como minimo 10 caracteres",
            minLength(10)
          ),
        },
        rol: {
          required: helpers.withMessage("Porfavor de seleccionar una opción", required),
        },
      };
    });
    const v$ = useVuelidate(rules, userFormEdit);
    onMounted(async () => {
      assigDataUserForModel();
      screen.value = window.screen.width <= 639 ? true : false;
      $(window).resize(function () {
        screen.value = window.screen.width <= 639 ? true : false;
      });
      const rolesClass = new Roles("/empleados/consultar_roles");
      rolesClass
        .getAllRoles()
        .then((res) => {
          if (res.status == 200) {
            roles.value = res.data.respuesta.informacion;
          }
        })
        .catch((error) => console.log(error));
    });
    function OpenModalMovil() {
      if (screen.value) {
        OpenModal();
      }
    }
    function assigDataUserForModel() {
      userFormEdit.value = JSON.parse(
        JSON.stringify({
          nombres: props.userObj.persona.nombres,
          apellido_materno: props.userObj.persona.apellido_materno,
          email: props.userObj.email,
          sexo: props.userObj.persona.sexo,
          telefono: props.userObj.persona.telefono,
          apellido_paterno: props.userObj.persona.apellido_paterno,
          rol: props.userObj.rol,
          status: props.userObj.status
        })
      );
    }
    function changeStateUser() {
      const dateUserInfo = new User(
        userFormEdit.value.nombres,
        userFormEdit.value.apellido_paterno,
        userFormEdit.value.apellido_materno,
        userFormEdit.value.sexo,
        userFormEdit.value.telefono,
        userFormEdit.value.email,
        userFormEdit.value.role,
        userFormEdit.value.status
      );
      dateUserInfo
        .updateUserStatus(!props.userObj.status)
        .then((x) => {
          swal.fire({
            icon: "success",
            title: x.data.respuesta.mensaje,
            showConfirmButton: false,
            timer: 1500,
          });
          emit("reload_info_user");
          CloseModal();
        })
        .catch((error) => {
          if (error.response.status == 401 || error.response.status == 422) {
            swal.fire({
              icon: "error",
              title: error.response.data.response.mensaje,
              text: error.response.data.errors[0],
              showConfirmButton: false,
            });
          }
          emit("reload_info_user");
        });
    }
    function Showsitch() {
      this.showsitch = !this.showsitch;
    }
    function OpenModal() {
      assigDataUserForModel();
      ShowModalEditUser.value = true;
    }
    function CloseModal() {
      ShowModalEditUser.value = false;
    }
    async function saveDataUser() {
      sweetalertLoading();
      this.v$.$validate();
      if (!this.v$.$error) {
        const dateUserInfo = new User(
          userFormEdit.value.nombres,
          userFormEdit.value.apellido_paterno,
          userFormEdit.value.apellido_materno,
          userFormEdit.value.telefono,
          userFormEdit.value.email,
          userFormEdit.value.rol,
          userFormEdit.value.status
        );
        dateUserInfo
          .updateUserInfoEmployee(userFormEdit.value, props.userObj.id)
          .then(() => {
            swal.fire({
              position: "center",
              icon: "success",
              title: "¡Datos actualizados con éxito!",
              showConfirmButton: false,
              timer: 1500,
            });
            emit("reload_info_user");
            CloseModal();
          })
          .catch((error) => {
            if (error.response.status == 401 || error.response.status == 422) {
              swal.fire({
                icon: "error",
                title: error.response.data.response.mensaje,
                text: error.response.data.errors[0],
                showConfirmButton: false,
              });
            }
            emit("reload_info_user");
          });
      }else {
        swal.fire({
          icon: "error",
          title: "Error de validaciones",
          text: "Favor de corregir las validaciones",
        });
      }
    }
    function sweetalertLoading() {
      swal.fire({
        title: "Espere un momento...!",
        timerProgressBar: true
      });
    }
    return {
      changeStateUser,
      Showsitch,
      OpenModal,
      CloseModal,
      saveDataUser,
      OpenModalMovil,
      userFormEdit,
      v$,
      ShowModalEditUser,
      roles,
    };
  },
};
</script>

<style>
.toggle-checkbox:checked {
  @apply: right-0 border-green-400;
  right: 0;
  border-color: #68d391;
}
.toggle-checkbox:checked + .toggle-label {
  @apply: bg-green-400;
  background-color: #68d391;
}
/* :focus {
  outline: none;
} */
.modal-active {
  align-items: center;
  position: relative;
  z-index: 1000;
}
.modal {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  align-items: center;
  pointer-events: none;
  z-index: 1000;
}
.show-modal-active {
  display: flex;
  opacity: 1;
  align-items: center;
  pointer-events: auto;
  z-index: 1000;
}
@media (max-width: 900px) {
  .modal-active {
    margin-top: 75vh;
    margin-bottom: 100px;
  }
  .modal {
    overflow-y: scroll;
  }
}
</style>