<template>
  <div>   
    <div class="h-screen flex overflow-hidden bg-white" x-data="{ sidebarOpen: false }" keydown.window.escape="sidebarOpen = false">
      <side-bar-menu/>
      <div x-init="$el.focus()" class="flex-1 overflow-auto focus:outline-none" tabindex="0">
        <side-bar-menu-mobile/>
        <main class="flex-1 relative pb-8 z-0 overflow-y-auto bg-gray-50">
          <header-component/>
          <main-component />
        </main>
        <div class="body">
          <img class="h-4 w-auto mx-auto" 
                      src="@/assets/images/publicidad.png"
                      />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MainComponent from "@/components/customer/MainComponent.vue";
import HeaderComponent from "@/components/customer/HeaderComponent.vue";
import SideBarMenu from '@/components/customer/SideBarMenu';
import SideBarMenuMobile from '@/components/customer/SideBarMenuMobile';
export default {
  components: {
    HeaderComponent,
    MainComponent,
    SideBarMenu,
    SideBarMenuMobile
    
  },
  setup() {
  },
};
</script>
<style scoped>
.body{
  display: flex;
  justify-content: center;
}
img{
  padding-top: 5%;
  width: 680px;
  height: 498px;
}
</style>
