<template>
  <div id="trigger-benefits" data-aos="zoom-in-left" class="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-24 lg:text-left mt-12">
    <div class="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
      <h2 class="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl lg:text-5xl xl:text-6xl">
        La experiencia que
        <br class="xl:hidden" />
        <span class="text-4xl tracking-tight leading-10 font-extrabold sm:text-5xl sm:leading-none md:text-6xl lg:text-5xl xl:text-6xl text-orange-500">
          protege todos los aspectos de tu vida
        </span> 
        en un mismo lugar
      </h2>
      <div class="mt-10 sm:flex sm:justify-center lg:justify-start">
        <div class="rounded-md shadow">
          <a  href="cotizacion" class="w-full flex items-center justify-center px-8 py-3 border
              border-transparent text-base leading-6 font-medium rounded-md text-white bg-orange-500
              hover:bg-orange-400 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange 
              transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10 ">
            Cotizar
          </a>
        </div>
        <div class="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
          <a href="./#nuestros-productos" class="w-full flex items-center justify-center px-8 py-3 
          border  text-base leading-6 font-medium rounded-md text-orange-500 
          bg-white hover:text-orange-400 focus:outline-none focus:border-orange-300 
          focus:shadow-outline-orange transition duration-150 ease-in-out
           md:py-4 md:text-lg md:px-10">
            Nuestros productos
          </a>
        </div>
      </div>
    </div>
  <trigger-benefits-animation/>
  </div>
  
</template>
<script>
import TriggerBenefitsAnimation from "@/components/main-page/TriggerBenefitsAnimation.vue";
// import Swal from "sweetalert2";
import {
  onMounted
} from "vue";
export default {
  name: 'TriggerBenefits', 
  components:{
    TriggerBenefitsAnimation
  },
  setup() {
    onMounted(async () => {
      
      // Swal.fire({
      //   imageUrl: "https://i.ibb.co/bJw1rJT/giveaway-mobile.jpg",
      //   showCloseButton: true,
      //   showDenyButton: true,
      //   confirmButtonText: 'Participar',
      //   denyButtonText: 'No Participar',
      //   imageHeight: '100%',
      //   imageWidth: '100%',
      //   showClass: {
      //     popup: 'animate__animated animate__fadeInDown'
      //   },
      //   hideClass: {
      //     popup: 'animate__animated animate__fadeOutUp'
      //   }
      // }).then((result) => {
      //   if (result.isConfirmed){
      //     window.open("https://www.facebook.com/anter.mx/photos/a.105211520910249/707737790657616");
      //   }
      // });
    })
    return {
      
    }
  },
};
</script>