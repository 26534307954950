<template>
  <main class="lg:relative pt-12">
    <cars-jumbotron/>
    <providers/>
    <insurance-experts/>
  </main>
</template>
<script>
import CarsJumbotron from '@/components/cars/CarsJumbotron.vue';
import Providers from '@/components/cars/Providers.vue';
import InsuranceExperts from '@/components/cars/InsuranceExperts.vue';



export default {
  components:{
    CarsJumbotron,
    Providers,
    InsuranceExperts
  },
  setup() {
    
  },
}
</script>
