<template>
  <div class="vld-parent">
    <loading :active="isLoading" :can-cancel="false" :color="'#bfd243'" :height="100" :width="350" :is-full-page="true" :loader="loader">
    </loading>
  </div>
  
  <div id="form-cotizacion" class="max-w-screen-xl mx-auto py-6 px-4 sm:px-6 lg:py-6 lg:px-8">
    <div class="relative px-4 sm:px-6 lg:px-8">
      <div class="general-data-title">
        <h1 class="center">
          Datos generales del vehículo
        </h1>
        <p class="center">Para solicitar una cotización, debes llenar el siguiente formulario con datos personales y de tu vehículo.</p>
      </div>
      <div class="content-background">
        <!-- <form @submit="sendRequest($event)">   -->
          <div class="main_container row-equal datos-vehiculo">
            <div class="col13">
              <div class="container">
                <div class="imgCenter center">
                  <img src="@/assets/images/radames/iconos/cobertura.svg" alt="Radames1"/>
                </div>
                <h3 class="center"><span>Tipo cobertura</span></h3>
                <div class="input center">
                  <select class="ajuste_inputs" v-model="coverage" >
                    <option selected hidden value="">- Seleccionar -</option>
                    <option value="Amplia">Amplia</option>
                    <option value="Limitada">Limitada</option>
                    <option value="Responsabilidad civil">Responsabilidad civil</option>
                    <option value="Uber">Uber</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col13">
              <div class="container">
                <div class="imgCenter">
                  <img src="@/assets/images/radames/iconos/tipo_auto.svg" alt="Radames2"/>
                </div>
                <h3 class="center"><span>tipo de auto</span></h3>
                <div class="input center">
                  <select class="ajuste_inputs" @change="getModels" v-model="type">
                    <option selected hidden value="">- Seleccionar -</option>
                    <option data-val="1" value="Sedan">Sedan</option>
                    <option data-val="2" value="Pickup">Pickup</option>
                  </select><br />
                </div>
              </div>
            </div>
            <div class="col13">
              <div class="container">
                <div class="imgCenter"><img src="@/assets/images/radames/iconos/anio.svg" alt="Radames3"/></div>
                <h3 class="center"><span>Año</span></h3>
                <div class="input center">
                  <select class="ajuste_inputs" @change="getBrands" v-model="year" required>
                    <option disabled value="0" selected>Seleccionar</option>
                    <option
                      v-for="option in model_catalog_list"
                      :key="option.value"
                      :value="option.value"
                      :name="option.id"
                      >{{ option.value }}</option
                    >
                  </select>
                </div>
              </div>
            </div>
            <div class="col13">
              <div class="container">
                <div class="imgCenter"><img src="@/assets/images/radames/iconos/marca.svg" alt="Radames4"/></div>
                <h3 class="center"><span>marca</span></h3>
                <div class="input center">
                  <select class="ajuste_inputs" @change="getSubBrands" v-model="brand" required>
                    <option disabled value="0" selected>Seleccionar</option>
                    <option
                      v-for="param in brand_catalog_list"
                      :key="param.id"
                      :value="param.value"
                      :name="param.id"
                      >{{ param.value }}</option
                    >
                  </select><br>
                </div>
              </div>
            </div>
            <div class="col13">
              <div class="container">
                <div class="imgCenter"><img src="@/assets/images/radames/iconos/modelo.svg" alt="Radames5"/></div>
                <h3 class="center"><span>Modelo</span></h3>
                <div class="input center">
                  <select class="ajuste_inputs" @change="getDescriptions" v-model="subBrand" required>
                    <option disabled value="0" selected>Seleccionar</option>
                    <option
                      v-for="param in sub_brand_catalog_list"
                      :key="param.id"
                      :value="param.value"
                      :name="param.id"
                      >{{ param.value }}</option
                    >
                  </select><br>
                </div>
              </div>
            </div>
            <div class="col13">
              <div class="container">
                <div class="imgCenter"><img src="@/assets/images/radames/iconos/version.svg" alt="Radames6"/></div>
                <h3 class="center"><span>versión</span></h3>
                <div class="input center">
                  <select class="ajuste_inputs" v-model="version">
                    <option disabled value="0" selected>Seleccionar</option>
                    <option
                      v-for="param in description_catalog_list"
                      :key="param.id"
                      :value="param.value"
                      :name="param.id"
                      >{{ param.value }}</option
                    >
                  </select><br>
                </div>
              </div>
            </div>
            <div class="col13">
              <div class="container">
                <div class="imgCenter"><img src="@/assets/images/radames/iconos/codigo_postal.svg" alt="Radames7"/></div>
                <h3 class="center"><span>código postal</span></h3>
                <div class="input center">
                  <input type="number" class="ajuste_inputs" v-model="postalCode" minlength="4" ><br>
                </div>
              </div>
            </div>
            <div class="col13">
              <div class="container">
                <div class="imgCenter"><img src="@/assets/images/radames/iconos/sexo.svg" alt="Radames8"/></div>
                <h3 class="center"><span>sexo</span></h3>
                <div class="input center">
                  <select class="ajuste_inputs" v-model="gender" >
                    <option selected hidden value="">- Seleccionar -</option>
                    <option value="Masculino">Masculino</option>
                    <option value="Femenino">Femenino</option>
                  </select><br>
                </div>
              </div>
            </div>
            <div class="col13">
              <div class="container">
                <div class="imgCenter"><img src="@/assets/images/radames/iconos/fecha_nacimiento.svg" alt="Radames9"/></div>
                <h3 class="center"><span>Fecha nacimiento</span></h3>
                <div class="input center">
                  <DatePicker :max-date='new Date().setFullYear(new Date().getFullYear()-18)' v-model="birthDate.data">
                    <template v-slot="{ inputValue, inputEvents }">
                      <input placeholder="dd/mm/yyyy" class="ajuste_inputs" :value="inputValue" v-on="inputEvents" id="fecha-nacimientot"/>
                    </template>
                  </DatePicker>
                  <!-- <input type="text" name="solicitud[nacimiento]" placeholder="MM/DD/AAAA"  id="fecha-nacimiento"><br> -->
                </div>
                
              </div>
            </div>
            <div class="col13">
              <div class="container">
                <div class="imgCenter"><img src="@/assets/images/radames/iconos/nombre.svg" alt="Radames10"/></div>
                <h3 class="center"><span>Nombre completo</span></h3>
                <div class="input center">
                  <input type="text" class="ajuste_inputs" v-model="fullName" minlength="4" ><br>
                </div>
              </div>
            </div>
            <div class="col13">
              <div class="container">
                <div class="imgCenter center">
                  <img src="@/assets/images/radames/iconos/correo.svg" alt="Radames11"/>
                </div>
                <h3 class="center"><span>Correo electrónico</span><label style="color: red; font-size: 10px; display: none;">Requerido</label></h3>
                <div class="input center">
                  <input type="email" class="ajuste_inputs" v-model="email" minlength="4"  email><br>
                </div>
              </div>
            </div>
            <div class="col13">
              <div class="container">
                <div class="imgCenter center">
                  <img src="@/assets/images/radames/iconos/celular.svg" alt="Radames12"/>
                </div>
                <h3 class="center"><span>celular</span></h3>
                <div class="input center">
                  <input type="number" class="ajuste_inputs" v-model="phone" minlength="10"><br>
                </div>
              </div>
            </div>
            <div class="justify-between  mt-16">
              <span class="rounded-md shadow-sm">
                <button type="submit" id="btn_pay" @click="sendRequest()" class="btn focus:shadow-outline-orange active:bg-orange-700">
                  Solicitar Cotizaciones
                </button>
              </span>
            </div>
          </div>
        <!-- </form> -->
      </div>
    </div>
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from "axios";
import '@/assets/css/radames.css';
import SendEmail from "@/classes/SendEmail.js";
import { DatePicker } from 'v-calendar';
import moment from 'moment';
import swal from "sweetalert2";
import {
  ref
} from "vue";
export default {
  name: "LandingContainer",
  components:{
    Loading,
    DatePicker
  },
  setup(){
    const coverage = ref("");
    const type = ref("");
    const year = ref("");
    const brand = ref("");
    const subBrand = ref("");
    const version = ref("");

    const fullName = ref("");
    const email = ref("");
    const phone = ref("");
    const gender = ref("");
    const postalCode = ref("");
    const birthDate = ref({
      data: moment(new Date()).format("DD/MM/YYYY")
    });

    const selectType = ref("");
    const selectYear = ref([]);
    const selectBrand = ref([]);
    const selectSubBrand = ref([]);
    const selectDescription = ref([]);
    const selectCoverage = ref("");

    const anterapi = process.env.VUE_APP_ANTERAPI;
    let config = ref({
      headers: {
        'Content-Type': 'application/json'
      }
    }); 
    const isLoading = ref(false);
    const model_catalog_list = ref ([]);
    const brand_catalog_list = ref ([]);
    const sub_brand_catalog_list = ref ([]);
    const description_catalog_list = ref ([]);

    

    async function sendRequest() {
      sweetalertLoading();
      const carInfo = ref({
        coverage: coverage.value,
        type: type.value,
        year: year.value,
        brand: brand.value,
        sub_brand: subBrand.value,
        version: version.value
      });

      const prospectInfo = ref({
        name: fullName.value,
        email: email.value,
        phone: phone.value,
        gender: gender.value,
        postal_code: postalCode.value,
        birth_date: birthDate.value.data
      });

      const sendEmail = new SendEmail();
      sendEmail.sendRadamesRequest(
        carInfo.value, prospectInfo.value
      ).then((response) => {
        if(response.status == 200){
          swal.fire({
            position: "center",
            icon: "success",
            title: "Su solicitud ha sido enviada, en máximo 24 horas recibirás tus cotizaciones.<br>¡Gracias por su preferencia!",
            showConfirmButton: true,
          }).then((result) => {
            if(result.isConfirmed){
              window.location.href = "./radames";
            }
          });

        }else{
          if(response.status == 500){
            swal.fire({
              icon: "error",
              title: "Error",
              text: "Lo sentimos, ocurrió un error al enviar tu solicitud.<br>Intentalo de nuevo mas tarde.",
              showConfirmButton: false,
            });
          }else{
            swal.fire({
              icon: "error",
              title: response.data.respuesta,
              text: response.data.errores[0],
              showConfirmButton: false,
            });
          }
        }    
      })
      .catch((error) => {
        if (error.response.status == 401 || error.response.status == 422) {
          "Lo sentimos, ocurrió un error al enviar tu solicitud.<br>Intentalo de nuevo mas tarde."
        }
      }).finally(() => {
        
      })
    }
    
    const getModels = async (event) => {
      isLoading.value = true;
      model_catalog_list.value = [];
      selectType.value = event.target.options.selectedIndex;
      console.log("tipo auto seleccionado: ", selectType.value);
      var data = {
        "tipo": selectType.value
      }

      let response = await axios.get(anterapi + '/autos/catalogo_apro/obtener_modelos',{params: data}, config.value);
      if(response.status == 200){
        response.data.respuesta.resultado.forEach((element) => {
          model_catalog_list.value.push({
            id: element.id,
            value: element.value
          })
        })
        
        brand_catalog_list.value = [];
        sub_brand_catalog_list.value = [];
        description_catalog_list.value = [];
        console.log("respuesta de Api/Catalogo/Apro - Modelos'", response.data.respuesta);

        isLoading.value = false;
      }else{
        isLoading.value = true;
        console.log("Se presentó un error; ",response.data.respuesta.message)
      } 
    }

    const getBrands = async (event) => {
      isLoading.value = true;
      selectYear.value = event.target.value;
      console.log("Año seleccionado: ", selectYear.value);
      var data = {
        "tipo": selectType.value,
        "modelo": selectYear.value
      }

      let response = await axios.get(anterapi + '/autos/catalogo_apro/obtener_marcas',{params: data}, config.value);
      if(response.status == 200){
        response.data.respuesta.resultado.forEach((element) => {
          brand_catalog_list.value.push({
            id: element.id,
            value: element.value
          })
        })
        sub_brand_catalog_list.value = [];
        description_catalog_list.value = [];
        console.log("respuesta de Api/Catalogo/Apro - Marcas'", response.data.respuesta);

        isLoading.value = false;
      }else{
        isLoading.value = true;
        console.log("Se presentó un error; ",response.data.respuesta.message)
      } 
    }

    const getSubBrands = async (event) => {
      isLoading.value = true;
      selectBrand.value = event.target.value;
      console.log("Marca seleccionada: ", selectBrand.value);
      var data = {
        "tipo": selectType.value,
        "modelo": selectYear.value,
        "marca_id": brand_catalog_list.value[event.target.options.selectedIndex-1].id
      }

      let response = await axios.get(anterapi + '/autos/catalogo_apro/obtener_submarcas',{params: data}, config.value);
      if(response.status == 200){
        response.data.respuesta.resultado.forEach((element) => {
          sub_brand_catalog_list.value.push({
            id: element.id,
            value: element.value
          })
        })
        description_catalog_list.value = [];
        console.log("respuesta de Api/Catalogo/Apro - Sub Marcas'", response.data.respuesta);

        isLoading.value = false;
      }else{
        isLoading.value = true;
        console.log("Se presentó un error; ",response.data.respuesta.message)
      } 
    }

    const getDescriptions = async (event) => {
      isLoading.value = true;
      selectSubBrand.value = event.target.value;
      console.log("Submarca seleccionada: ", selectSubBrand.value);
      var data = {
        "tipo": selectType.value,
        "modelo": selectYear.value,
        "submarca_id": sub_brand_catalog_list.value[event.target.options.selectedIndex-1].id
      }
      description_catalog_list.value = [];
      let response = await axios.get(anterapi + '/autos/catalogo_apro/obtener_descripciones',{params: data}, config.value);
      if(response.status == 200){
        response.data.respuesta.resultado.forEach((element) => {
          description_catalog_list.value.push({
            id: element.id,
            value: element.value
          })
        })
        
        console.log("respuesta de Api/Catalogo/Apro - Descripciones'", response.data.respuesta);

        isLoading.value = false;
      }else{
        isLoading.value = true;
        console.log("Se presentó un error; ",response.data.respuesta.message)
      } 
    }

    function sweetalertLoading() {
      swal.fire({
        title: "Espere un momento...!",
        timerProgressBar: true,
        showConfirmButton: false
      });
    }
    return{
      isLoading,

      sendRequest,
      selectType,
      selectYear,
      selectBrand,
      selectSubBrand,
      selectDescription,
      selectCoverage,
      getModels,
      getBrands,
      getSubBrands,
      getDescriptions,
      model_catalog_list,
      brand_catalog_list,
      sub_brand_catalog_list,
      description_catalog_list,
      birthDate,fullName,email,phone,postalCode,gender,
      coverage,type,year,brand,subBrand,version

    }
    
  }
}

</script>
<style>
/* :focus {
  outline: none;
} */
.ajuste_inputs {
  inline-size: -webkit-fill-available;
  contain: size;
  text-align: -webkit-center;
}

.main_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Divide en 3 columnas iguales */
  gap: 1rem; /* Espacio entre los inputs */
}
@media (max-width: 950px) {
  .main_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Divide en 3 columnas iguales */
    gap: 1.2rem; /* Espacio entre los inputs */
  }
}
@media (max-width: 780px) {
  .main_container {
    display: grid;
    grid-template-columns: repeat(1, 1fr); /* Divide en 3 columnas iguales */
  }
  
}
.modal-active {
  align-items: center;
  position: relative;
  z-index: 1000;
}
.modal {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  align-items: center;
  pointer-events: none;
  z-index: 1000;
}
.show-modal-active {
  display: flex;
  opacity: 1;
  align-items: center;
  pointer-events: auto;
  z-index: 1000;
}
@media (max-width: 900px) {
  .modal-active {
    margin-top: 75vh;
    margin-bottom: 100px;
  }
  .modal {
    overflow-y: scroll;
  }
}
</style>