import moment from 'moment-timezone'


const getDaysOfTheMonthPerYear= (year) => {
  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December" 
  ];

  let yearSelected = (typeof year == 'undefined' || year == '')? moment().tz('America/Hermosillo').format("YYYY") : year;
  const daysInYear = [];
  months.forEach(month => {
    let request = moment(`${yearSelected}-${month}`).daysInMonth();

    daysInYear.push({
      "date": `${yearSelected}-${month}`,
      "days": request
    });

  });
  return daysInYear;
};

const configDatePicker = () => {
    const config = {
        responsive: {
          breakpoints: [
            {name: 'bigdesktop', width: Infinity},
            {name: 'meddesktop', width: 1480},
            {name: 'smalldesktop', width: 1280},
            {name: 'medium', width: 1188},
            {name: 'tabletl', width: 1024},
            {name: 'btwtabllandp', width: 848},
            {name: 'tabletp', width: 768},
            {name: 'mobilel', width: 480},
            {name: 'mobilep', width: 320}
          ]
        },

        changeMonth: true,
        changeYear: true,
        dateFormat: "dd/mm/yy",
        monthNames: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        monthNamesShort: [
          "Ene",
          "Feb",
          "Mar",
          "Abr",
          "May",
          "Jun",
          "Jul",
          "Ago",
          "Sep",
          "Oct",
          "Nov",
          "Dic",
        ],
        dayNames: [
          "Domingo",
          "Lunes",
          "Martes",
          "Miércoles",
          "Jueves",
          "Viernes",
          "Sábado",
        ],
        dayNamesShort: ["Dom", "Lun", "Mar", "Mié", "Juv", "Vie", "Sáb"],
        dayNamesMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"],
        maxDate: new Date()
    };

    return config;
}

const getMonday = ( type, dates) => {
  var now = new Date();
  var nowTime = now.getTime();
  var day = now.getDay();
  var longTime = 24 * 60 * 60 * 1000;
  var n = longTime * 7 * (dates || 0);
  var dd;
  if (type == "s") {
    dd = nowTime - (day - 1) * longTime + n;
  }
  if (type == "e") {
    dd = nowTime + (7 - day) * longTime + n;
  }
  dd = new Date(dd);
  var y = dd.getFullYear();
  var m = dd.getMonth() + 1;
  var d = dd.getDate();
  m = m < 10 ? "0" + m: m;
  d = d < 10 ? "0" + d: d;
  day = moment(y + "-" + m + "-" + d).format('YYYY-MM-DD');

  return day;
} 

const subtractDate = (date, type = 'day', quantity = 0) => {
  if(typeof date == 'undefined' || date == ''){
    return false;
  }

  return moment(date).subtract(quantity, type).tz('America/Hermosillo').format('YYYY-MM-DD');
}

const addDate = (date, type = 'day', quantity = 0) => {
  if(typeof date == 'undefined' || date == ''){
    return false;
  }

  return moment(date).add(quantity, type).tz('America/Hermosillo').format('YYYY-MM-DD');
}

const getDayFirstAndLastDayOfYear = (date) => {
  if(typeof date == 'undefined' || date == ''){
    return false;
  }

  const startOfYear = moment(date).startOf('year').tz('America/Hermosillo').format('YYYY-MM-DD');
  const endOfYear   = moment(date).endOf('year').tz('America/Hermosillo').format('YYYY-MM-DD');

  return {
    first: startOfYear,
    last: endOfYear
  }
}

const getDayFirstAndLastDayOfWeek = (date) => {
  if(typeof date == 'undefined' || date == ''){
    return false;
  }

  const startOfWeek = moment(date).startOf('week').tz('America/Hermosillo').format('YYYY-MM-DD');
  const endOfWeek   = moment(date).endOf('week').tz('America/Hermosillo').format('YYYY-MM-DD');

  return {
    first: startOfWeek,
    last: endOfWeek
  }
};

const getMonthFirstAndLastDayOfMonth = (date) => {
  if(typeof date == 'undefined' || date == ''){
    return false;
  }

  const startOfMonth = moment(date).startOf('month').tz('America/Hermosillo').format('YYYY-MM-DD');
  const endOfMonth   = moment(date).endOf('month').tz('America/Hermosillo').format('YYYY-MM-DD');

  return {
    first: startOfMonth,
    last: endOfMonth
  }
}

const dateFormatForUserView = (date) => {
  if(typeof date == 'undefined' || date == ''){
    return moment(new Date()).tz('America/Hermosillo').format('D [del] MMMM [del] YYYY');
  }

  return moment(date).tz('America/Hermosillo').format('D [del] MMMM [del] YYYY');
}

const dateFormatForSystem = (date) => {
  if(typeof date == 'undefined' || date == ''){
    return moment(new Date()).tz('America/Hermosillo').format('YYYY-MM-DD');
  }

  return moment(date, 'DD/MM/YYYY').tz('America/Hermosillo').format('YYYY-MM-DD');
}


export { 
  configDatePicker, 
  getMonday, 
  getMonthFirstAndLastDayOfMonth, 
  dateFormatForUserView, 
  dateFormatForSystem, 
  getDayFirstAndLastDayOfYear, 
  subtractDate, 
  addDate, 
  getDaysOfTheMonthPerYear,
  getDayFirstAndLastDayOfWeek
};