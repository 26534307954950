import Option from "@/classes/option.js"
export default class Question {
  id = null;
  question = "";
  questionType = "";
  options = [];
  dependOnQuestion = null;
  childQuestions = [];
  productName = "";
  subcategoryName = "";
  validations = [];

  constructor(id, question, questionType, options, dependOnQuestion, childQuestions, productName, subcategoryName, validations) {
    this.id = id;
    this.question = question;
    this.questionType = questionType;
    this.options = options;
    this.dependOnQuestion = dependOnQuestion;
    this.childQuestions = this.setChildQiestions(childQuestions);
    this.productName = productName;
    this.subcategoryName = subcategoryName;
    this.validations = this.setvalidations(validations);
  }

  setChildQiestions(childQuestions){
    return childQuestions == null || childQuestions == "" || childQuestions == undefined ? [] : childQuestions;
  }
  setvalidations(validations){
    return validations == null || validations == "" ? [] :validations;
  }

  getParents() {
    return "¡Hola mundo!";
  }

  static buildInput() {
    return "Hello world!"
  }

  static buildFromJson(jsonArray) {
    let questions = [];
    for (let i = 0; i < jsonArray.length; i++) {
      let options = Option.buildFromJson(jsonArray[i].options);
      questions.push(new Question(jsonArray[i].id, jsonArray[i].question, jsonArray[i].question_type, options,
        jsonArray[i].depend_on_question, jsonArray[i].child_question_ids, jsonArray[i].product_name, jsonArray[i].subcategory_name, jsonArray[i].validations));
    }
    return questions;
  }

}