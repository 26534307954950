<template>
  <div data-aos="fade-up" data-aos-anchor-placement="top-top" data-aos-anchor="#products">
    <p class="text-2xl tracking-tight leading-7 font-extrabold text-gray-900 sm:text-2xl sm:leading-none md:text-2xl lg:text-2xl xl:text-2xl">
      <span class="text-2xl tracking-tight leading-7 font-extrabold sm:text-2xl sm:leading-none md:text-2xl lg:text-2xl xl:text-2xl text-orange-500">
        Aseguramos tu automóvil
      </span>
      <br />en minutos
    </p>
    <p class="mt-2 text-base leading-6 text-gray-500">
      Nuestra plataforma facilita la cotización, 
      comparación de precios con distintas aseguradoras 
      y la compra de tu seguro totalmente en línea,
      deja de hacer fila y asegura tu auto 
      en minutos con nosotros.
    </p>
    <br>
    <a href="autos" class="rounded-md shadow bg-orange-500 text-white py-2 px-2">
      Ver producto
    </a>
  </div>
</template>