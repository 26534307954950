<template>

    <account-recover-form/>
    <account-recover-image/>

</template>
<script>
import AccountRecoverForm from '@/components/AccountRecover/AccountRecoverForm';
import AccountRecoverImage from '@/components/AccountRecover/AccountRecoverImage';

export default {
  components:{
    AccountRecoverForm,
    AccountRecoverImage
  }
}
</script>