<style>
html {
  scroll-behavior: smooth;
}
</style>
<template>
  <main class="lg:relative">
    <trigger-benefits/>
    <features/>
    <info-section/>
    <our-services/>
    <!-- <our-clients-say/> -->
  </main>
</template>

<script>

import TriggerBenefits from "@/components/main-page/TriggerBenefits";
import Features from "@/components/main-page/Features";
import InfoSection from "@/components/main-page/info-section/InfoSection";
// import OurClientsSay from "@/components/main-page/OurClientsSay";
import OurServices from "@/components/main-page/OurServices";

export default {
  components: {
    TriggerBenefits,
    Features,
    InfoSection,
    OurServices,
    // OurClientsSay
  },
  setup() {},
};
</script>