<template>
  <div class="min-h-screen bg-white flex">
    <confirm-account-container/>
  </div>
</template>
<script>
import ConfirmAccountContainer from '@/components/ConfirmAccount/ConfirmAccountContainer';

export default {
  components: {
    ConfirmAccountContainer
  }
};
</script>