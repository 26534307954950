<template>
  <div class="min-h-screen bg-white flex">
    <create-account-container/>
  </div>
</template>
<script>
import CreateAccountContainer from '@/components/createAccount/CreateAccountContainer';

export default {
  components: {
    CreateAccountContainer
  }
};
</script>