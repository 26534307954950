<template>
  <div class="mx-auto max-w-7xl w-full text-center lg:text-left">
    <div class="px-4 lg:w-1/2 sm:px-8 xl:pr-2">
      <p class="text-3xl tracking-tight leading-7 font-extrabold text-gray-900 sm:text-4xl sm:leading-none md:text-4xl lg:text-4xl xl:text-4xl">
        <span class="text-3xl tracking-tight leading-7 font-extrabold sm:text-4xl sm:leading-none md:text-4xl lg:text-4xl xl:text-4xl text-orange-500">
          Ponte en contacto con
        </span>
        <br />nuestro equipo de soporte
      </p>
      <p class="mt-3 mx-auto text-contact text-gray-500 md:mt-5 xl:pr-2">
        Queremos que tu experiencia con nosotros sea cada vez mejor, si tienes
        algún comentario o necesitas asesoría personalizada, no dudes en
        escribirnos y con gusto le daremos seguimiento a tu solicitud
      </p>
    </div>
  </div>
</template>
