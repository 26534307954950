<template>

    <account-confirmation-form/>
    <account-confirmation-image/>

</template>
<script>
import AccountConfirmationForm from '@/components/AccountConfirmation/AccountConfirmationForm';
import AccountConfirmationImage from '@/components/AccountConfirmation/AccountConfirmationImage';

export default {
  components:{
    AccountConfirmationForm,
    AccountConfirmationImage
  }
}
</script>