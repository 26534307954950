<template>
  <div x-data="{ open: false }" class="relative pt-6 pb-12 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">
    <header-component/>
    <health-container/>
    <footer-component/>
  </div>
</template>
<script>
import FooterComponent from '@/components/common/FooterComponent.vue';
import HeaderComponent from "@/components/common/HeaderComponent.vue";
import HealthContainer from "@/components/health/HealthContainer.vue";

export default {
  components: {
    HeaderComponent,
    FooterComponent,
    HealthContainer
    
  },
  setup() {},
};
</script>